export const videodata = [
    {
        label: 'Lightening Roulette',
        iframsrc: 'https://www.youtube.com/embed/Uiph-5uGEjQ'
    },
    {
        label: 'American Roulette',
        iframsrc: 'https://www.youtube.com/embed/4JpS7qr_qwY'
    },
    {
        label: 'Double Ball Roulette',
        iframsrc: 'https://www.youtube.com/embed/CQvmSX-9Qkg'
    },
]

export const baccaratvideodata = [
    {
        label: 'Dragon Tiger',
        iframsrc: 'https://www.youtube.com/embed/Uiph-5uGEjQ'
    },
    {
        label: 'Lightning Baccarat',
        iframsrc: 'https://www.youtube.com/embed/4JpS7qr_qwY'
    },
    {
        label: 'Lightning Baccarat',
        iframsrc: 'https://www.youtube.com/embed/CQvmSX-9Qkg'
    },
]

export const poker = [
    {
        label: 'Casino Hold’em Poker',
        iframsrc: 'https://www.youtube.com/embed/zEw_LVNsGHc'
    },
    {
        label: 'Caribbean Stud Poker',
        iframsrc: 'https://www.youtube.com/embed/3-zFxuqyZPw'
    },
    {
        label: 'Extreme Texas Hold’em Poker',
        iframsrc: 'https://www.youtube.com/embed/Z0W089tfwRY'
    },
]

export const blackjack = [
    {
        label: 'Free Bet Blackjack',
        iframsrc: 'https://www.youtube.com/embed/Y9hzBPtrTZ8'
    },
    {
        label: 'Infinite Blackjack',
        iframsrc: 'https://www.youtube.com/embed/HodcuaXBt3k'
    },
    {
        label: 'Power Blackjack',
        iframsrc: 'https://www.youtube.com/embed/nEtw7DcFKw0'
    },
]

export const gameshow = [
    {
        label: 'Lightning Dice',
        iframsrc: 'https://www.youtube.com/embed/Qs_FDf7ttDk'
    },

]

