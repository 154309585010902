import { useLocation, useNavigate } from "react-router"
import HeadingBar from "../../../../common/HeadingBar"
import TopTabs from "../../../../common/TopTabs"
import MarketsTabs from "./MarketsTabs"
const Markets = () => {
  let { pathname } = useLocation()
  pathname = pathname.replace("/", "")
  const nav = useNavigate()

  return (
    <div>
      <HeadingBar title="markets" />
      <TopTabs />
      <MarketsTabs />
    </div>
  )
}

export default Markets
