import { Box, Container, Grid, List, ListItem, Typography } from "@mui/material"
import sevenupsevendown from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/7up7down-menu.webp"

const listyle = {
  padding: 0,
  fontSize: "14px",
  color: "#fff",
}

const SevenUpSevenDown = () => {
  return (
    <Box className="sevenupsevendown">
      <Container maxWidth="lg" sx={{ py: "10px", m: "17px 59px" }}>
        <Grid container columnSpacing={{ xs: 2 }}>
          <Grid item xs={4}>
            <Typography
              component="h1"
              sx={{
                fontSize: "32px",
                color: "#f36c21",
                fontFamily: "Lato,sans-serif",
                fontWeight: "700",
                paddingBottom: "8px",
                textAlign: "left",
              }}
            >
              7up 7down
            </Typography>

            <div className="teenpattiimg max-w-[420px] mb-[27px]">
              <img
                src={
                  "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/7up7down-menu.webp"
                }
                alt=""
                className="max-w-[100%] h-auto"
              />
            </div>
          </Grid>
          <Grid item xs={8}>
            <Box className="seven">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                7:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>The drawn card should be 7's.</ListItem>
              </List>
            </Box>
            <Box className="sevendown">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                7 Down:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  The drawn card should be amount A's, 2's, 3's, 4's, 5's or
                  6's.
                </ListItem>
              </List>
            </Box>
            <Box className="red">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Red:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  The drawn card should be amount A's, 2's, 3's, 4's, 5's or
                  6's.
                </ListItem>
              </List>
            </Box>
            <Box className="black">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Black:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  The drawn card should be amount A's, 2's, 3's, 4's, 5's or
                  6's.
                </ListItem>
              </List>
            </Box>
            <Box className="even">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Even:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  The drawn card should be amount 2's, 4's, 6's, 8's, 10's or
                  Q's.
                </ListItem>
              </List>
            </Box>
            <Box className="odd">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Odd:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  The drawn card should be amount A's, 3's, 5's, 7's, 9's, 11's
                  or 13's.
                </ListItem>
              </List>
            </Box>
            <Box className="club">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Club:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>The drawn card should be Club.</ListItem>
              </List>
            </Box>
            <Box className="diamond">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Diamond:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  The drawn card should be Diamond.
                </ListItem>
              </List>
            </Box>
            <Box className="heart">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Heart:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  The drawn card should be Heart.
                </ListItem>
              </List>
            </Box>
            <Box className="spade">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Spade:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  The drawn card should be Spade.
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
export default SevenUpSevenDown
