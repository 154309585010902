import {
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
} from "@mui/material"
import type { FC } from "react"
// import Logo from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/{ window.location.hostname.replace("www.", "").split(".")[0] }.png"
import { Link, Outlet } from "react-router-dom"

interface dataProps {
  GAME: {
    name: string
    link: string
  }[]
  COMPANY: {
    name: string
    link: string
  }[]
  RESOURCES: {
    name: string
    link: string
  }[]
};

const footerdata: dataProps[] = [
  {
    GAME: [
      {
        name: "Card Game",
        link: "#",
      },
      {
        name: "Cricket Betting",
        link: "#",
      },
      {
        name: "IPL Betting",
        link: "#",
      },
      {
        name: "Football Betting",
        link: "#",
      },
      {
        name: "Tennis Betting",
        link: "#",
      },
      {
        name: "Live Casino Betting",
        link: "#",
      },
      {
        name: "CO Online Betting App",
        link: "#",
      },
    ],
    COMPANY: [
      {
        name: "About Us",
        link: "#",
      },
      {
        name: "Rules & Regulation",
        link: "#",
      },
      {
        name: "Privacy Policy",
        link: "#",
      },
      {
        name: "Sitemap",
        link: "#",
      },
      {
        name: "Responsible Gambling",
        link: "#",
      },
      {
        name: "Terms And Conditions",
        link: "#",
      },
    ],
    RESOURCES: [
      {
        name: "Blogs",
        link: "#",
      },
      {
        name: "CO News",
        link: "#",
      },
      {
        name: "Premium Sportsbook Betting",
        link: "#",
      },
    ],
  },
]

interface HeaderProps {
  isSelfData: isSelfRes | undefined
}

const Footer: FC<HeaderProps> = ({ isSelfData }) => {
  return (
    <div className="bg-[#272727]">
      <Container maxWidth="xl" sx={{ mx: "auto", p: 0 }}>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ px: 2 }}>
            <Box sx={{ my: 2.5 }}>
              <img
                src={isSelfData?.data?.logo}
                alt=""
                className="lg:w-[135px] lg:h-[43px] w-[98px] h-[35px]"
              />
            </Box>
            <Typography
              component="p"
              sx={{
                color: "#fff",
                textAlign: "left",
                fontSize: "12px",
                mt: "15px",
              }}
            >
              © Established in 2019, CO is the most trusted betting exchange
              and leading online casino.
            </Typography>
            <Divider sx={{ my: "30px" }} />
          </Grid>
          <Grid item xs={6} md={3} sx={{ px: 2 }}>
            <Box>
              <Typography
                component={"h3"}
                sx={{
                  textAlign: "left",
                  mt: "25px",
                  fontSize: { md: "1.25rem", xs: "1rem" },
                  color: "#fff",
                  fontWeight: "500",
                }}
              >
                GAME
              </Typography>

              <List sx={{ p: 0 }}>
                {footerdata?.map(data =>
                  data?.GAME?.map((gamedata, index) => (
                    <ListItem
                      sx={{ display: "flex", px: 0, py: "4px" }}
                      key={gamedata.name + index}
                    >
                      <Link
                        to={gamedata?.link}
                        className="text-[11px] lg:text-[12px] text-white"
                      >
                        {gamedata?.name}
                      </Link>
                    </ListItem>
                  )),
                )}
              </List>
            </Box>
          </Grid>
          <Grid item xs={6} md={2} sx={{ px: 2 }}>
            <Box>
              <Typography
                component={"h3"}
                sx={{
                  textAlign: "left",
                  mt: "25px",
                  fontSize: { md: "1.25rem", xs: "1rem" },
                  color: "#fff",
                  fontWeight: "500",
                }}
              >
                COMPANY
              </Typography>
              <List sx={{ p: 0 }}>
                {footerdata?.map(data =>
                  data?.GAME?.map((gamedata, index) => (
                    <ListItem
                      sx={{ display: "flex", px: 0, py: "4px" }}
                      key={gamedata.name + index}
                    >
                      <Link
                        to={gamedata?.link}
                        className="text-[11px] lg:text-[12px] text-white"
                      >
                        {gamedata?.name}
                      </Link>
                    </ListItem>
                  )),
                )}
              </List>
            </Box>
          </Grid>
          <Grid item xs={6} md={2} sx={{ px: 2 }}>
            <Box>
              <Typography
                component={"h3"}
                sx={{
                  textAlign: "left",
                  mt: "25px",
                  fontSize: { md: "1.25rem", xs: "1rem" },
                  color: "#fff",
                  fontWeight: "500",
                }}
              >
                RESOURCES
              </Typography>
              <List sx={{ p: 0 }}>
                {footerdata?.map(data =>
                  data?.GAME?.map((gamedata, index) => (
                    <ListItem
                      sx={{ display: "flex", px: 0, py: "4px" }}
                      key={gamedata.name + index}
                    >
                      <Link
                        to={gamedata?.link}
                        className="text-[11px] lg:text-[12px] text-white"
                      >
                        {gamedata?.name}
                      </Link>
                    </ListItem>
                  )),
                )}
              </List>
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", alignItems: "center", px: 2 }}
          >
            <Box>
              <List sx={{ p: 0 }}>
                <ListItem sx={{ display: "flex", px: 0, py: "4px" }}>
                  <Link
                    to={"#"}
                    className="text-[11px] lg:text-[12px] text-white"
                  >
                    Affiliate
                  </Link>
                </ListItem>
                <ListItem sx={{ display: "flex", px: 0, py: "4px" }}>
                  <Link
                    to={"#"}
                    className="text-[11px] lg:text-[12px] text-white"
                  >
                    FAQ
                  </Link>
                </ListItem>
                <ListItem sx={{ display: "flex", px: 0, py: "4px" }}>
                  <Link
                    to={"#"}
                    className="text-[11px] lg:text-[12px] text-white"
                  >
                    Tutorials
                  </Link>
                </ListItem>
                <ListItem sx={{ display: "flex", px: 0, py: "4px" }}>
                  <Link
                    to={"#"}
                    className="text-[11px] lg:text-[12px] text-white"
                  >
                    Market
                  </Link>
                </ListItem>
                <ListItem sx={{ display: "flex", px: 0, py: "4px" }}>
                  <Link
                    to={"#"}
                    className="text-[11px] lg:text-[12px] text-white"
                  >
                    Loyalty
                  </Link>
                </ListItem>
                <ListItem sx={{ display: "flex", px: 0, py: "4px" }}>
                  <Link
                    to={"#"}
                    className="text-[11px] lg:text-[12px] text-white"
                  >
                    Contact Us
                  </Link>
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ my: "30px" }} />

        <Grid container sx={{ display: { xs: "none", md: "flex" } }}>
          <Grid item xs={3} md={3} sx={{ width: "100%" }}>
            {/* empty */}
          </Grid>
          <Grid
            item
            xs={9}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              component="span"
              sx={{ fontSize: "12px", letterSpacing: "0.5px", color: "#fff" }}
            >
              Disclaimer :
            </Typography>
            <Typography
              component="p"
              sx={{
                fontSize: "12px",
                letterSpacing: "0.5px",
                color: "#fff",
                textAlign: "left",
                fontWeight: "600",
              }}
            >
              Please note that Gambling involves a financial risk and could be
              addictive over time if not practised within limits. Only 18+
              people should use the services and should use it responsibly.
              Players should be aware of any financial risk and govern
              themselves accordingly.
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: "30px" }} />
        <Box sx={{ padding: "0 0 10px" }}>
          <Typography
            component="p"
            sx={{
              px: 2,
              textAlign: "left",
              color: "#fff",
              fontSize: "13px",
              fontWeigh: "600",
            }}
          >
            © 2023 {window.location.hostname.replace("www.", "").split(".")[0]}. All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </div>
  )
}

export default Footer
