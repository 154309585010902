import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Fade,
  Box,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { CiLogout } from "react-icons/ci"
import { useLazyLogoutQuery } from "../../api/mainApi/mainApiSlice"
import snackBarUtil from "../../utils/snackbar"
import { SnackbarContent } from "notistack"
import { useNavigate } from "react-router"
import "./feedbackmodal.css"
interface InfoDialogProps {
  setFeedbackOpen: Dispatch<SetStateAction<boolean>>
  open: boolean
}

const FeedbackModal: FC<InfoDialogProps> = ({ open, setFeedbackOpen }) => {
  const handleClose = () => {
    setFeedbackOpen(false)
  }
  const nav = useNavigate()

  const [logoutTrigger, { data: logoutdata, isSuccess, isError }] =
    useLazyLogoutQuery()
  const [textareaVal, setTextAreaVal] = useState("")

  const handleWriteComment = (e: any) => {
    setTextAreaVal(e.target.value)
  }

  useEffect(() => {
    if (logoutdata?.status) {
      nav("/")
      snackBarUtil.success(
        <SnackbarContent role="alert">
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ color: "#fff", fontSize: "16px", textAlign: "left" }}
            >
              Success!
            </Typography>
            <Typography
              sx={{ color: "#fff", fontSize: "12px", textAlign: "left" }}
            >
              Successfully Logged Out
            </Typography>
          </Box>
        </SnackbarContent>,
      )
    }
  }, [logoutdata, nav])

  return (
    <Dialog
      open={open}
      TransitionComponent={Fade}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
      sx={{
        ".MuiDialog-paper": {
          position: "absolute",
          top: "30%",
          backgroundColor: "#fff",
          borderRadius: "8px",
          maxWidth: "500px",
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#f36c21",
          padding: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              textTransform: "capitalize",
              fontSize: "16px",
              color: "#000",
              fontWeight: "600",
            }}
          >
            Feedback
          </Typography>
          <Button
            disableRipple
            disableTouchRipple
            onClick={handleClose}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "unset",
              backgroundColor: "transparent",
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <CloseIcon sx={{ color: "#fff" }} />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: { md: 1, xs: 0.5 } }}>
        <Box sx={{ px: { md: 1, xs: 0 } }}>
          <textarea
            maxLength={10}
            className="textarea"
            placeholder="Write a comment or describe the problem"
            value={textareaVal}
            onChange={handleWriteComment}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "#f36c21",
            padding: "10px 15px",
            color: "#fff",
            fontSize: "15px",
            textTransform: "capitalize",
            minWidth: "unset",
            lineHeight: "17px",
            border: "1px solid #f36c21",

            ":hover": {
              color: "#f36c21",
              border: "1px solid #f36c21",
              background: "linear-gradient(0deg,hsla(0,0%,4%,.5) 50%,#323232)",
              backgroundColor: "#1e1e1e",
            },
          }}
        >
          Submit
        </Button>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "#f36c21",
            padding: "10px 15px",
            color: "#fff",
            fontSize: "15px",
            textTransform: "capitalize",
            lineHeight: "17px",
            border: "1px solid #f36c21",
            minWidth: "unset",

            ":hover": {
              color: "#f36c21",
              border: "1px solid #f36c21",
              background: "linear-gradient(0deg,hsla(0,0%,4%,.5) 50%,#323232)",
              backgroundColor: "#1e1e1e",
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FeedbackModal
