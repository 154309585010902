import { Button, Divider, Typography } from "@mui/material"
// import sportsbook from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/sportsbook.webp"
// import livecasino from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecasino.webp"
// import livecard from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecard.webp"
import { useLocation, useNavigate } from "react-router"
import { useEffect, useState } from "react"

const TopTabs = () => {
  const { pathname } = useLocation()
  const nav = useNavigate()
  const [active, setActive] = useState("")
  useEffect(() => {
    setActive(location.pathname)
  }, [location.pathname])

  return (
    <div className="flex items-center justify-start py-[17px] px-[0px] lg:px-[59px] bg-[#1e1e1e]">
      <Button
        sx={{
          marginRight: "5px",
          padding: "2px 10px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: 0,
          backgroundColor: `${active ? "#51311f" : ""}`,
          borderRight: "1px solid hsla(0, 0%, 100%, .6)",
          ":hover": {
            backgroundColor: "#51311f",
          },
        }}
      >
        <img
          src={
            "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/sportsbook.webp"
          }
          alt=""
          className="w-[22px] h-[22px]"
        />
        <Typography
          sx={{
            color: "#f36c21",
            fontSize: "12px",
            textTransform: "uppercase",
          }}
          component="span"
        >
          sportsbook
        </Typography>
      </Button>

      <Button
        onClick={() => nav("/markets/live-casino")}
        sx={{
          marginRight: "5px",
          padding: "0 10px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: 0,
          borderRight: "1px solid hsla(0, 0%, 100%, .6)",
          ":hover": {
            backgroundColor: "#272727",
          },
        }}
      >
        <img
          src={
            "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecasino.webp"
          }
          alt=""
          className="w-[22px] h-[22px]"
        />
        <Typography
          sx={{
            color: "#ffffff99",
            fontSize: "12px",
            textTransform: "uppercase",
          }}
          component="span"
        >
          live casino
        </Typography>
      </Button>

      <Button
        onClick={() => nav("/markets/live-card")}
        sx={{
          marginRight: "5px",
          padding: "0 10px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: 0,
          borderRight: "1px solid hsla(0, 0%, 100%, .6)",
          ":hover": {
            backgroundColor: "#272727",
          },
        }}
      >
        <img
          src={
            "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecard.webp"
          }
          alt=""
          className="w-[22px] h-[22px]"
        />
        <Typography
          sx={{
            color: "#ffffff99",
            fontSize: "12px",
            textTransform: "uppercase",
          }}
          component="span"
        >
          live card
        </Typography>
      </Button>
    </div>
  )
}
export default TopTabs
