import { FC } from "react"

interface BadgeProps {
  className: string
  text: string | number
}

const Badge: FC<BadgeProps> = ({ className, text }) => {
  return <p className={className}>{text}</p>
}
export default Badge
