import { Box, Container, Grid, Typography } from "@mui/material"
import { privacydata } from "./privacydata"
import HeadingBar from "../../../../common/HeadingBar"

const PrivacyPolicy = () => {
  return (
    <Box sx={{ backgroundColor: "#000" }} className="privacypolicyparent">
      <HeadingBar title="privacy policy" />
      <Container maxWidth="xl" sx={{ p: { md: "17px 59px", xs: "17px 3px" } }}>
        <Grid container rowSpacing={{ md: 2, xs: 0 }} columnSpacing={3}>
          <Grid item xs={12} md={6}>
            {privacydata[0]?.map((content, idx) => (
              <Box key={idx}>
                {content?.title ? (
                  <Typography
                    className="heading"
                    sx={{
                      fontSize: "1rem",
                      lineHeight: "1.75rem",
                      letterSpacing: ".009375em",
                      fontFamily: "Roboto,sans-serif!important",
                      color: "#fff",
                      textAlign: "left",
                    }}
                  >
                    {content?.title}
                  </Typography>
                ) : (
                  ""
                )}

                {content?.heading ? (
                  <Typography
                    component="p"
                    sx={{
                      fontSize: "14px",
                      paddingBottom: "16px",
                      letterSpacing: ".0178571429em",
                      lineHeight: "1.5rem",
                      color: "#fff",
                      fontFamily: "Lato,sans-serif",
                      textAlign: "left",
                    }}
                  >
                    {content?.heading}
                  </Typography>
                ) : (
                  ""
                )}

                {content?.content ? (
                  <Typography
                    component="p"
                    sx={{
                      fontSize: "14px",
                      paddingBottom: "16px",
                      letterSpacing: ".0178571429em",
                      lineHeight: "1.5rem",
                      color: "#fff",
                      fontFamily: "Lato,sans-serif",
                      textAlign: "left",
                    }}
                  >
                    {content.content}
                  </Typography>
                ) : (
                  ""
                )}

                {content?.morecontent ? (
                  <Typography
                    component="p"
                    sx={{
                      fontSize: "14px",
                      paddingBottom: "16px",
                      letterSpacing: ".0178571429em",
                      color: "#fff",
                      lineHeight: "1.5rem",
                      fontFamily: "Lato,sans-serif",
                      textAlign: "left",
                    }}
                  >
                    {content.morecontent}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            {privacydata[1]?.map((content, idx) => (
              <Box key={idx}>
                {content?.title ? (
                  <Typography
                    className="heading"
                    sx={{
                      fontSize: "1rem",
                      lineHeight: "1.75rem",
                      letterSpacing: ".009375em",
                      fontFamily: "Roboto,sans-serif!important",
                      color: "#fff",
                      textAlign: "left",
                    }}
                  >
                    {content?.title}
                  </Typography>
                ) : (
                  ""
                )}

                {content?.heading ? (
                  <Typography
                    component="p"
                    sx={{
                      fontSize: "14px",
                      paddingBottom: "16px",
                      letterSpacing: ".0178571429em",
                      lineHeight: "1.5rem",
                      fontFamily: "Lato,sans-serif",
                      color: "#fff",
                      textAlign: "left",
                    }}
                  >
                    {content?.heading}
                  </Typography>
                ) : (
                  ""
                )}

                {content?.content ? (
                  <Typography
                    component="p"
                    sx={{
                      fontSize: "14px",
                      paddingBottom: "16px",
                      letterSpacing: ".0178571429em",
                      color: "#fff",
                      lineHeight: "1.5rem",
                      fontFamily: "Lato,sans-serif",
                      textAlign: "left",
                    }}
                  >
                    {content.content}
                  </Typography>
                ) : (
                  ""
                )}

                {content?.morecontent ? (
                  <Typography
                    component="p"
                    sx={{
                      fontSize: "14px",
                      paddingBottom: "16px",
                      letterSpacing: ".0178571429em",
                      color: "#fff",
                      lineHeight: "1.5rem",
                      fontFamily: "Lato,sans-serif",
                      textAlign: "left",
                    }}
                  >
                    {content.morecontent}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default PrivacyPolicy
