// import sevenupsevendown from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/7up7down-live-card.webp';
// import cards32 from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/32-cards-live-card.webp'
// import tripleaaa from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/aaa-live-card.webp'
// import andarbahar from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/andar_bahar-live-card.webp';
// import poker from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/poker-live-card.webp'
// import teenpattioneday from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/teen-patti_one-day.webp'
// import worli from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/worli-matka-live-card.webp'
// import dragontiger from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/dragon_tiger-live-card.webp'

type livecarddata = {
    img: string,
    link: string
}[]

export const livecarddata: livecarddata = [
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/teen-patti_one-day.webp',
        link: '/markets/oneday-teen-patti'
    },
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/teen-patti_one-day.webp',
        link: '/markets/t20-teen-patti'
    },
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/poker-live-card.webp',
        link: '/markets/poker'
    },
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/andar_bahar-live-card.webp',
        link: '/markets/andhar-bahar'
    },
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/7up7down-live-card.webp',
        link: '/markets/7up7down'
    },
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/32-cards-live-card.webp',
        link: '/markets/32-cards'
    },
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/dragon_tiger-live-card.webp',
        link: '/markets/dragon-tiger'
    },
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/aaa-live-card.webp',
        link: '/markets/amar-akbar-antony'
    },
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardimg/worli-matka-live-card.webp',
        link: '/markets/worli-matka'
    },
]