import React from "react"
import SportsBar from "../../common/SportsBar"
import HomeSportsPage from "./HomeSportsPage"
import DownloadTheApp from "../../common/DownloadTheApp"

const Home = () => {
  return (
    <div>
      <HomeSportsPage />
      <DownloadTheApp />
    </div>
  )
}

export default Home
