import { FC, useState } from "react"
import "./headingbar.css"
import { Box, Button, Typography } from "@mui/material"
import { useNavigate } from "react-router"
import { ChevronLeft } from "@mui/icons-material"
import { CiStar } from "react-icons/ci"
import { AiFillStar } from "react-icons/ai"
interface SportsDetailHeadingBarProps {
  title: any
}

const SportsDetailHeadingBar: FC<SportsDetailHeadingBarProps> = ({ title }) => {
  const nav = useNavigate()
  const [fav, setFav] = useState(false)

  const handlerFav = () => {
    setFav(!fav)
  }

  return (
    <Box
      className="headingbarparent"
      sx={{ display: "flex", alignItems: "center" }}
    >
      <Box sx={{ display: "flex" }}>
        <Button
          onClick={() => nav(-1)}
          disableRipple
          disableTouchRipple
          sx={{
            minWidth: 0,
            borderRadius: "50%",
            backgroundColor: "transparent",
            cursor: "default",
            ":hover": {
              backgroundColor: "#0000008b",
            },
          }}
        >
          <ChevronLeft sx={{ color: "#f36c21" }} />
        </Button>
        <Typography
          sx={{
            fontSize: "14px",
            textAlign: "left",
            color: "#fff",
            textTransform: "capitalize",
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
      </Box>

      <Typography
        component="p"
        sx={{
          //   padding: { md: "0 50%", xs: "0 65%" },
          margin: "0 0 0 auto",
          fontSize: "15px",
        }}
      >
        <Typography component="span" onClick={handlerFav}>
          {fav ? (
            <AiFillStar
              className="text-[20px] text-[#ffc107] cursor-pointer"
              title="Add to Favourite"
            />
          ) : (
            <CiStar
              className="text-[20px] text-[#ffc107] cursor-pointer"
              title="Add to Favourite"
            />
          )}
        </Typography>
      </Typography>
    </Box>
  )
}

export default SportsDetailHeadingBar
