import { Box } from "@mui/material"
import SecondHeadingBar from "../../../common/SecondHeadingBar"
import SearchBar from "../../../common/openbetsmain/OpenBetsMainComp"

const style = {
  backgroundColor: "#f36c21",
}

const BetsPage = () => {
  return (
    <Box className="betspageparent">
      <Box sx={style}>
        <SecondHeadingBar title="open bets" />
      </Box>
      <Box className="gametypesearch">
        <SearchBar exp={false} />
      </Box>
    </Box>
  )
}

export default BetsPage
