import React from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material"

const data = [
  { percent: 15, deposits: "200k to 2000k", minActiveUser: 5, newUsers: 3 },
  { percent: 30, deposits: "2000k to 5000k", minActiveUser: 20, newUsers: 10 },
  { percent: 20, deposits: "5000k and above", minActiveUser: 50, newUsers: 25 },
]

const AffiliateTable = () => {
  return (
    <TableContainer component={Paper} sx={{ borderRadius: "10px", my: 1 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                backgroundColor: "#f36c21",
                color: "#fff",
                fontSize: "12px",
              }}
            >
              %
            </TableCell>
            <TableCell
              align="left"
              sx={{
                backgroundColor: "#f36c21",
                color: "#fff",
                fontSize: "12px",
              }}
            >
              DEPOSITS
            </TableCell>
            <TableCell
              align="left"
              sx={{
                backgroundColor: "#f36c21",
                color: "#fff",
                fontSize: "12px",
              }}
            >
              MIN ACTIVE USER
            </TableCell>
            <TableCell
              align="left"
              sx={{
                backgroundColor: "#f36c21",
                color: "#fff",
                fontSize: "12px",
              }}
            >
              NEW USERS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, idx) => (
            <TableRow
              key={idx}
              sx={{
                "&:nth-of-type(odd)": { backgroundColor: "#424242" },
                "&:nth-of-type(even)": { backgroundColor: "#616161" },
              }}
            >
              <TableCell align="left" sx={{ color: "#fff", fontSize: "14px" }}>
                {row.percent}
              </TableCell>
              <TableCell align="left" sx={{ color: "#fff", fontSize: "14px" }}>
                {row.deposits}
              </TableCell>
              <TableCell align="left" sx={{ color: "#fff", fontSize: "14px" }}>
                {row.minActiveUser}
              </TableCell>
              <TableCell align="left" sx={{ color: "#fff", fontSize: "14px" }}>
                {row.newUsers}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AffiliateTable
