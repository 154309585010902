import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Typography,
} from "@mui/material"
import HeadingBar from "../../../../common/HeadingBar"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { useState } from "react"
import { tutorialsdata } from "./tutorialsdata"

const Tutorials = () => {
  const [expanded, setExpanded] = useState<string | false>(false)
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <Box className="tutorialsparent">
      <Box className="tutorials">
        <HeadingBar title="Tutorials" />
      </Box>
      <Box className="tutorial-accordion" sx={{ bgcolor: "#000", p: "15px" }}>
        <Container maxWidth="lg" sx={{ padding: "0 !important" }}>
          <Typography
            component="h2"
            sx={{
              fontSize: "32px",
              color: "#fff",
              fontWeight: "700",
              lineHeight: "1.2",
              my: 2,
            }}
          >
            Checkout these categories to watch tutorials
          </Typography>

          <Box className="accordionholder">
            {tutorialsdata?.map((tutorial, idx) => (
              <Accordion
                key={tutorial.title + idx}
                sx={{
                  backgroundColor: "#1e1e1e",
                  borderRadius: "5px",
                  border: "1px solid #2f2f2f !important",
                  my: 2,
                  color: "#fff",
                  fontWeight: "500",
                  "&::before": {
                    display: "none",
                  },
                }}
                expanded={expanded === `panel${idx}`}
                onChange={handleChange(`panel${idx}`)}
              >
                <AccordionSummary
                  expandIcon={
                    expanded !== `panel${idx}` ? <AddIcon /> : <RemoveIcon />
                  }
                  aria-controls={`panel${idx}bh-content`}
                  id={`panel${idx}bh-header`}
                >
                  <Typography
                    component="p"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      color: "#fff",
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      fontWeight: "700",
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        backgroundColor: `${expanded === `panel${idx}` ? "#f36c21" : "#4caf50"}`,
                        borderRadius: "100px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                    >
                      <img
                        src={tutorial.iconimg}
                        alt=""
                        className="w-[17px] h-full max-w-full"
                      />
                    </Typography>
                    {tutorial?.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 2 }}>
                  <Grid container columnSpacing={2}>
                    {tutorial?.imgone && (
                      <Grid
                        item
                        xs={tutorial?.imgtwo ? 12 : 12}
                        md={tutorial?.imgtwo ? 6 : 12}
                      >
                        <img
                          loading="lazy"
                          className="w-auto max-w-full h-auto m-auto"
                          src={tutorial?.imgone}
                          alt=""
                        />

                        <Typography
                          component="p"
                          sx={{
                            color: "#f36c21",
                            paddingTop: "5px",
                            fontSize: "16px",
                          }}
                        >
                          {tutorial?.bottomtitleone}
                        </Typography>
                      </Grid>
                    )}

                    {tutorial?.imgtwo && (
                      <Grid
                        item
                        xs={tutorial?.imgone ? 12 : 12}
                        md={tutorial?.imgone ? 6 : 12}
                      >
                        <img
                          loading="lazy"
                          className="w-auto max-w-full h-auto"
                          src={tutorial?.imgtwo}
                          alt=""
                        />

                        <Typography
                          component="p"
                          sx={{
                            color: "#f36c21",
                            paddingTop: "5px",
                            fontSize: "16px",
                          }}
                        >
                          {tutorial?.bottomtitletwo}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default Tutorials
