import { List, ListItem } from "@mui/material"
import { Link } from "react-router-dom"
import Badge from "./Badge"
import { useLeftMenuListMutation } from "../api/mainApi/mainApiSlice"
import { useEffect } from "react"
import sportIcon from "../utils/sporticon"

const LeftMenu = () => {
  const [leftmenuTrigger, { data: leftmenuData }] = useLeftMenuListMutation()

  useEffect(() => {
    leftmenuTrigger({})
  }, [])

  const racingarr: LeftMenuListData[] = []
  const restdata: LeftMenuListData[] = []

  const racing = function () {
    leftmenuData?.data.forEach((ele: any) => {
      if (ele.sportName.includes("racing")) {
        racingarr.push(ele)
      } else {
        restdata.push(ele)
      }
    })
  }
  racing()

  return (
    <div className="border border-[#f36c21] rounded-[0.938rem] h-[auto]">
      <List>
        <h3 className="text-[#fdcf13] underline ml-4 text-lg font-semibold text-left">
          Racing Sport
        </h3>

        {racingarr &&
          racingarr?.map((data: any, index: number) => (
            <Link
              key={data.sportId + index}
              to={`/racing-category/${data?.sportName.replace(" racing", "_race").toUpperCase()}`}
              className="group flex items-center 
            py-[8px]
            px-[10px]
            hover:shadow-[inset_0_8px_26px_-23px_hsla(0,0%,100%,.75)]
            hover:border-r-[5px]
            hover:border-r-[#f36c21]
            "
            >
              <img
                src={sportIcon(data?.sportName)}
                alt={data?.sportName.trim()}
                className={`h-5 w-5 ${data?.sportName === "Kabaddi" || data?.sportName === "Election" ? "filter invert" : ""}`}
              />

              <p
                className="text-white capitalize ml-1 text-[14px] text-left
             transition-colors ease-in-out delay-0 
             duration-[0.6s]
              group-hover:text-[#f36c21]
              border-collapse

              "
              >
                {data?.sportName}
              </p>
            </Link>
          ))}

        <h3 className="text-[#fdcf13] underline ml-4 text-lg font-semibold text-left">
          All Sports
        </h3>

        {restdata &&
          restdata?.map((data, index) => (
            <Link
              key={Number(data?.sportId) + index}
              to={`/sports/${data?.sportName.trim().toLowerCase()}/${data?.sportId}`}
              className="group flex items-center 
            justify-between
            py-[8px]
            px-[10px]
            hover:shadow-[inset_0_8px_26px_-23px_hsla(0,0%,100%,.75)]
            hover:border-r-[5px]
            hover:border-r-[#f36c21]
            "
            >
              <ListItem sx={{ p: 0, my: 0.5 }}>
                <img
                  src={sportIcon(data?.sportName)}
                  alt="horse"
                  className={`h-5 w-5 ${data?.sportName === "Kabaddi" || data?.sportName === "Election" ? "filter invert" : ""}`}
                />
                <p
                  className="text-white relative capitalize ml-1 text-[14px] text-left
                 transition-colors ease-in-out delay-0
                 duration-[0.6s]
                  group-hover:text-[#f36c21]
                  border-collapse
                  "
                >
                  {data?.sportName}
                </p>
              </ListItem>

              <Badge
                className="text-white flex items-center justify-center absolute font-semibold text-[12px] right-5 rounded-full h-[25px] w-[27px] bg-[#4cAF50]"
                text={data?.totalMatch}
              />
            </Link>
          ))}
      </List>
    </div>
  )
}

export default LeftMenu
