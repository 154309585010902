// import tutorialsimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tutorialsimg.webp"
// import othersimg from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tutorialaccimg/accotherimg.webp'
// import bettingimg from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tutorialaccimg/betting.webp'
// import offersimg from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tutorialaccimg/offers.webp'
// import proimg from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tutorialaccimg/pro.webp'


type tutorialimgDataType = {
    title: string,
    iconimg: string,
    imgone: string,
    imgtwo?: string,
    bottomtitleone: string,
    bottomtitletwo?: string,

}[]

export const tutorialsdata: tutorialimgDataType = [
    {
        title: 'others',
        iconimg: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tutorialaccimg/accotherimg.webp',
        imgone: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tutorialsimg.webp',
        imgtwo: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tutorialsimg.webp',
        bottomtitleone: 'How to install app',
        bottomtitletwo: 'How to clear cache',
    },
    {
        title: 'betting',
        iconimg: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tutorialaccimg/accotherimg.webp',
        imgone: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tutorialsimg.webp',
        imgtwo: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tutorialsimg.webp',
        bottomtitleone: 'How To Place A Bet',
        bottomtitletwo: 'How To Play On Premium Sportsbook',
    },
    {
        title: 'offers',
        iconimg: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tutorialaccimg/accotherimg.webp',
        imgone: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tutorialsimg.webp',
        bottomtitleone: 'Average Interest Bonus',
    },
    {
        title: 'fantasy pro',
        iconimg: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tutorialaccimg/accotherimg.webp',
        imgone: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tutorialsimg.webp',
        bottomtitleone: `${window.location.hostname.replace("www.", "").split(".")[0]} Fantasy Pro`,
    },
]