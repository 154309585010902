import { Box, Container, Grid, List, ListItem, Typography } from "@mui/material"
// import teen20img from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/t20-3-patti.webp"
import { Link } from "react-router-dom"

const listyle = {
  padding: 0,
  fontSize: "14px",
  color: "#fff",
  display: "list-item",
  listStyleType: "inherit",
}

const list = {
  p: 0,
  listStyleType: "disc",
  paddingInlineStart: "20px",
}
const T20TeenPatti = () => {
  return (
    <Box className="poker">
      <Container maxWidth="lg" sx={{ py: "10px", m: "17px 59px" }}>
        <Grid container columnSpacing={{ xs: 2 }}>
          <Grid item xs={4}>
            <Typography
              component="h1"
              sx={{
                fontSize: "32px",
                color: "#f36c21",
                fontFamily: "Lato,sans-serif",
                fontWeight: "700",
                paddingBottom: "8px",
                textAlign: "left",
              }}
            >
              Live Teen Patti
            </Typography>

            <div className="teenpattiimg max-w-[420px] mb-[27px]">
              <img
                src={
                  "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/t20-3-patti.webp"
                }
                alt=""
                className="max-w-[100%] h-auto"
              />
            </div>
          </Grid>
          <Grid item xs={8}>
            <Box className="pana">
              <Typography
                component="p"
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Lato,sans-serif",
                }}
              >
                All cards in Teenpatti T20 are dealt in one round itself. This
                is the main difference between T20 and Live TeenPatti.
              </Typography>
            </Box>
            <Box className="rules">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Rules:
              </Typography>
              <Typography
                component="p"
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Lato,sans-serif",
                }}
              >
                Teenpatti (also spelled Teenpathi and sometimes referred to as
                Flash) is a gambling card game that originated in India and
                became popular in Southeast Asia. Teenpatti is India’s twist on
                3 card poker and is identical to 3-card brag which is popular in
                the UK.
              </Typography>
            </Box>
            <Box className="howdoyouwin">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Ranking of the hands:
              </Typography>

              <Typography
                component="p"
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Lato,sans-serif",
                }}
              >
                Aces are ranked the highest and 2's are the lowest. The
                objective of the game is to have the best 3-card hand and to
                maximize the pot before the showdown. The categories are ranked
                as follows (from high to low):
              </Typography>
              <List sx={list}>
                <ListItem sx={listyle}>
                  Trail (Three of a Kind) : Three cards of the same rank. Three
                  aces are the highest and three 2’s are the lowest.
                </ListItem>
                <ListItem sx={listyle}>
                  Pure Sequence (Straight Flush) : Three consecutive cards of
                  the same suit.
                </ListItem>
                <ListItem sx={listyle}>
                  Sequence (Straight) : Three consecutive cards not all in the
                  same suit.
                </ListItem>
                <ListItem sx={listyle}>
                  Color (Flush) : Three cards of the same suit that are not in
                  sequence. When comparing two colors, first compare the highest
                  card. If these are equal, compare the second and if these are
                  equal compare the lowest. Highest flush is A-K-J and the
                  lowest flush is 5-3-2.
                </ListItem>
                <ListItem sx={listyle}>
                  Pair (Two of a Kind) : Two cards of the same rank. Between two
                  pairs, the one with the higher value is the winner. If the
                  pairs are of equal value then the kicker card determines the
                  winner. The highest pair is A-A-K and the lowest is 2-2-3.
                </ListItem>
                <ListItem sx={listyle}>
                  High Card : A hand in which the three cards are not in
                  sequence, not all the same suit and no two cards have the same
                  rank. If two players share a common high card, the next
                  highest card is used to determine the winner. The best high
                  card hand would be an AKJ of different suits and the worst is
                  5-3-2.
                </ListItem>
                <ListItem sx={listyle}>
                  High Card: A hand in which the three cards are not in
                  sequence, not all the same suit and no two cards have the same
                  rank. If two players share a common high card, the next
                  highest card is used to determine the winner. The best high
                  card hand would be an AKJ of different suits and the worst is
                  5-3-2.
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default T20TeenPatti
