import { Box, List, ListItem, Typography } from "@mui/material"
import { Link, useNavigate, useParams } from "react-router-dom"
import "./sportsmenu.css"
import { MdHome } from "react-icons/md"
import { MdOutlineKeyboardArrowLeft } from "react-icons/md"
import { sportsstaticdata } from "./sportsmenustaticdata"
import { useActiveMatchByIdQuery } from "../api/betFairApi/betFairApiSlice"
import { FC } from "react"

interface sprotsMenuProps {
  id: any
}

const SportsMenu: FC<sprotsMenuProps> = ({ id }) => {
  const nav = useNavigate()
  const { data: activematchData } = useActiveMatchByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  })
  const handleGoHome = (params: string | unknown) => {
    nav(`/sports/cricket/4`)
  }

  const handlePrevious = () => {
    nav(-1)
  }

  const handleNavToSportEventDetail = (data: any) => {
    nav(`/sports-event-detail/${data?.matchId}/${id}`)
  }

  return (
    <Box
      className="sportsmenuleftmenu2"
      sx={{
        borderRadius: "15px",
        overflow: "hidden",
        border: "1px solid #f36c21",
      }}
    >
      <Box
        className="gotohome"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Link to={"/sports/Cricket/4"} onClick={handleGoHome}>
          <MdHome color="#fff" fontSize={25} spacing={1} />
        </Link>
        <Link
          to={"/sports/Cricket/4"}
          className="text-white text-[16px] ml-3 capitalize font-semibold"
        >
          sports
        </Link>
      </Box>

      <Box
        className="previous"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          background: "rgba(68,68,68,.5)",
        }}
      >
        <Box
          onClick={handlePrevious}
          className="flex items-center w-full px-[10px] py-[9px] "
        >
          <MdOutlineKeyboardArrowLeft color="#fff" fontSize={25} spacing={1} />
          <Typography
            component="span"
            sx={{ fontSize: "14px", color: "#fff", ml: 1 }}
          >
            Previous
          </Typography>
        </Box>
      </Box>

      <List sx={{ p: 0 }}>
        {activematchData?.data?.map((matchdata, idx) => (
          <ListItem
            key={matchdata?.matchName + idx}
            onClick={() => handleNavToSportEventDetail(matchdata)}
            sx={{
              cursor: "pointer",
              padding: "9px 15px",
              borderBottom: "1px solid #cccccc24",
              color: "#fff",
              fontSize: "14px",
              ":hover": {
                boxShadow: "inset 0 8px 26px -23px hsla(0,0%,100%,.75)",
                transition: "color .6s ease-in-out",
              },
            }}
          >
            {matchdata?.matchName}
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default SportsMenu
