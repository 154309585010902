import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout } from '../../features/auth/authslice';

const baseQuery = fetchBaseQuery({
    baseUrl: import.meta.env.VITE_QTECH_PROVIDER,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token');
        if (token) headers.set('Authorization', `Bearer ${token}`);
        return headers;
    }
});

const wrapperQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>
    = async (args, api, extraOptions) => {
        let result = await baseQuery(args, api, extraOptions)

        if (result?.error?.status === 401) api.dispatch(logout());
        if (result?.error?.status === 403) api.dispatch(logout());
        if ((result.data as ErrorRes)?.responseCode === 401) api.dispatch(logout());
        if ((result.data as ErrorRes)?.responseCode === 403) api.dispatch(logout());
        return result
    };

const qtechApiSlice = createApi({
    reducerPath: 'qtechApi',
    baseQuery: wrapperQuery,
    endpoints: (build) => {
        return {
            // 1
            qtechAuthentication: build.query<liveauthenticationRes, any>({
                query: () => {
                    return {
                        url: '/qtech/authentication',
                        method: 'POST',
                    }
                }
            }),
            // 2
            qtechprovider: build.query<any, ProviderPayload>({
                query: (args) => {
                    return {
                        url: '/qtech/provider',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            // 3
            qtechgamelist: build.query<gamelistRes, gamelistPayload>({
                query: (args) => {
                    return {
                        url: '/qtech/gamelist',
                        method: 'POST',
                        body: args
                    }
                }
            }),

            gamelink: build.mutation<gamelinkRes, gamelinkPayload>({
                query: (args) => {
                    return {
                        url: "/qtech/gamelink",
                        method: 'POST',
                        body: args
                    }
                }
            })
        }

    }
})

export const {
    useQtechAuthenticationQuery,
    useQtechproviderQuery,
    useQtechgamelistQuery,
    useGamelinkMutation
} = qtechApiSlice;

export default qtechApiSlice;