import HeadingBar from "../../../common/HeadingBar"
import GameTabs from "./markets/GameTabs"

const LiveCasino = () => {
  return (
    <div>
      <HeadingBar title="live casino" />

      <GameTabs />
    </div>
  )
}

export default LiveCasino
