import { Box, Container, List, ListItem, Typography } from "@mui/material"
import HeadingBar from "../../../../common/HeadingBar"
import { Link } from "react-router-dom"

const heading = {
  fontSize: "1rem",
  color: "#fff",
  textAlign: "left",
  lineHeight: "1.75rem",
  letterSpacing: ".009375em",
  fontFamily: "Roboto,sans-serif!important",
}

const content = {
  fontSize: "14px",
  color: "#fff",
  textAlign: "left",
  lineHeight: "1.75rem",
  letterSpacing: ".009375em",
  fontFamily: "Roboto,sans-serif!important",
  mb: 2,
}

const TermCondition = () => {
  return (
    <Box className="termconditonparent">
      <HeadingBar title="Term & Conditions" />
      <Box className="contentholder" sx={{ backgroundColor: "#000" }}>
        <Container maxWidth="lg" sx={{ p: "17px 59px" }}>
          <Typography sx={heading}>I. {window.location.hostname.replace("www.", "").split(".")[0]}</Typography>

          <Typography sx={content}>
            By subscribing/registering to/on our{" "}
            <Link to={"#"} className="underline text-[#f36c21]">
              {window.location.hostname.replace("www.", "")}
            </Link>{" "}
            website, and its sub-domains (collectively referred to as
            {window.location.hostname.replace("www.", "").split(".")[0]} site) and/or any services including, registering
            and playing betting games, participating in betting contests and
            tournaments, provided on the {window.location.hostname.replace("www.", "").split(".")[0]} site, you agree that
            you have read, understood and have agreed to be bound by this Terms
            and Conditions, regardless of how you subscribe to or use the
            services.
          </Typography>

          <Typography sx={content}>
            Please note that this Privacy Policy will be agreed between you and
            {window.location.hostname.replace("www.", "").split(".")[0]}. (‘We’, ‘Us’ or ‘Our’, as appropriate). This
            Privacy Policy is an integrated part of {window.location.hostname.replace("www.", "").split(".")[0]}’s Terms and
            Conditions. We may periodically make changes to this Privacy Policy
            and will notify you of these changes by posting the modified terms
            on our platforms. We recommend that you revisit this Privacy Policy
            regularly.Please read the Terms carefully and if you do not accept
            the Terms, do not use, visit or access any part (including, but not
            limited to, sub-domains, source code and/or website APIs, whether
            visible or not) of the {window.location.hostname.replace("www.", "").split(".")[0]} site. The Terms shall also
            apply to all telephone betting and betting or gaming via mobile
            devices including downloadable applications to a mobile device (as
            if references to your use of the Website were references to your use
            of our telephone betting and/or mobile devices betting facilities).
            “We”, “us”, “our”, “{window.location.hostname.replace("www.", "").split(".")[0]}” shall mean Win Ventures NV
            and/or any of its affiliated entities. For the purpose of these
            Terms and Conditions, wherever the context so require "you" or
            "your" or "User" or “Participant” shall mean any natural or legal
            person who has agreed to become a member of the {window.location.hostname.replace("www.", "").split(".")[0]}
            site by visiting the {window.location.hostname.replace("www.", "").split(".")[0]} site as registered User or a
            person who has used the {window.location.hostname.replace("www.", "").split(".")[0]} site either through
            browsing or otherwise. Where you play any game, or place a bet or
            wager, using the Website, you accept and agree to be bound by, the
            Rules which apply to the applicable products available on the
            Website from time to time.
          </Typography>

          <Typography sx={content}>
            {window.location.hostname.replace("www.", "").split(".")[0]} one stop shop for leisure gambling games including
            Baccarat, Teen Patti, Roulette, Poker, Blackjack and sports betting
            through which it offers cricket based, football, tennis, horse
            racing based online betting games along with live casino betting.
            The {window.location.hostname.replace("www.", "").split(".")[0]} site is neither affiliated by nor associated to
            any sort of private or government Sports leagues and tournaments
            until and unless expressly stated by {window.location.hostname.replace("www.", "").split(".")[0]}. In addition
            to this, the {window.location.hostname.replace("www.", "").split(".")[0]} app is not related and does not claim
            any official status with any of the official or non-official sports
            teams and/or sportspersons.
          </Typography>

          <Typography sx={heading}>II. USER REGISTRATION</Typography>

          <Typography sx={content}>
            To participate in {window.location.hostname.replace("www.", "").split(".")[0]} activities, you must register by
            creating a {window.location.hostname.replace("www.", "").split(".")[0]} club account by providing your true
            information. In the event you wish to play on the {window.location.hostname.replace("www.", "").split(".")[0]}
            site you shall create an account with us. You shall do so after
            completing the registration process by:
          </Typography>
          <Typography sx={content}>
            1. Providing true, accurate, correct and complete information as
            prompted by the applicable registration form.
          </Typography>
          <Typography sx={content}>
            2. Maintaining and updating true, accurate, correct and complete
            information provided by you during the registration process.
          </Typography>
          <Typography sx={content}>
            You shall be required to choose a username and password. You shall
            be solely responsible for maintaining the confidentiality of your
            account username and password. You shall be solely responsible for
            the registration information provided by you during submissions and
            the consequences of posting or publishing them. {window.location.hostname.replace("www.", "").split(".")[0]} is
            only acting as a repository of data and makes no guarantee as to the
            validity, accuracy, or legal status of any information /
            submissions.
          </Typography>
          <Typography sx={content}>
            If, at any time, {window.location.hostname.replace("www.", "").split(".")[0]} believes that your account and
            password is being misused in any manner, or that the information
            provided by you during the above stated registration process is not
            true, inaccurate or incomplete, then {window.location.hostname.replace("www.", "").split(".")[0]} reserves the
            right to terminate/cancel or suspend your account and block your
            access to the {window.location.hostname.replace("www.", "").split(".")[0]} site. You shall be solely and
            exclusively responsible for any and/or all the activities that are
            carried through your account. You agree to notify {window.location.hostname.replace("www.", "").split(".")[0]}
            of any unauthorized use of your account and/or any breach of
            security please contact us at chat. {window.location.hostname.replace("www.", "").split(".")[0]} shall not be
            liable for any loss that you may incur as a result of another
            person(s) using your account username and/or password and / or your
            failure to comply with this section. You shall be solely and
            exclusively liable for all the losses to {window.location.hostname.replace("www.", "").split(".")[0]} and/or
            other claims, damages, fines due to such an unauthorized use.
          </Typography>
          <Typography sx={content}>
            If {window.location.hostname.replace("www.", "").split(".")[0]} charges you a platform fee (facilitation fee) in
            respect of any {window.location.hostname.replace("www.", "").split(".")[0]} services, {window.location.hostname.replace("www.", "").split(".")[0]} shall,
            without delay, refund such platform fee in the event of suspension
            and/or termination of your account or {window.location.hostname.replace("www.", "").split(".")[0]} services on
            account of any fault on the part of {window.location.hostname.replace("www.", "").split(".")[0]}. It is hereby
            clarified that no refund shall be payable if such suspension and/or
            termination is affected due to any breach or failure to adhere any
            of these Terms and Conditions, Privacy Policy and/or any other rules
            by the you, the User, or a person(s) accessing your account by using
            your username and password; or any circumstances beyond the
            reasonable control of {window.location.hostname.replace("www.", "").split(".")[0]}.
          </Typography>
          <Typography sx={heading}>
            III. REGISTRATION AND PARTICIPANTS
          </Typography>
          <Typography sx={content}>
            The games/tournaments/contests available on the {window.location.hostname.replace("www.", "").split(".")[0]}
            site are open only to persons aged eighteen (18) years or above at
            the date of usage and/or registration, who are participating from
            jurisdiction where gaming on {window.location.hostname.replace("www.", "").split(".")[0]} is permitted.
            Employees of {window.location.hostname.replace("www.", "").split(".")[0]}, Play Ventures NV, their associated,
            affiliated and subsidiary companies and their families, agents, game
            sponsors, and any person connected with {window.location.hostname.replace("www.", "").split(".")[0]} are
            excluded from participating in the game and/or winning a prize. By
            taking part in a game (excluding administering the game) on the
            {window.location.hostname.replace("www.", "").split(".")[0]} site, Users/Participants warrant that all
            information submitted by them is true, accurate and complete proof
            of age may be required. Where this is not the case, we reserve the
            right to suspend the account and treat any deposits into the
            gambling account as being invalid (and any winnings arising from
            such deposit as void). Where an account is suspended, the relevant
            customer may contact us at chat seeking details regarding the same.
            The sole discretion to declare a User ineligible to participate
            shall vest with {window.location.hostname.replace("www.", "").split(".")[0]}. Users/Participants who wish to
            participate shall have a valid email address. In order to register
            for the betting games, Users/Participants are required to accurately
            provide the following information:
          </Typography>
          <List sx={{ listStyleType: "disc", paddingLeft: "10px" }}>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Full Name
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              E-mail address
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Password
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Gender
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Date of birth
            </ListItem>
          </List>
          <Typography sx={content}>
            As part of the registration process, we may supply your information
            details to authorized credit reference agencies to confirm your
            identity and payment card details. You agree that we may process
            such information in connection with your registration. Participants
            may open only one account; in case we identify any customer with
            more than one account we reserve the right to treat any such
            accounts as one joint account. Participant must keep their
            registration and account details up to date. This, and your account
            information, may be amended in the personal section of the Website.
            If you require any assistance, please contact us at chat.
          </Typography>
          <Typography sx={heading}>IV. ACCOUNT DETAILS</Typography>
          <Typography sx={content}>
            {window.location.hostname.replace("www.", "").split(".")[0]} allows all its users to choose their own Username
            and Password combination for their account. Users must keep this
            information secret and confidential as you are responsible for all
            bets/wagers placed on your account and any other activities taking
            place on your account. Bets will stand if your Username and Password
            have been entered correctly or if your account has been accessed via
            Touch ID, Fingerprint log in, Face ID, Passcode, subject to there
            being sufficient funds in the account.
          </Typography>
          <Typography sx={content}>
            If, at any time, you feel a third party is aware of your Username,
            Password you should change it immediately via the {window.location.hostname.replace("www.", "").split(".")[0]}
            site. In case you forget part or all of your combination, contact us
            at chat or avail the Chat support made available for Users on the
            Platform. If you activate Touch ID, Fingerprint log in, Face ID,
            Passcode and feel that a third party could be accessing your account
            via any of these methods then you should immediately disable Touch
            ID, Fingerprint log in, Face ID, Passcode from all of your devices
            and contact us at chat. You are responsible for the security of your
            device(s) and for all bets/wagers placed on your account and any
            other activities taking place on your account.
          </Typography>
          <Typography sx={content}>
            If you nominate another person as an authorized user of your
            account, you shall be responsible for all transactions such person
            makes using the relevant account details. Should you lose your
            account details or feel that someone else may have your account
            details, please contact us at chat. Please note that cardholder
            details and any other sensitive data should never be sent to us by
            unencrypted email. The current balance and transaction history of
            your account may be viewed at any time once you have logged into
            your account on the Website.
          </Typography>
          <Typography sx={heading}>V. SUSPENSION AND CLOSURE</Typography>
          <Typography sx={content}>
            Reach out to us at chat in case of closure of accounts. Any negative
            balance on your behalf will be immediately due and payable to
            {window.location.hostname.replace("www.", "").split(".")[0]}, and your account will not be closed until the
            relevant amount owed to {window.location.hostname.replace("www.", "").split(".")[0]} is paid in full.
            {window.location.hostname.replace("www.", "").split(".")[0]} reserves the right to close or suspend your account
            at any time and for any reason including any violation of laws, if a
            user is participating on the {window.location.hostname.replace("www.", "").split(".")[0]} site illegally.
            Without limiting the preceding sentence, {window.location.hostname.replace("www.", "").split(".")[0]} shall be
            entitled to close or suspend your account if:
          </Typography>
          <Typography sx={content}>
            i.{window.location.hostname.replace("www.", "").split(".")[0]} considers that you have used the Website in a
            fraudulent manner or for illegal and/or unlawful or improper
            purposes;
            <br />
            ii. {window.location.hostname.replace("www.", "").split(".")[0]} considers that you have used the Website in an
            unfair manner, have deliberately cheated or taken unfair advantage
            of {window.location.hostname.replace("www.", "").split(".")[0]} or any of its customers or if your account is
            being used for the benefit of a third party;
            <br />
            iii. {window.location.hostname.replace("www.", "").split(".")[0]} considers that you have opened or are using
            any additional accounts to conceal your activity or to avoid
            measures put in place on other active or inactive accounts;
            <br />
            iv. {window.location.hostname.replace("www.", "").split(".")[0]} considers that you have deliberately provided
            incomplete or inaccurate information when registering with the site
            or during of our verification processes;
            <br />
            v. {window.location.hostname.replace("www.", "").split(".")[0]} is requested to do so by the police, any
            governmental or other regulatory authority, court or other authority
            of competent jurisdiction, law or applicable regulation;
            <br />
            vi. {window.location.hostname.replace("www.", "").split(".")[0]} considers that any of the events referred to in
            (i) to (iv) above may have occurred or are likely to occur; or
            <br />
            vii. you become bankrupt;
            <br />
            viii. you are in breach of {window.location.hostname.replace("www.", "").split(".")[0]}’s Responsible Gaming
            Policy and/or {window.location.hostname.replace("www.", "").split(".")[0]} determines that your continued
            participation may be detrimental to your health;
            <br />
            ix. your account is deemed to be dormant and its balance is, or
            reaches zero or is otherwise closed in accordance with the Terms and
            Conditions listed herein.
          </Typography>
          <Typography sx={content}>
            If {window.location.hostname.replace("www.", "").split(".")[0]} closes or suspends your account for any of the
            reasons referred to in (i) to (viii) above, you shall be liable for
            any and all claims, losses, liabilities, damages, costs and expenses
            incurred or suffered by {window.location.hostname.replace("www.", "").split(".")[0]} (together “Claims”) arising
            therefrom and shall indemnify and hold {window.location.hostname.replace("www.", "").split(".")[0]} harmless on
            demand for such Claims. In the circumstances referred to in (i) to
            (iv) above (“Prohibited Events”), {window.location.hostname.replace("www.", "").split(".")[0]} shall also be
            entitled to void any bets or other activity and withhold and/or
            retain any and all amounts which would otherwise have been paid or
            payable to you (including any winnings, Bet Credits or bonus
            payments) that are attributable to a Prohibited Event or up to an
            amount no greater than any amount previously withdrawn by you where
            that amount withdrawn is attributable to a Prohibited Event.
          </Typography>
          <Typography sx={heading}>VI. FINANCES</Typography>
          <Typography sx={heading}>A. Deposits And Wagers</Typography>
          <Typography sx={content}>
            You may only bet/wager with the amount of cleared funds held in your
            account. Accordingly, if you want to place bets or participate in
            gaming, you must deposit monies into your account. By depositing
            funds into your account, you direct us and we agree to hold them,
            along with any winnings, for the sole and specific purpose of using
            them (i) to place your sporting and gaming stakes; and (ii) settling
            any fees or charges that you might incur in connection with the use
            of our services (Purpose). We shall be entitled to suspend or close
            your account if we reasonably consider or have reason to believe
            that you are depositing funds without any intention to place
            sporting and/or gaming stakes. In such circumstances we may also
            report this to relevant authorities.
          </Typography>
          <Typography sx={content}>
            For payment methods that require a specific account holder, you
            should only deposit funds into your gambling account from a source
            where you are the named account holder. In such cases where
            {window.location.hostname.replace("www.", "").split(".")[0]} identifies that the named account holder differs
            from that which holds the {window.location.hostname.replace("www.", "").split(".")[0]} account, we reserve the
            right to treat any deposits into the gambling account as being
            invalid (and any winnings arising from such deposit as void).
          </Typography>
          <Typography sx={content}>
            For payment methods that require a specific account holder, you
            should only deposit funds into your gambling account from a source
            where you are the named account holder. In such cases where
            {window.location.hostname.replace("www.", "").split(".")[0]} identifies that the named account holder differs
            from that which holds the {window.location.hostname.replace("www.", "").split(".")[0]} account, we reserve the
            right to treat any deposits into the gambling account as being
            invalid (and any winnings arising from such deposit as void).
          </Typography>
          <Typography sx={content}>
            All funds that we hold for you are held in separate designated
            customer bank accounts to be used solely for the Purpose and the
            bank has acknowledged this. We must return the funds to you if they
            are not used for the Purpose. The bank has acknowledged that your
            funds are held in those accounts solely for the Purpose. The bank
            has confirmed that it will not mix customers’ funds in those
            accounts with any other, including our own, and that it has no claim
            on the funds itself. In view of the above, in the event of our
            insolvency, your funds are protected. The funds would still belong
            to you and would not fall into the hands of the liquidator.
          </Typography>
          <Typography sx={content}>
            All funds that we hold for you are held in separate designated
            customer bank accounts to be used solely for the Purpose and the
            bank has acknowledged this. We must return the funds to you if they
            are not used for the Purpose. The bank has acknowledged that your
            funds are held in those accounts solely for the Purpose. The bank
            has confirmed that it will not mix customers’ funds in those
            accounts with any other, including our own, and that it has no claim
            on the funds itself. In view of the above, in the event of our
            insolvency, your funds are protected. The funds would still belong
            to you and would not fall into the hands of the liquidator.
          </Typography>
          <Typography sx={content}>
            You agree that we shall be entitled to retain any interest which
            might accrue on monies held in your {window.location.hostname.replace("www.", "").split(".")[0]} account. No
            credit will be offered by any employee of {window.location.hostname.replace("www.", "").split(".")[0]}, and all
            bets must be supported by sufficient funds in the customer account.
            {window.location.hostname.replace("www.", "").split(".")[0]} reserves the right to void any bet/wager which may
            have inadvertently been accepted when the account did not have
            sufficient funds to cover the bet/wager. Should funds be credited to
            a customer's account in error it is the customer's responsibility to
            inform {window.location.hostname.replace("www.", "").split(".")[0]} without delay, {window.location.hostname.replace("www.", "").split(".")[0]} will recover
            such funds by account adjustment. Unless otherwise stated, all
            examples given on the {window.location.hostname.replace("www.", "").split(".")[0]} site are given in Euro(s)
            and/or the consequent local currency depending on the geographical
            location of the Users.
          </Typography>
          <Typography sx={content}>
            If {window.location.hostname.replace("www.", "").split(".")[0]} considers that you are using the Website for any
            reason other than the authorized purpose, {window.location.hostname.replace("www.", "").split(".")[0]} shall be
            entitled to close or suspend your account and you shall: a. be
            liable for any and all claims incurred arising therefrom; and b.
            indemnify {window.location.hostname.replace("www.", "").split(".")[0]} on demand for such claims.
          </Typography>
          <Typography sx={content}>
            In addition, {window.location.hostname.replace("www.", "").split(".")[0]} shall be entitled to withhold and/or
            retain any and all amounts earned or received by you as a result of
            or in connection with your ‘unauthorized’ use of the Website
            including activities which are not for an authorized purpose.
            {window.location.hostname.replace("www.", "").split(".")[0]} does not charge for deposits made by Debit/Credit
            Card, however please be aware that some card issuers consider
            betting transactions as ‘cash’ payments and therefore may charge you
            a cash advance fee. Please check with your card issuer for further
            details. Any and all bank charges and other chargeback fee, shall
            solely be the liability of the User/yours.
          </Typography>
          <Typography sx={heading}>B. Withdrawals</Typography>
          <List sx={{ listStyleType: "number", paddingLeft: "10px" }}>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              All withdrawals will be processed to the payment account from
              which the deposits were made. Withdrawal payments can only be made
              in the name of and to the registered account holder.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              For most payment types, withdrawals can be processed by clicking
              'Withdraw' on the Website, subject to there being sufficient funds
              in your betting account. There is no set maximum withdrawal amount
              per day.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              If the value of a deposit is not played through in full before a
              withdrawal is requested, {window.location.hostname.replace("www.", "").split(".")[0]} reserves the right to
              make a charge to the customer’s account to cover all reasonable
              costs relating to both the deposit and withdrawal. If necessary,
              the value of the withdrawal requested may be reduced accordingly.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              If the deposit method of the account is paysafecard and the value
              of a deposit is not played through in full before a withdrawal is
              requested, or there is any evidence of a series of bets/wagers
              placed which results in guaranteed customer profits irrespective
              of the outcome, or where all the betting/wagering is determined to
              be low risk, {window.location.hostname.replace("www.", "").split(".")[0]} reserves the right to request a
              receipt showing proof of purchase of the voucher before any
              withdrawals are processed.
            </ListItem>
          </List>

          <Typography sx={heading}>C. Other</Typography>

          <List sx={{ listStyleType: "number", paddingLeft: "10px" }}>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              If we incur any charge-backs, reversals or other charges in
              respect of your account, we reserve the right to charge you for
              the relevant amounts incurred. We may, at any time, set off any
              positive balance on your account against any amounts owed by you
              to {window.location.hostname.replace("www.", "").split(".")[0]} or any company within the {window.location.hostname.replace("www.", "").split(".")[0]}
              group.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              If we incur any charge-backs, reversals or other charges in
              respect of your account, we reserve the right to charge you for
              the relevant amounts incurred. We may, at any time, set off any
              positive balance on your account against any amounts owed by you
              to {window.location.hostname.replace("www.", "").split(".")[0]} or any company within the {window.location.hostname.replace("www.", "").split(".")[0]}
              group.
            </ListItem>
          </List>

          <Typography sx={heading}>VII. BETTING PROCEDURES</Typography>
          <Typography sx={heading}>A. Placing Bets/Wagers</Typography>
          <Typography sx={content}>
            It is the responsibility of the customer to ensure details of their
            bets/wagers are correct. Once bets/wagers have been placed they may
            not be cancelled by the customer. Bets can only be changed by the
            customer using our Edit Bet feature, where this is available.
            {window.location.hostname.replace("www.", "").split(".")[0]} reserves the right to cancel any bet/wager at any
            time.
          </Typography>
          <Typography sx={content}>
            Your funds will be allocated to bets/wagers in the order they are
            placed and will not be available for any other use. {window.location.hostname.replace("www.", "").split(".")[0]}
            reserves the right to void and/or reverse any transactions made
            after a bet/wager has been placed involving allocated funds, either
            at the time or retrospectively.
          </Typography>
          <Typography sx={heading}>B. Bet/Wager Confirmation</Typography>
          <Typography sx={content}>
            Bets/wagers will not be valid if there are insufficient funds in
            your account. In case of telephone bets/wagers 'acceptance
            confirmed' will be deemed only after an operator has completed a
            full read back of the bet/wager details and confirmed to the
            customer that the bet/wager has been successfully processed and
            accepted.
          </Typography>
          <Typography sx={content}>
            A bet/wager that you request will only be valid once accepted by
            {window.location.hostname.replace("www.", "").split(".")[0]}’s servers. Each valid bet/wager will receive a
            unique transaction code. We shall not be liable for the settlement
            of any bets/wagers which are not issued with a unique transaction
            code. If you are unsure about the validity of a bet/wager, please
            check your account history, or Contact Us.
          </Typography>
          <Typography sx={content}>
            Notwithstanding the above, if bets/wagers are placed via a credit or
            debit card they are not valid until we have received payment in
            full. The bet/wager will be automatically void if we have not
            received the payment prior to the relevant event commencing.
          </Typography>
          <Typography sx={content}>
            Should a dispute arise, you and {window.location.hostname.replace("www.", "").split(".")[0]} agree that the
            {window.location.hostname.replace("www.", "").split(".")[0]} transaction log database will be the ultimate
            authority in such matters.
          </Typography>
          <Typography sx={heading}>C. Offers</Typography>
          <Typography sx={content}>
            Where any term of an offer or promotion is breached or there is any
            evidence of a series of bets placed by a customer or group of
            customers, which due to enhanced payments, Free Bets, risk free
            bets, Bet Credits or any other promotional offer results in
            guaranteed customer profits irrespective of the outcome, whether
            individually or as part of a group, {window.location.hostname.replace("www.", "").split(".")[0]} may reclaim the
            enhanced payment, Free Bet, risk free bet or Bet Credit element of
            such offers and/or void any bet funded by the Free Bet or Bet
            Credits. In addition, where there is evidence of such activity,
            {window.location.hostname.replace("www.", "").split(".")[0]} may levy an administration charge on the customer
            up to the value of the Bet Credits, Free Bet, risk free bet or
            enhanced payment towards the administrative costs incurred in
            identifying and taking action against the activity.
          </Typography>
          <Typography sx={content}>
            {window.location.hostname.replace("www.", "").split(".")[0]} may reclaim any bonus amount, Free Bets, Bet
            Credits or enhanced payments that have been awarded in error. All
            customer offers are limited to one per person. If {window.location.hostname.replace("www.", "").split(".")[0]}
            has reasonable grounds to suspect that a bonus or offer is being
            claimed by or for the benefit of the same person more than once or
            by a group of people then it may withdraw the availability of any
            offer or all offers to that customer or group of customers and/or
            void any bet funded by the bonus or offer and remove any winnings
            from such bet.
          </Typography>
          <Typography sx={content}>
            {window.location.hostname.replace("www.", "").split(".")[0]} may, at any time, make minor amendments to the
            terms and conditions of any offer or promotion to correct
            typographical errors or to improve on clarity or customer experience
            and may cancel any offer or promotion for legal or regulatory
            reasons. {window.location.hostname.replace("www.", "").split(".")[0]} reserves the right to amend the terms of
            or cancel any customer offer or promotion at any time.
          </Typography>
          <Typography sx={heading}>D. Settlement and Payouts</Typography>
          <List sx={{ listStyleType: "number", paddingLeft: "20px" }}>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              All bets and wagers are subject to the Betting Coverage provisions
              (including maximum winnings) set out in Appendix Two to these
              Terms and Conditions.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              {window.location.hostname.replace("www.", "").split(".")[0]} reserves the right to suspend a market and/or
              cancel any bet/wager anytime. When a market is suspended any bets
              entered will be rejected. {window.location.hostname.replace("www.", "").split(".")[0]} also reserves the right
              to cease betting on any markets at any time without notice. Should
              a customer include a non-runner or void selection in a multiple
              bet/parlay, the bet will be settled on the remaining selections.
              This includes selections that have been made void where ‘Void if
              player does not start’ is selected at bet placement.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Winnings from settled bets/wagers are added to the balance of your
              betting account. Any funds/winnings credited to an account in
              error are not available for use, and {window.location.hostname.replace("www.", "").split(".")[0]} reserves the
              right to void any transactions involving such funds and/or
              withdraw the relevant amount from your account and/or reverse the
              transaction, either at the time or retrospectively.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              {window.location.hostname.replace("www.", "").split(".")[0]} reserves the right to withhold payment and to
              declare bets on an event void if we have evidence that the
              following has occurred: (i) the integrity of the event has been
              called into question; (ii) the price(s) or pool has been
              manipulated; or (iii) match rigging has taken place. Evidence of
              the above may be based on the size, volume or pattern of bets
              placed with {window.location.hostname.replace("www.", "").split(".")[0]} across any or all of our betting
              channels. A decision given by the relevant governing body of the
              sport in question (if any) will be conclusive. If any customer
              owes any money to {window.location.hostname.replace("www.", "").split(".")[0]} for any reason, we have the
              right to take that into account before making any payments to that
              customer.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Where there is evidence of a series of bets each containing the
              same selection(s) having been placed by or for the same individual
              or syndicate of individuals, {window.location.hostname.replace("www.", "").split(".")[0]} reserves the right
              to make bets void or withhold payment of returns pending the
              outcome of any subsequent investigation.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              For events where there is no official 'off' declared, the
              advertised start time of the event will be deemed the 'off'. If
              for any reason a bet is inadvertently accepted after an event or
              match has commenced (other than live In-Play betting clearly
              indicated on the Website), bets will stand providing the final
              outcome is not known, and that no participant/team has gained any
              material advantage (e.g., a score, sending off for the other team,
              etc.) at the time the bet was placed. If the outcome of an
              event/market is known {window.location.hostname.replace("www.", "").split(".")[0]} reserves the right to void
              the bet, win or lose. Disputes over times bets are placed will be
              settled using the transaction log. All times stated on the Website
              and/or referred to by {window.location.hostname.replace("www.", "").split(".")[0]} staff relate to Sri Lanka
              Standard Time (SLST) unless stated otherwise.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              In-Play betting - where we have reason to believe that a bet is
              placed after the outcome of an event is known, or after the
              selected participant or team has gained a material advantage
              (e.g., a score, sending off for the other team etc.) we reserve
              the right to void the bet, win or lose. If for any reason we are
              unable to validate the outcome of a particular market (e.g., due
              to loss of live pictures), all bets will be void, unless
              settlement of bets is already determined.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Where a customer gives ambiguous instructions, {window.location.hostname.replace("www.", "").split(".")[0]}
              reserves the right to split the number of monies staked between
              the potential outcomes. Where this is impractical, we reserve the
              right to void the whole of that bet. In any event, the decision of
              {window.location.hostname.replace("www.", "").split(".")[0]} is final.
            </ListItem>
          </List>
          <Typography sx={heading}>
            VIII. USE OF THE {window.location.hostname.replace("www.", "").split(".")[0]} SITE
          </Typography>
          <Typography sx={content}>
            All materials provided on the {window.location.hostname.replace("www.", "").split(".")[0]} site, including but
            not limited to all information, materials, functions, text, logos,
            designs, graphics, assets/images, sounds, software, documents,
            products and services (collectively, the "Materials"), and the
            selection, arrangement and display thereof, are the copyrighted
            works of {window.location.hostname.replace("www.", "").split(".")[0]} and/or its vendors and/or suppliers. All
            Materials herein and {window.location.hostname.replace("www.", "").split(".")[0]}’s software are the exclusive
            properties of {window.location.hostname.replace("www.", "").split(".")[0]}. Except as stated herein, none of the
            Materials may be modified, copied, reproduced, distributed,
            republished, downloaded, displayed, sold, compiled, posted or
            transmitted in any form or by any means, including but not limited
            to, electronic, mechanical, photocopying, recording or other means,
            without the prior written permission of {window.location.hostname.replace("www.", "").split(".")[0]}.
          </Typography>

          <Typography sx={content}>
            For payment methods that require a specific account holder, you
            should only deposit funds into your gambling account from a source
            where you are the named account holder. In such cases where
            {window.location.hostname.replace("www.", "").split(".")[0]} identifies that the named account holder differs
            from that which holds the {window.location.hostname.replace("www.", "").split(".")[0]} account, we reserve the
            right to treat any deposits into the gambling account as being
            invalid (and any winnings arising from such deposit as void).
          </Typography>
          <Typography sx={content}>
            Subject to your compliance with these Terms and Conditions,
            {window.location.hostname.replace("www.", "").split(".")[0]} hereby grants you the right to access and use the
            {window.location.hostname.replace("www.", "").split(".")[0]} site only for personal and non-commercial purposes.
            You undertake that you shall not use the {window.location.hostname.replace("www.", "").split(".")[0]} site for
            any commercial purposes and/or in any way that is unlawful, or harms
            us or any other person or entity, as determined in our sole
            discretion.
          </Typography>
          <Typography sx={content}>
            You may post/share links/assets/images/text content on the
            {window.location.hostname.replace("www.", "").split(".")[0]} site on social media platforms like Facebook,
            Twitter, Google+, Snapchat, Instagram, YouTube or any other social
            media sharing platform with appropriate link-back to the original
            source.
          </Typography>
          <Typography sx={content}>
            When the {window.location.hostname.replace("www.", "").split(".")[0]} site provides for the download of any
            particular Material including any software, you may download such
            Material and/or software to a computer or mobile device (as
            applicable) for your personal, non-commercial use only, provided
            that you shall:
          </Typography>

          <List sx={{ listStyleType: "disc", paddingLeft: "20px" }}>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              keep a record of and not amend copyright and other proprietary
              notices;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              make no modifications to, and do not rent, lease, loan, sell,
              distribute, copy (except to create a single copy for your own
              back-up purposes), or create any derivative works based on the
              {window.location.hostname.replace("www.", "").split(".")[0]} site or the Material including the software, in
              whole or in part, and;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              not transfer any Material and/or software and/or any part thereof
              to any other computer and/or mobile device. Any commercial or
              business use of the {window.location.hostname.replace("www.", "").split(".")[0]} site or any Material
              including the software is expressly prohibited.
            </ListItem>
          </List>

          <Typography sx={content}>
            Except as expressly provided above, nothing contained herein shall
            be construed as conferring, by implication, estoppel or otherwise,
            any license or right under any patent, trademark, design or
            copyright of the {window.location.hostname.replace("www.", "").split(".")[0]} intellectual properties. You
            acknowledge and agree that nothing in these Terms and Conditions
            shall have the effect of transferring the ownership of any
            copyrights, trademarks, service marks, trade names, source code or
            other proprietary rights on the {window.location.hostname.replace("www.", "").split(".")[0]} site or Material or
            software or any part thereof to you or any third party.
          </Typography>

          <Typography sx={heading}>
            IX. PROHIBITED USE AND {window.location.hostname.replace("www.", "").split(".")[0]} POLICY
          </Typography>

          <Typography sx={heading}>a. Prohibited Use.</Typography>
          <Typography sx={content}>
            As a condition of your use of the {window.location.hostname.replace("www.", "").split(".")[0]} site, you shall
            not use the {window.location.hostname.replace("www.", "").split(".")[0]} site for any purpose that is unlawful
            or prohibited under these Terms and Conditions or under any relevant
            laws, statutes, order, ordinances and regulations. The duty to
            verify whether the use of the {window.location.hostname.replace("www.", "").split(".")[0]} site is legal in your
            jurisdiction is your sole and exclusive responsibility. You shall
            not display, upload, modify, publish, transmit, update or share any
            information on the {window.location.hostname.replace("www.", "").split(".")[0]} site that is:
          </Typography>
          <List sx={{ listStyleType: "number", pl: 2 }}>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              promoting illegal or tortuous activities or conduct that is
              abusive, threatening, obscene, defamatory or libelous;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              attempting to circumvent, disable or otherwise interfere with
              security-related features of {window.location.hostname.replace("www.", "").split(".")[0]} or features that
              prevent or restrict use or copying of any content or enforce
              limitations on the use of {window.location.hostname.replace("www.", "").split(".")[0]} or the content therein;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              using cheats, exploits, automation software, bots, hacks, mods or
              any other unauthorized software designed to modify or interfere
              with the {window.location.hostname.replace("www.", "").split(".")[0]} site, and taking advantage of cheats or
              exploits;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              using any software that intercepts, mines, or otherwise collects
              information about other Users or copies and stores any Proprietary
              Materials;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              interfering with, disrupting, or creating an undue burden on the
              {window.location.hostname.replace("www.", "").split(".")[0]} site or the networks connected to the
              {window.location.hostname.replace("www.", "").split(".")[0]} site;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              attempting to impersonate another User or person;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              soliciting personal information from anyone under the eighteen
              (18) years of age;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              using the account, username, or password of another account holder
              at any time or disclosing the User account password to any third
              party or permitting any third party to access the User Account;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              using any information obtained from the {window.location.hostname.replace("www.", "").split(".")[0]} site in
              order to harass, abuse, or harm another person;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              using any unfair, misleading or deceptive content intended to draw
              traffic to one’s profile;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              using the {window.location.hostname.replace("www.", "").split(".")[0]} site and/or software in a manner
              inconsistent with any and all applicable laws and regulations;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              sub-license, rent, lease, sell, trade, gift, bequeath or other
              transfer of the User account associated with the User account to
              anyone;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              access or use of a third-party account that have been
              sub-licensed, rented, leased, sold, traded, gifted, bequeathed, or
              otherwise transferred from the holder or the original account
              creator; and
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              using the {window.location.hostname.replace("www.", "").split(".")[0]} site and/or Software and/or materials
              for any commercial purpose.
            </ListItem>
          </List>

          <Typography sx={heading}>b. {window.location.hostname.replace("www.", "").split(".")[0]} Policy.</Typography>
          <Typography sx={content}>
            We at {window.location.hostname.replace("www.", "").split(".")[0]} consider the {window.location.hostname.replace("www.", "").split(".")[0]} of online
            betting game/tournament/contest of utmost importance. In order to
            prevent any form of fraud or {window.location.hostname.replace("www.", "").split(".")[0]} in our
            games/contests/tournaments or on our site - all User actions
            including - deposits/identity verification/Betting
            Games/Tournaments/Contests are monitored to ensure a safe, legal and
            fair environment for you (the Users/Participants). Every single
            betting game/tournament/contest shall be a fair individual effort.
            Hence, as part of the {window.location.hostname.replace("www.", "").split(".")[0]} policy to prevent any form of
            fraud & in-game collusion all User/Participant actions are monitored
            and recorded. Any detection of a breach of our Fair Play policy,
            such as and not limited to, fraudulent deposits under a stolen
            identity or collusion in gameplay, shall result in swift and serious
            action taken by {window.location.hostname.replace("www.", "").split(".")[0]} against the suspected
            User/Participant.
          </Typography>
          <Typography sx={heading}>X. LEGALITY:</Typography>
          <Typography sx={content}>
            The User’s/Participant’s use of the {window.location.hostname.replace("www.", "").split(".")[0]} site and
            software is also subject to relevant legislations which apply to the
            User on the basis of the location from which he/she accesses the
            {window.location.hostname.replace("www.", "").split(".")[0]} site.
          </Typography>
          <Typography sx={content}>
            In case wherein detailed audits on the end of {window.location.hostname.replace("www.", "").split(".")[0]}, any
            User/Participant is found to be a resident of any of a
            jurisdiction/territory in which gaming/gambling or any aspect of it
            is restricted/prohibited and it is found that the user is hiding
            such details from {window.location.hostname.replace("www.", "").split(".")[0]} at the time of registration then
            actions mentioned under these Terms and Conditions including (but
            not limited to) blocking of account, account reset, account deletion
            or deactivation shall be initiated. If the laws of these
            jurisdictions change and the residents of these jurisdictions are
            allowed to participate in the paid versions of games offered on the
            {window.location.hostname.replace("www.", "").split(".")[0]} Site, the Users/Participants shall be treated as
            new registrations and in no case, previous winnings shall be
            considered and any claim of such sort will not be entertained in any
            case.
          </Typography>
          <Typography sx={content}>
            The User/Participant agrees that {window.location.hostname.replace("www.", "").split(".")[0]} shall not be held
            liable if laws applicable to the User/Participant restricts or
            prohibits his/her participation.
          </Typography>
          <Typography sx={content}>
            {window.location.hostname.replace("www.", "").split(".")[0]} makes no representations or warranties, implicit or
            explicit, as to the User’s legal right to participate nor shall any
            person affiliated or claiming affiliation with the User/Participant
            have authority to make any such representations or warranties.
          </Typography>
          <Typography sx={content}>
            The {window.location.hostname.replace("www.", "").split(".")[0]} site and service are hosted in Curacao and are
            intended for and directed to Users/Participants all over the world
            (except where the same is prohibited under local applicable laws
            ‘Excluded Regions’). Users/ Participants are advised to check the
            local laws prior to participating on the {window.location.hostname.replace("www.", "").split(".")[0]} site. If
            you are a User/Participant accessing the {window.location.hostname.replace("www.", "").split(".")[0]} site and
            service from any region with laws or regulations governing personal
            data collection, use, and disclosure, your continued use of
            {window.location.hostname.replace("www.", "").split(".")[0]} site and service you consent to transfer your
            personal information to {window.location.hostname.replace("www.", "").split(".")[0]} as per the laws and
            regulations applicable at that time.
          </Typography>
          <Typography sx={heading}>XI. USER CONDUCT</Typography>

          <List sx={{ listStyleType: "disc", pl: 2 }}>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              You agree to abide by these Terms and Conditions and all other
              rules, regulations of the {window.location.hostname.replace("www.", "").split(".")[0]} Website and/or App. In
              the event you do not abide by these Terms and Conditions and all
              other rules, regulations, {window.location.hostname.replace("www.", "").split(".")[0]} may, at its sole and
              exclusive discretion, take necessary remedial action, including
              but not limited to:
            </ListItem>
          </List>

          <List typeof="a" sx={{ listStyle: "lower-alpha", pl: 2 }}>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              restricting, suspending, or terminating your access to all or any
              part of {window.location.hostname.replace("www.", "").split(".")[0]} services;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              deactivating or deleting your account and all related information
              and files on the account. Any winnings amount on the date of
              deactivation or deletion shall be transferred to the User account
              in {window.location.hostname.replace("www.", "").split(".")[0]} database subject to a processing fee (if any)
              applicable on such transfers as set out herein; or
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              refraining from awarding any prize(s) to such User.
            </ListItem>
          </List>

          <List sx={{ listStyleType: "disc", pl: 2 }}>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              You agree to provide true, accurate, current and complete
              information at the time of registration and at all other times (as
              required by {window.location.hostname.replace("www.", "").split(".")[0]}). You further agree to update and
              keep updated your registration information as and when required by
              {window.location.hostname.replace("www.", "").split(".")[0]}.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              You shall provide all the necessary Mandatory Information
              Documents (“MIDs”) as and when required by {window.location.hostname.replace("www.", "").split(".")[0]} as per
              compliance of the Laws of Curacao and its regulations.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              You agree to ensure that you can receive all communication from
              {window.location.hostname.replace("www.", "").split(".")[0]} by marking e-mails from {window.location.hostname.replace("www.", "").split(".")[0]} as part
              of your "safe senders" list. {window.location.hostname.replace("www.", "").split(".")[0]} shall not be held
              liable if any e-mail remains unread as a result of such an e-mail
              being delivered to your junk and/or spam folder.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Any password issued by {window.location.hostname.replace("www.", "").split(".")[0]} to you shall not be
              revealed to anyone else. You shall strictly avoid using anyone
              else's password. You shall be responsible for maintaining the
              confidentiality of your account and password. You agree to
              immediately notify {window.location.hostname.replace("www.", "").split(".")[0]} of any unauthorized use of
              your account or any other breach of security.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              You agree to exit/log-out of your accounts at the end of each
              session. {window.location.hostname.replace("www.", "").split(".")[0]} shall not be responsible for any loss
              and/or damage that may result if you fail to comply with these
              requirements.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              You agree not to use cheats, exploits, automation, software, bots,
              hacks or any unauthorized third-party software designed to modify
              or interfere with {window.location.hostname.replace("www.", "").split(".")[0]} services and/or
              {window.location.hostname.replace("www.", "").split(".")[0]} experience or assist in such activity.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              You agree not to copy, modify, rent, lease, loan, sell, assign,
              distribute, reverse engineer, grant a security interest in, or
              otherwise transfer any right to the technology or software
              underlying {window.location.hostname.replace("www.", "").split(".")[0]} or {window.location.hostname.replace("www.", "").split(".")[0]} services.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              You agree that without {window.location.hostname.replace("www.", "").split(".")[0]}'s express written consent,
              you shall not modify or cause to modify any files or software that
              are a part of {window.location.hostname.replace("www.", "").split(".")[0]}'s services.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              You agree that you shall not indulge in collusive play with other
              Users/Participants on the {window.location.hostname.replace("www.", "").split(".")[0]} site in any manner.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              You agree not to disrupt, overburden, or aid or assist in the
              disruption or overburdening of (a) any computer or server used to
              offer or support {window.location.hostname.replace("www.", "").split(".")[0]} or the {window.location.hostname.replace("www.", "").split(".")[0]} services
              (each a "Server"); or (b) enjoyment of the {window.location.hostname.replace("www.", "").split(".")[0]}
              services by any other User/Participant or person.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              You agree not to institute, assist or become involved in any type
              of attack, including without limitation to the distribution of a
              virus, denial of service, or other attempts to disrupt the
              {window.location.hostname.replace("www.", "").split(".")[0]} services or any other person's use or enjoyment
              of the {window.location.hostname.replace("www.", "").split(".")[0]} services.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              You shall not attempt to gain unauthorized access to the User
              accounts, servers or networks connected to the {window.location.hostname.replace("www.", "").split(".")[0]}
              services by any means other than the User interface provided by
              {window.location.hostname.replace("www.", "").split(".")[0]}, including but not limited to, by circumventing
              or modifying, attempting to circumvent or modify, or encouraging
              or assisting any other person to circumvent or modify, any
              security, technology, device, or software that is part of the
              {window.location.hostname.replace("www.", "").split(".")[0]} services.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              If you choose a username that, in {window.location.hostname.replace("www.", "").split(".")[0]}'s opinion is
              considered to be obscene, indecent, abusive or that might subject
              {window.location.hostname.replace("www.", "").split(".")[0]} to public disparagement or scorn,
              {window.location.hostname.replace("www.", "").split(".")[0]} reserves the right, without prior notice, to
              change such username and intimate you or delete such username from
              {window.location.hostname.replace("www.", "").split(".")[0]}, deny you access to {window.location.hostname.replace("www.", "").split(".")[0]}, or any
              combination of these options.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Unauthorized access to the {window.location.hostname.replace("www.", "").split(".")[0]} site shall be
              considered a breach of these Terms and Conditions, Privacy Policy
              and a violation of the applicable laws and regulations of Curacao.
              You agree not to access the {window.location.hostname.replace("www.", "").split(".")[0]} site by any means
              other than through the interface that is provided by
              {window.location.hostname.replace("www.", "").split(".")[0]} for use in accessing the {window.location.hostname.replace("www.", "").split(".")[0]} site.
              You agree not to use any automated means, including, without
              limitation, agents, robots, scripts, or spiders, to access,
              monitor, or copy any part of our site, except those that has been
              approved in advance and in writing by us.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Use of the {window.location.hostname.replace("www.", "").split(".")[0]} site shall be subject to existing laws
              & regulations of Curacao and legal processes. Nothing contained in
              these Terms and Conditions, shall limit the {window.location.hostname.replace("www.", "").split(".")[0]}'s
              right to comply with governmental, court, and law- enforcement
              requests or requirements relating to User’s use of
              {window.location.hostname.replace("www.", "").split(".")[0]}
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Users may contact chat with problems or questions, as appropriate.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Users agree not to institute, assist or become involved in any
              type of attack, including without limitation to distribution of a
              virus, denial of service, or other attempts to disrupt the
              {window.location.hostname.replace("www.", "").split(".")[0]} Services or any other person's use or enjoyment
              of the {window.location.hostname.replace("www.", "").split(".")[0]} Services.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Users shall not attempt to gain unauthorized access to other User
              accounts on {window.location.hostname.replace("www.", "").split(".")[0]} by any means, including but not
              limited to, by circumventing or modifying, attempting to
              circumvent or modify, or encouraging or assisting any other person
              to circumvent or modify, any security, technology, device, or
              software that underlies or is part of the {window.location.hostname.replace("www.", "").split(".")[0]}
              services.
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Users provide their acceptance for not using the {window.location.hostname.replace("www.", "").split(".")[0]}
              platform for any sort of inadequate activities including (but not
              limited to): to engage in any obscene, offensive, indecent,
              racial, communal, anti-national, objectionable, defamatory or
              abusive action or communication; to harass, stalk, threaten, or
              otherwise, violate any legal rights of other individuals; to
              transmit files that contain viruses, corrupted files, or any other
              similar software or programs that may damage or adversely affect
              the operation of another person's computer, {window.location.hostname.replace("www.", "").split(".")[0]} site,
              any software, hardware, or telecommunications equipment; to
              advertise, offer or sell any goods or services for any commercial
              purpose on {window.location.hostname.replace("www.", "").split(".")[0]}1.co.in without written consent of
              {window.location.hostname.replace("www.", "").split(".")[0]}; to Transmit content regarding services,
              products, surveys, contests, pyramid schemes, spam, unsolicited
              advertising or promotional materials, or chain letters.{" "}
            </ListItem>
          </List>

          <Typography sx={heading}>XII. PARTICIPATION IN CONTESTS</Typography>

          <List sx={{ listStyleType: "disc", pl: 2 }}>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              By entering a Contest, User/Participant agrees to be bound by
              these Terms and the decisions of {window.location.hostname.replace("www.", "").split(".")[0]}. Subject to the
              terms and conditions stipulated herein below, the Company, at its
              sole discretion, may disqualify any User/Participant from a
              Contest, refuse to award benefits or prizes and require the return
              of any prizes, if the User engages in unfair conduct, which the
              Company deems to be improper, unfair or otherwise adverse to the
              operation of the Contest or is in any way detrimental to other
              Users/Participants which includes, but is not limited to:
            </ListItem>
          </List>

          <List sx={{ listStyle: "lower-alpha", pl: 2 }}>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              Falsifying ones’ own personal information (including, but not
              limited to, name, email address, bank account details and/or any
              other information or documentation as may be requested by
              {window.location.hostname.replace("www.", "").split(".")[0]} to enter a contest and/or claim a prize/winning.
            </ListItem>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              Engaging in any type of financial fraud or misrepresentation
              including unauthorized use of credit/debit instruments, payment
              wallet accounts etc. to enter a Contest or claim a prize. It is
              expressly clarified that the onus to prove otherwise shall solely
              lie on the User.
            </ListItem>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              Colluding with any other User(s) / Participant(s) or engaging in
              any type of syndicate play.
            </ListItem>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              Any violation of Contest rules or the Terms and Conditions.
            </ListItem>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              Accumulating points or prizes through unauthorized methods such as
              automated bots, or other automated means.
            </ListItem>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              Using automated means (including but not limited to harvesting
              bots, robots, parser, spiders or screen scrapers) to obtain,
              collect or access any information on the Website or of any
              User/Participant for any purpose
            </ListItem>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              Tampering with the administration of a Contest or trying to in any
              way tamper with the computer programs or any security measure
              associated with a Contest;
            </ListItem>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              Obtaining other Users’/Participants’ information without their
              express consent and/or knowledge and/or spamming other
              Users/Participants (Spamming may include but shall not be limited
              to send unsolicited emails to Users/Participants, sending bulk
              emails to {window.location.hostname.replace("www.", "").split(".")[0]} Users/Participants, sending unwarranted
              email content either to selected Users/Participants or in bulk);
              or
            </ListItem>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              Abusing the Website in any way. It is clarified that in case a
              User/Participant is found to be in violation of this policy,
              {window.location.hostname.replace("www.", "").split(".")[0]} reserves its right to initiate appropriate
              Civil/Criminal remedies as it may be advised other than forfeiture
              and/or recovery of prize money if any.
            </ListItem>
          </List>

          <Typography sx={heading}>XIII. INTELLECTUAL PROPERTY</Typography>
          <Typography sx={content}>
            Unless otherwise stated, copyright and/or all intellectual property
            rights in all materials on the {window.location.hostname.replace("www.", "").split(".")[0]} site (including but
            not limited to text, audio, video or graphical assets/images, or
            technology, the look and feel of the {window.location.hostname.replace("www.", "").split(".")[0]} site),
            trademarks and logos appearing on the {window.location.hostname.replace("www.", "").split(".")[0]} site are the
            properties of {window.location.hostname.replace("www.", "").split(".")[0]} and are owned & controlled by us
            and/or by other parties that have licensed their materials to us.
            You shall not copy, reproduce, republish, upload, post, transmit or
            distribute the {window.location.hostname.replace("www.", "").split(".")[0]} site materials in any way, including
            by e-mail and/or other electronic means and whether directly or
            indirectly and/or assist any other person to do the same. Without
            the prior written consent of the owner of the materials,
            modification, use of the materials on any other web site and/or
            networked computer environment and/or use of the materials for any
            purpose other than personal, non-commercial use shall be considered
            a violation of the copyrights, trademarks and other proprietary
            rights of {window.location.hostname.replace("www.", "").split(".")[0]} and shall be prohibited.
          </Typography>
          <Typography sx={content}>
            Any use of the materials other than for personal and non-commercial
            purposes shall be considered as commercial use for the purposes of
            this clause. You agree not to use any framing techniques to enclose
            any trademark or logo or other proprietary information of
            {window.location.hostname.replace("www.", "").split(".")[0]} or remove, conceal or obliterate any copyright or
            other proprietary notice or any credit-line or date-line on other
            mark or source identifier included on the {window.location.hostname.replace("www.", "").split(".")[0]} site,
            including without limitation, the size, color combinations, design,
            font, location or style of all proprietary marks. Any infringement
            shall be vigorously defended and pursued to the extent permitted by
            applicable law.
          </Typography>
          <Typography sx={heading}>XIV. LIMITATIONS OF LIABILITY</Typography>
          <Typography sx={content}>
            Users shall access the {window.location.hostname.replace("www.", "").split(".")[0]} Services provided on
            {window.location.hostname.replace("www.", "").split(".")[0]} voluntarily and at their own risk. {window.location.hostname.replace("www.", "").split(".")[0]}
            shall, under no circumstances be held responsible or liable on
            account of any loss or damage sustained (including but not limited
            to any accident, injury, death, loss of property) by Users or any
            other person or entity during the course of access to the
            {window.location.hostname.replace("www.", "").split(".")[0]} Services (including participation in the
            Contest(s)) or as a result of acceptance of any prize.
          </Typography>
          <Typography sx={content}>
            By entering the contests and accessing the {window.location.hostname.replace("www.", "").split(".")[0]} Services
            provided therein, Users hereby release from and agree to indemnify
            {window.location.hostname.replace("www.", "").split(".")[0]}, and/ or any of its directors, employees, partners,
            associates and licensors, from and against all liability, cost, loss
            or expense arising out their access to the {window.location.hostname.replace("www.", "").split(".")[0]} Services
            including (but not limited to) personal injury and damage to
            property and whether direct, indirect, consequential, foreseeable,
            due to some negligent act or omission on their part, or otherwise.
            {window.location.hostname.replace("www.", "").split(".")[0]} accepts no liability, whether jointly or severally,
            for any errors or omissions, whether on behalf of itself or third
            parties in relation to the prizes.
          </Typography>
          <Typography sx={content}>
            Users shall be solely responsible for any consequences which may
            arise due to their access of {window.location.hostname.replace("www.", "").split(".")[0]} Services by conducting
            an illegal act or due to non-conformity with these Terms and
            Conditions and other rules and regulations in relation to
            {window.location.hostname.replace("www.", "").split(".")[0]} Services, including provision of incorrect address
            or other personal details. Users also undertake to indemnify
            {window.location.hostname.replace("www.", "").split(".")[0]} and their respective officers, directors, employees
            and agents on the happening of such an event (including without
            limitation cost of attorney, legal charges etc.) on full indemnity
            basis for any loss/damage suffered by {window.location.hostname.replace("www.", "").split(".")[0]} on account of
            such act on the part of the Users.
          </Typography>
          <Typography sx={content}>
            Users shall indemnify, defend, and hold {window.location.hostname.replace("www.", "").split(".")[0]} harmless
            from any third party/entity/organization claims arising from or
            related to such User's engagement with the {window.location.hostname.replace("www.", "").split(".")[0]} or
            participation in any Contest. In no event shall {window.location.hostname.replace("www.", "").split(".")[0]} be
            liable to any User for acts or omissions arising out of or related
            to User's engagement with the {window.location.hostname.replace("www.", "").split(".")[0]} or his/her
            participation in any Contest(s). In consideration of
            {window.location.hostname.replace("www.", "").split(".")[0]} allowing Users to access the {window.location.hostname.replace("www.", "").split(".")[0]}
            Services, to the maximum extent permitted by law, the Users waive
            and release each and every right or claim, all actions, causes of
            actions (present or future) each of them has or may have against
            {window.location.hostname.replace("www.", "").split(".")[0]}, its respective agents, directors, officers,
            business associates, group companies, sponsors, employees, or
            representatives for all and any injuries, accidents, or mishaps
            (whether known or unknown) or (whether anticipated or unanticipated)
            arising out of the provision of {window.location.hostname.replace("www.", "").split(".")[0]} Services or related
            to the Contests or the prizes of the Contests.
          </Typography>

          <Typography sx={heading}>
            XV. THIRD PARTY SITES, SERVICES AND PRODUCTS
          </Typography>
          <Typography sx={content}>
            {window.location.hostname.replace("www.", "").split(".")[0]} may contain links to other Internet sites owned and
            operated by third parties. Users' use of each of those sites is
            subject to the conditions, if any, posted by the sites.
            {window.location.hostname.replace("www.", "").split(".")[0]} does not exercise control over any Internet sites
            apart from {window.location.hostname.replace("www.", "").split(".")[0]} and cannot be held responsible for any
            content residing in any third-party Internet site.
            {window.location.hostname.replace("www.", "").split(".")[0]}'s inclusion of third-party content or links to
            third-party Internet sites is not an endorsement by {window.location.hostname.replace("www.", "").split(".")[0]}
            of such third-party Internet site.
          </Typography>
          <Typography sx={content}>
            Users' correspondence, transactions/offers or related activities
            with third parties, including payment providers and verification
            service providers, are solely between the User and that third party.
            Users' correspondence, transactions and usage of the services/offers
            of such third party shall be subject to the terms and conditions,
            policies and other service terms adopted/implemented by such third
            party, and the User shall be solely responsible for reviewing the
            same prior to transacting or availing of the services/offers of such
            third party. User agrees that {window.location.hostname.replace("www.", "").split(".")[0]} will not be
            responsible or liable for any loss or damage of any sort incurred as
            a result of any such transactions/offers with third parties. Any
            questions, complaints, or claims related to any third-party product
            or service should be directed to the appropriate vendor.
          </Typography>
          <Typography sx={content}>
            {window.location.hostname.replace("www.", "").split(".")[0]} contains content that is created by
            {window.location.hostname.replace("www.", "").split(".")[0]} as well as content provided by third parties.
            {window.location.hostname.replace("www.", "").split(".")[0]} does not guarantee the accuracy, integrity, quality
            of the content provided by third parties and such content may not
            relied upon by the Users in utilizing the {window.location.hostname.replace("www.", "").split(".")[0]} Services
            provided on {window.location.hostname.replace("www.", "").split(".")[0]} including while participating in any of
            the contests hosted on {window.location.hostname.replace("www.", "").split(".")[0]}.
          </Typography>
          <Typography sx={heading}>XVI. PRIVACY POLICY</Typography>
          <Typography sx={content}>
            All information collected from Users, such as registration and
            credit card information, is subject to {window.location.hostname.replace("www.", "").split(".")[0]}'s Privacy
            Policy which is available at Privacy Policy.
          </Typography>
          <Typography sx={heading}>XVII. CONTACTING WINNERS</Typography>
          <Typography sx={content}>
            Winners shall be contacted by {window.location.hostname.replace("www.", "").split(".")[0]} either through the
            e-mail address provided at the time of registration or through
            either Calls or Short Messaging Service (SMS) on their registered
            Phone Number.
          </Typography>
          <Typography sx={content}>
            {window.location.hostname.replace("www.", "").split(".")[0]} shall not permit a Winner to withdraw his/her
            prize(s)/accumulated winnings unless some government approved ID
            have been received and verified within the time-period stipulated by
            {window.location.hostname.replace("www.", "").split(".")[0]}. The User represents and warrants that the
            documents provided in the course of the verification process are
            true copies of the original documents to which they relate.
          </Typography>
          <Typography sx={content}>
            Participants are required to provide proper and complete details at
            the time of registration. {window.location.hostname.replace("www.", "").split(".")[0]} shall not be responsible
            for communications errors, commissions or omissions including those
            of the Participants due to which the results may not be communicated
            to the Winner.
          </Typography>
          <Typography sx={content}>
            In the event that a Participant has been declared a Winner on the
            {window.location.hostname.replace("www.", "").split(".")[0]} site but has not received any communication from
            {window.location.hostname.replace("www.", "").split(".")[0]}, such Participant may contact {window.location.hostname.replace("www.", "").split(".")[0]}
            within the time specified on the webpage.
          </Typography>
          <Typography sx={heading}>XVIII. VERIFICATION PROCESS</Typography>
          <Typography sx={content}>
            Only those Winners who successfully complete the verification
            process and provide the required documents within the time limit
            specified by {window.location.hostname.replace("www.", "").split(".")[0]} shall be permitted to withdraw/receive
            their accumulated winnings (or any part thereof). {window.location.hostname.replace("www.", "").split(".")[0]}
            shall not entertain any claims or requests for extension of time for
            submission of documents.
          </Typography>
          <Typography sx={content}>
            {window.location.hostname.replace("www.", "").split(".")[0]} shall scrutinize all documents submitted and may,
            at its sole and absolute discretion, disqualify any Winner from
            withdrawing his accumulated winnings (or any part thereof).
          </Typography>
          <Typography sx={heading}>XIX. TAXES PAYABLE</Typography>
          <Typography sx={content}>
            All prizes shall be subject to withholding tax implications payable
            by you. {window.location.hostname.replace("www.", "").split(".")[0]} shall not be responsible for checking the
            laws of your jurisdiction pertaining to withholding tax, double
            taxation arrangements amongst others. The Winners shall be
            responsible for payment of any other applicable taxes, including but
            not limited to, income tax, gift tax, etc. in respect of the prize
            money.
          </Typography>
          <Typography sx={heading}>XX. MISCELLANEOUS</Typography>
          <Typography sx={content}>
            The decision of {window.location.hostname.replace("www.", "").split(".")[0]} with respect to the awarding of
            prizes shall be final, binding and non-contestable. If it is found
            that a Participant playing the paid formats of the Contest(s) is a
            resident of any jurisdiction from where participation is prohibited,
            {window.location.hostname.replace("www.", "").split(".")[0]} shall disqualify such Participant and forfeit any
            prize won by such Participant. Further {window.location.hostname.replace("www.", "").split(".")[0]} may, at its
            sole and absolute discretion, suspend or terminate such
            Participant's account with {window.location.hostname.replace("www.", "").split(".")[0]}. Any amount remaining
            unused in the User's Game Account or Winnings Account on the date of
            deactivation or deletion shall be reimbursed to the User by an
            online transfer to the User's bank account on record with
            {window.location.hostname.replace("www.", "").split(".")[0]}, subject to the processing fee (if any) applicable
            on such transfers as set out herein
          </Typography>
          <Typography sx={content}>
            If it is found that a Participant playing the paid formats of the
            Contest(s) is under the age of eighteen (18), {window.location.hostname.replace("www.", "").split(".")[0]} shall
            be entitled, at its sole and absolute discretion, to disqualify such
            Participant and forfeit his/her prize. Further, {window.location.hostname.replace("www.", "").split(".")[0]}
            may, at its sole and absolute discretion, suspend or terminate such
            Participant's account.
          </Typography>
          <Typography sx={content}>
            To the extent permitted by law, {window.location.hostname.replace("www.", "").split(".")[0]} makes no
            representations or warranties as to the quality, suitability or
            merchantability of any prizes and shall not be liable in respect of
            the same. {window.location.hostname.replace("www.", "").split(".")[0]} may, at its sole and absolute discretion,
            vary or modify the prizes being offered to winners. Participants
            shall not raise any claim against {window.location.hostname.replace("www.", "").split(".")[0]} or question its
            right to modify such prizes being offered, prior to closure of the
            Contest. {window.location.hostname.replace("www.", "").split(".")[0]} will not bear any responsibility for the
            transportation or packaging of prizes to the respective winners.
            {window.location.hostname.replace("www.", "").split(".")[0]} shall not be held liable for any loss or damage
            caused to any prizes at the time of such transportation. The Winners
            shall bear the shipping, courier or any other delivery cost in
            respect of the prizes. The Winners shall bear all transaction
            charges levied for delivery of cash prizes. All prizes are
            non-transferable and non-refundable. Prizes cannot be exchanged /
            redeemed for cash or kind. No cash claims can be made in lieu of
            prizes in kind.
          </Typography>
          <Typography sx={heading}>XXI. PUBLICITY</Typography>
          <Typography sx={content}>
            Acceptance of a prize by the Winner constitutes permission for
            {window.location.hostname.replace("www.", "").split(".")[0]}, and its affiliates to use the Winner's name,
            likeness, voice and comments for advertising and promotional
            purposes in any media worldwide for purposes of advertising and
            trade without any further permissions or consents and / or
            additional compensation whatsoever.
          </Typography>
          <Typography sx={content}>
            The Winners further undertake that they will be available for
            promotional purposes as planned and desired by {window.location.hostname.replace("www.", "").split(".")[0]}
            without any charge. The exact dates remain the sole discretion of
            {window.location.hostname.replace("www.", "").split(".")[0]}. Promotional activities may include but not be
            limited to press events, internal meetings and ceremonies/functions.
          </Typography>
          <Typography sx={heading}>XXII. LIMITATION OF LIABILITY</Typography>
          <Typography sx={content}>
            In no event shall {window.location.hostname.replace("www.", "").split(".")[0]} its officers, directors,
            employees, or agents, be liable to you for any direct, indirect,
            incidental, special, punitive, or consequential damages whatsoever
            resulting from any:
          </Typography>

          <List sx={{ listStyle: "lower-roman", pl: 2 }}>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              errors, mistakes, or inaccuracies of content;
            </ListItem>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              personal injury or property damage, of any nature whatsoever,
              resulting from your access to and use of our service;
            </ListItem>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              any unauthorized access to or use of our secure servers and/or any
              and all personal information and/or financial information stored
              therein;
            </ListItem>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              any interruption and/or cessation of transmission to or from our
              servers;
            </ListItem>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              any bugs, viruses, trojan horses, or the like, which may be
              transmitted to or through our service by any third party;
            </ListItem>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              any errors or omissions in any content or for any loss or damage
              of any kind incurred as a result of your use of any content
              posted, emailed, transmitted, or otherwise made available via the
              {window.location.hostname.replace("www.", "").split(".")[0]} client, whether based on warranty, contract,
              tort, or any other legal theory, and whether or not
              {window.location.hostname.replace("www.", "").split(".")[0]} is advised of the possibility of such damages;
              and/or
            </ListItem>
            <ListItem sx={{ display: "list-item", color: "#fff", p: 0 }}>
              the disclosure of information pursuant to these Terms and
              Conditions or privacy policy of {window.location.hostname.replace("www.", "").split(".")[0]}. The foregoing
              limitation of liability shall apply to the extent permitted by law
              in the applicable jurisdiction.
            </ListItem>
          </List>

          <Typography sx={content}>
            You specifically acknowledge that {window.location.hostname.replace("www.", "").split(".")[0]} shall not be
            liable for any defamatory, offensive, or illegal conduct of any
            third party and that the risk of any harm or damage from the
            foregoing shall rest entirely with you.
          </Typography>
          <Typography sx={heading}>
            XXIII. TECHNICAL FAILURES AND DISCONNECTION POLICY
          </Typography>
          <Typography sx={content}>
            It is possible that you may face disruptions, including, but not
            limited to errors, disconnection or inferences in communication in
            the Internet services, software or hardware that you have used to
            avail our services. {window.location.hostname.replace("www.", "").split(".")[0]} shall not be liable for any
            network outage or any problems/interruptions with network
            connectivity including network, Internet services, computer hardware
            or software failures of any kind in the disruption in the services
            as the same is beyond {window.location.hostname.replace("www.", "").split(".")[0]}’s control. You take full
            responsibility with complete knowledge for any risk of loss or
            damages caused due to interruption of services for any such reasons.
          </Typography>

          <Typography sx={content}>
            You agree that {window.location.hostname.replace("www.", "").split(".")[0]} shall not be held responsible and/or
            liable for any interruption in a {window.location.hostname.replace("www.", "").split(".")[0]} game and/or
            tournament and/or contest caused at the User’s end due to an
            incoming call, message, chat, video call or any other interruption
            on the mobile phone device/tablet device. In case of any
            interruption specified above in a {window.location.hostname.replace("www.", "").split(".")[0]} game and/or
            tournament and/or Contest on the User’s mobile phone device/tablet
            device, the game and/or tournament and/or contest shall still
            continue, and the User shall assume complete responsibility for such
            interruptions in between the games and/or tournaments and/or
            contest.
          </Typography>
          <Typography sx={heading}>XXIV. INDEMNITY</Typography>
          <Typography sx={content}>
            You agree to defend, indemnify and hold harmless {window.location.hostname.replace("www.", "").split(".")[0]},
            its officers, directors, employees and agents, from and against any
            and all claims, damages, obligations, losses, liabilities, costs or
            debt, and expenses (including but not limited to attorney's fees)
            arising from:
          </Typography>

          <List sx={{ pl: 2, listStyle: "lower-alpha" }}>
            <ListItem sx={{ color: "#fff", p: 0, display: "list-item" }}>
              your use of and access to the {window.location.hostname.replace("www.", "").split(".")[0]} site;
            </ListItem>
            <ListItem sx={{ color: "#fff", p: 0, display: "list-item" }}>
              your violation of any term of these Terms and Conditions;
            </ListItem>
            <ListItem sx={{ color: "#fff", p: 0, display: "list-item" }}>
              your violation of any third party right, including without
              limitation any trademark, copyright, property, or any other
              privacy right;
            </ListItem>
            <ListItem sx={{ color: "#fff", p: 0, display: "list-item" }}>
              any violation of applicable law, rules, regulation, orders and
              ordinance. This defense and indemnification obligation shall
              survive these Terms and Conditions.
            </ListItem>
          </List>
          <Typography sx={heading}>
            XXV. GOVERNING LAW AND JURISDICTION
          </Typography>
          <Typography sx={content}>
            {window.location.hostname.replace("www.", "").split(".")[0]} is licensed to provide gambling services and
            products to you by Play Ventures NV who are sub-licensee’s of the
            Master Gaming license issued by the Ministry of Justice, Official
            Decree of the Central Government of Curaçao No 365/JAZ dated August
            18, 1998 No 14 signed by the Minister of Justice laying down the
            Master License granted to Gaming Services Provider allowing the
            operation of Games of Chance on the International market via service
            lines. Thus, the relevant and applicable laws of Curacao are to be
            adhered to by the users under these Terms and Conditions.
          </Typography>
          <Typography sx={heading}>XXVI. DISPUTE RESOLUTION</Typography>
          <Typography sx={content}>
            The courts or tribunals of competent jurisdiction at Curacao shall
            have jurisdiction to determine any and all disputes arising out of,
            or in connection with, the {window.location.hostname.replace("www.", "").split(".")[0]} Services provided by
            {window.location.hostname.replace("www.", "").split(".")[0]} (including the Contest(s)), the construction,
            validity, interpretation and enforceability of these Terms and
            Conditions, or the rights and obligations of the User(s) (including
            Participants) or {window.location.hostname.replace("www.", "").split(".")[0]}, as well as the exclusive
            jurisdiction to grant interim or preliminary relief in case of any
            dispute referred to arbitration as given below. All such issues and
            questions shall be governed and construed in accordance with the
            laws of Curacao.
          </Typography>
          <Typography sx={content}>
            In the event of any legal dispute (which may be a legal issue or
            question) which may arise, the party raising the dispute shall
            provide a written notification ("Notification") to the other party.
            On receipt of Notification, the parties shall first try to resolve
            the dispute through discussions. In the event that the parties are
            unable to resolve the dispute within fifteen (15) days of receipt of
            Notification, the dispute shall be settled by arbitration.
          </Typography>
          <Typography sx={content}>
            The arbitration award will be final and binding on the Parties, and
            each Party will bear its own costs of arbitration and equally share
            the fees of the arbitrator unless the arbitral tribunal decides
            otherwise. The arbitrator shall be entitled to pass interim orders
            and awards, including the orders for specific performance and such
            orders would be enforceable in competent courts. The arbitrator
            shall give a reasoned award.
          </Typography>
          <Typography sx={content}>
            Nothing contained in these Terms and Conditions shall prevent
            {window.location.hostname.replace("www.", "").split(".")[0]} from seeking and obtaining interim or permanent
            equitable or injunctive relief, or any other relief available to
            safeguard {window.location.hostname.replace("www.", "").split(".")[0]}'s interest prior to, during or following
            the filing of arbitration proceedings or pending the execution of a
            decision or award in connection with any arbitration proceedings
            from any court having jurisdiction to grant the same. The pursuit of
            equitable or injunctive relief shall not constitute a waiver on the
            part of {window.location.hostname.replace("www.", "").split(".")[0]} to pursue any remedy for monetary damages
            through the arbitration described herein.
          </Typography>
          <Typography sx={heading}>XXVII. BREACH AND CONSEQUENCES</Typography>
          <Typography sx={content}>
            If we have evidence of a breach of our Terms and Conditions through
            our investigations or reasonable belief that your continued access
            to our website/app (platform) is deleterious or detrimental to the
            interests of {window.location.hostname.replace("www.", "").split(".")[0]} and/or our Users or the general
            public, then we reserve the right in our sole discretion to take one
            or all of the actions as mentioned below:
          </Typography>
          <List sx={{ listStyle: "disc", pl: 2 }}>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Permanent suspension and/or termination of your user account on
              our platform;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Forfeit the balance amount left in your account;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Demand and order damages for breach of {window.location.hostname.replace("www.", "").split(".")[0]} Terms and
              Conditions and take required civil actions to recover damages;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Initiate steps of prosecution for damages and violation that is
              equivalent to offences in law;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Cause restriction to access our games/tournaments/contests to
              Users who are suspected in cases of cheating or colluding;
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              Bar you from playing or registering at {window.location.hostname.replace("www.", "").split(".")[0]} in the
              future, as we reserve full discretion to restrict you from playing
              our online betting games/tournaments/contests.
            </ListItem>
          </List>

          <Typography sx={content}>
            The action taken by us will be solely due to your breach of our
            Terms and Conditions; the action shall be final and decisive that
            will be binding on you. Any action taken by us will be without
            prejudice to our other rights and remedies that are mentioned and
            available in law or equity.
          </Typography>

          <Typography sx={heading}>XXVIII. TERMINATION</Typography>
          <Typography sx={content}>
            We reserve the right, at our discretion, to immediately, with or
            without notice, suspend or terminate your registration, the Terms
            and Conditions and/or your access to all or any portion of the
            {window.location.hostname.replace("www.", "").split(".")[0]} site and/or remove any registration information or
            User Content from the {window.location.hostname.replace("www.", "").split(".")[0]} site. Upon termination or
            expiration of the Terms and Conditions, your obligations and
            {window.location.hostname.replace("www.", "").split(".")[0]}'s rights and disclaimers survive, but your right to
            use the {window.location.hostname.replace("www.", "").split(".")[0]} site immediately ceases.
          </Typography>
          <Typography sx={heading}>XXIX. DISCLAIMERS</Typography>
          <Typography sx={content}>
            To the extent permitted under law, neither {window.location.hostname.replace("www.", "").split(".")[0]} nor its
            parent/holding company, subsidiaries, affiliates, directors,
            officers, professional advisors, employees shall be responsible for
            the deletion, the failure to store, the mis-delivery, or the
            untimely delivery of any information or materials.
          </Typography>
          <Typography sx={content}>
            To the extent permitted under law, {window.location.hostname.replace("www.", "").split(".")[0]} shall not be
            responsible for any harm resulting from downloading or accessing any
            information or materials, the quality of servers, games, products,
            {window.location.hostname.replace("www.", "").split(".")[0]} services or sites, cancellation of competition and
            prizes. {window.location.hostname.replace("www.", "").split(".")[0]} disclaims any responsibility for, and if a
            User pays for access to one of {window.location.hostname.replace("www.", "").split(".")[0]}'s Services the User
            will not be entitled to a refund as a result of, any inaccessibility
            that is caused by {window.location.hostname.replace("www.", "").split(".")[0]}'s maintenance on the servers or
            the technology that underlies our sites, failures of
            {window.location.hostname.replace("www.", "").split(".")[0]}'s service providers (including telecommunications,
            hosting, and power providers), computer viruses, natural disasters
            or other destruction or damage of our facilities, acts of nature,
            war, civil disturbance, or any other cause beyond our reasonable
            control. In addition, {window.location.hostname.replace("www.", "").split(".")[0]} does not provide any warranty
            as to the content on the {window.location.hostname.replace("www.", "").split(".")[0]}(s). {window.location.hostname.replace("www.", "").split(".")[0]}(s)
            content is distributed on an "as is, as available" basis.
          </Typography>
          <Typography sx={content}>
            Any materials accessed, downloaded or otherwise obtained through
            {window.location.hostname.replace("www.", "").split(".")[0]} is done at the User's discretion, competence,
            acceptance and risk, and the User will be solely responsible for any
            potential damage to User's computer system or loss of data that
            results from a user’s download of any such materials.
          </Typography>
          <Typography sx={content}>
            {window.location.hostname.replace("www.", "").split(".")[0]} shall make best endeavors to ensure that the
            {window.location.hostname.replace("www.", "").split(".")[0]}(s) is error-free and secure, however, neither
            {window.location.hostname.replace("www.", "").split(".")[0]} nor any of its partners, licensors or associates
            makes any warranty that:
          </Typography>

          <List sx={{ listStyle: "disc", pl: 2 }}>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              the {window.location.hostname.replace("www.", "").split(".")[0]} service(s) will meet Users' requirements,
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              {window.location.hostname.replace("www.", "").split(".")[0]} service(s) will be uninterrupted, timely, secure,
              or error free
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              the results that may be obtained from the use of
              {window.location.hostname.replace("www.", "").split(".")[0]}(s) will be accurate or reliable; and
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0, color: "#fff" }}>
              the quality of any products, {window.location.hostname.replace("www.", "").split(".")[0]} Services,
              information, or other materials that Users purchase or obtain
              through {window.location.hostname.replace("www.", "").split(".")[0]} site(s) will meet Users' expectations.
            </ListItem>
          </List>

          <Typography sx={content}>
            In case {window.location.hostname.replace("www.", "").split(".")[0]} discovers any error, including any error in
            the determination of Winners or in the transfer of amounts to a
            User's account, {window.location.hostname.replace("www.", "").split(".")[0]} reserves the right (exercisable at
            its discretion) to rectify the error in such manner as it deems fit,
            including through a set-off of the erroneous payment from amounts
            due to the User or deduction from the User's account of the amount
            of erroneous payment. In case of exercise of remedies in accordance
            with this clause, {window.location.hostname.replace("www.", "").split(".")[0]} agrees to notify the User of the
            error and of the exercise of the remedy(ies) to rectify the same.
          </Typography>
          <Typography sx={content}>
            To the extent permitted under applicable law, in the event of
            suspension or closure of any Services, events or Contests, Users
            (including Participants) shall not be entitled to make any demands,
            claims, on any nature whatsoever.
          </Typography>
          <Typography sx={heading}>XXX. FORCE MAJEURE</Typography>
          <Typography sx={content}>
            Neither party to these Terms and Conditions shall be liable for any
            loss and/or any failure to perform any obligation under these Terms
            and Conditions due to causes beyond its reasonable anticipation or
            control including real or potential labor disputes, governmental
            actions, war or threat of war, sabotage, civil unrest,
            demonstrations, fire, storm, flooding, explosion, earthquake,
            provisions or limitations of materials or resources, inability to
            obtain the relevant authorization, accident and defect in
            electricity or telecommunication network, among other events.
          </Typography>
          <Typography sx={content}>
            Force Majeure and/or another event beyond {window.location.hostname.replace("www.", "").split(".")[0]}’s control
            hindering, delaying or complicating the maintenance of the
            {window.location.hostname.replace("www.", "").split(".")[0]} Game/Tournament/Contest, shall entitle
            {window.location.hostname.replace("www.", "").split(".")[0]} to suspend or limit the {window.location.hostname.replace("www.", "").split(".")[0]}
            Game/Tournament/Contest until further notice.
          </Typography>
          <Typography sx={heading}>XXXI. ENTIRE AGREEMENT</Typography>
          <Typography sx={content}>
            The Terms and Conditions and Privacy Policy, as amended from time to
            time, constitute the entire agreement between you and
            {window.location.hostname.replace("www.", "").split(".")[0]}. If any provision of these Terms and Conditions is
            considered unlawful, void, or for any reason unenforceable by a
            court of competent jurisdiction, then that provision shall be deemed
            severable from these Terms and Conditions and shall not affect the
            validity and enforceability of any remaining provisions, which shall
            remain in full force and effect.
          </Typography>
          <Typography sx={content}>
            Force Majeure and/or another event beyond {window.location.hostname.replace("www.", "").split(".")[0]}’s control
            hindering, delaying or complicating the maintenance of the
            {window.location.hostname.replace("www.", "").split(".")[0]} Game/Tournament/Contest, shall entitle
            {window.location.hostname.replace("www.", "").split(".")[0]} to suspend or limit the {window.location.hostname.replace("www.", "").split(".")[0]}
            Game/Tournament/Contest until further notice.
          </Typography>
          <Typography sx={heading}>
            XXXII. ABILITY TO ACCEPT TERMS AND CONDITIONS
          </Typography>
          <Typography sx={content}>
            The Terms and Conditions and Privacy Policy, as amended from time to
            time, constitute the entire agreement between you and
            {window.location.hostname.replace("www.", "").split(".")[0]}. If any provision of these Terms and Conditions is
            considered unlawful, void, or for any reason unenforceable by a
            court of competent jurisdiction, then that provision shall be deemed
            severable from these Terms and Conditions and shall not affect the
            validity and enforceability of any remaining provisions, which shall
            remain in full force and effect.
          </Typography>
          <Typography sx={content}>
            You further represent and warrant that you are not located in any of
            the Excluded Regions where any of the games offered on
            {window.location.hostname.replace("www.", "").split(".")[0]} is prohibited to be offered and/or accessed local
            laws.
          </Typography>
          <Typography sx={heading}>XXXIII. ASSIGNMENT</Typography>
          <Typography sx={content}>
            These Terms and Conditions, and any rights and licenses granted
            hereunder, may not be transferred or assigned by you, but may be
            assigned by {window.location.hostname.replace("www.", "").split(".")[0]} without restriction.
          </Typography>
          <Typography sx={heading}>XXXIV. WAIVER</Typography>
          <Typography sx={content}>
            No waiver of any terms of these Terms and Conditions shall be deemed
            a further or continuing waiver of such term or any other term and
            {window.location.hostname.replace("www.", "").split(".")[0]}’s failure to assert any right or provision under
            these Terms and Conditions shall not constitute a waiver of such
            right or provision.
          </Typography>
          <Typography sx={heading}>XXXV. RELATIONSHIP</Typography>
          <Typography sx={content}>
            None of the provisions of the Terms and Conditions shall be deemed
            to constitute a partnership or agency between you and
            {window.location.hostname.replace("www.", "").split(".")[0]} and you shall have no authority to bind
            {window.location.hostname.replace("www.", "").split(".")[0]} in any manner, whatsoever. If you are registering
            on the {window.location.hostname.replace("www.", "").split(".")[0]} site as a business entity, you represent
            that you have the authority to bind the entity to this Agreement.
          </Typography>
        </Container>
      </Box>
    </Box>
  )
}

export default TermCondition
