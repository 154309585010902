import {
  Box,
  TextField,
  Button,
  InputLabel,
  InputAdornment,
  Typography,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material"
import { useForm } from "react-hook-form"
import LockIcon from "@mui/icons-material/Lock"
import Person from "@mui/icons-material/person"

import "./registerpage.css"
import { FC, useEffect, useState } from "react"
import { Login } from "@mui/icons-material"
import { FaEye } from "react-icons/fa"
import { FaEyeSlash } from "react-icons/fa"
import PhoneIcon from "@mui/icons-material/Phone"
import {
  useDemologinMutation,
  useGetdataQuery,
  useIsSelfByAppUrlMutation,
  useLoginMutation,
  useSelfregisterMutation,
} from "../../../api/mainApi/mainApiSlice"
import { useNavigate } from "react-router"
import snackBarUtil from "../../../utils/snackbar"
import { SnackbarContent } from "notistack"
import { useMyIpQuery } from "../../../api/betFairApi/betFairApiSlice"
import { useAppDispatch } from "../../../hooks/useAppDispatch"
import { demoLogin, login } from "../../../features/auth/authslice"
import { Link } from "react-router-dom"

interface LoginPageProps {
  isSelfData: isSelfRes | undefined
}

const RegisterPage: FC<LoginPageProps> = ({ isSelfData }) => {
  const navigate = useNavigate()
  const [showPass, setShowPass] = useState(false)
  const [showConfirmPass, setShowConfirmPass] = useState(false)
  const [checked, setKeepLoggedIn] = useState<boolean>(true)
  const [selfRegisterTrigger, { data: selfregisterData }] =
    useSelfregisterMutation()
  const { data } = useMyIpQuery()
  const { data: getData, isLoading } = useGetdataQuery({
    // appUrl: "fairplays24.com",
    appUrl: window.location.hostname.replace("www.", ""),
  })
  const dispatch = useAppDispatch()
  const [demoLoginTrigger, { data: demoLoginData }] = useDemologinMutation()
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      casinoComm: isSelfData?.data?.casinoComm,
      fancyComm: isSelfData?.data?.fancyComm,
      oddsComm: isSelfData?.data?.oddsComm,
      userId: "",
      checked,
      username: "",
      password: "",
      confirmPassword: "",
      mobile: "",
      appUrl: window.location.hostname.replace("www.", ""),
      // appUrl: "fairplays24.com",
    },
  })

  const handleShowConfirmPass = () => {
    setShowConfirmPass(!showConfirmPass)
  }

  const handleShowPass = () => {
    setShowPass(!showPass)
  }

  const handleDemoLogin = () => {
    demoLoginTrigger({
      appUrl: window.location.hostname.replace("www.", ""),
      // appUrl: "fairplays24.com",
    })
  }

  useEffect(() => {
    if (demoLoginData) {
      if (demoLoginData?.status === false) {
        snackBarUtil.error(demoLoginData?.message)
        return
      } else if (demoLoginData?.token) {
        dispatch(demoLogin(demoLoginData))
        navigate("/")
      }
    }
  }, [demoLoginData])

  // useEffect(() => {
  //   if (loginData) {
  //     if (loginData?.status === false) {
  //       snackBarUtil.error(loginData?.message)
  //       return
  //     } else if (loginData?.token) {
  //       dispatch(login(loginData))
  //       navigate("/")
  //     }
  //   }
  // }, [loginData])

  const onSubmit = async (data: any) => {
    const {
      userId,
      password,
      appUrl,
      username,
      mobile,
      casinoComm,
      oddsComm,
      fancyComm,
    } = data
    const payload = {
      username,
      password,
      confirmPassword,
      mobile,
      casinoComm,
      fancyComm,
      oddsComm,
      userId: username,
      appUrl,
      checked,
    }

    try {
      const response = await selfRegisterTrigger(payload).unwrap()

      if (response?.token) {
        localStorage.setItem("token", response.token)
        localStorage.setItem("loginData", JSON.stringify(response))
        snackBarUtil.success(
          <SnackbarContent role="alert" style={{ width: "800px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{ color: "#fff", fontSize: "16px", textAlign: "left" }}
              >
                Success!
              </Typography>
              <Typography
                sx={{ color: "#fff", fontSize: "12px", textAlign: "left" }}
              >
                User Created Successfully
              </Typography>
            </Box>
          </SnackbarContent>,
        )
        navigate("/")
      } else {
        snackBarUtil.error(
          <SnackbarContent role="alert" style={{ width: "800px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{ color: "#fff", fontSize: "16px", textAlign: "left" }}
              >
                {/* failed! */}
                {response?.status}
              </Typography>
              <Typography
                sx={{ color: "#fff", fontSize: "12px", textAlign: "left" }}
              >
                {/* Login failed */}
                {response?.message}
              </Typography>
            </Box>
          </SnackbarContent>,
        )
      }
    } catch (error) {
      console.log(`login error ${error}`)
    }

    reset()
  }

  const password = watch("password")
  const confirmPassword = watch("confirmPassword")

  return (
    <Box
      className="bg-img-holder"
      sx={{
        backgroundImage: `url("https://s3buket.blr1.cdn.digitaloceanspaces.com/images/login-background.webp")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "50%",
        padding: "50px 0",
      }}
    >
      <Box
        className="form-holderr"
        sx={{
          maxWidth: 580,
          margin: "auto",
          backgroundColor: "#000000B3",
          padding: 3,
          borderRadius: "5px",
          border: "2px solid #f36c21",
        }}
      >
        <div className="form-box">
          <div className="formlogoholder">
            <img
              src={isSelfData?.data?.logo}
              alt=""
              className="w-[150px] h-[40px] m-auto"
            />
          </div>

          <Box className="register-as-new-user">
            <Typography
              sx={{ fontSize: "14px", color: "#AAAFB5", textAlign: "center" }}
            >
              Register as New User
            </Typography>

            <Typography
              component="h4"
              sx={{
                my: 1,
                fontSize: "18px",
                color: "#AAAFB5",
                textAlign: "center",
              }}
            >
              Get your instant ID from whatsapp
            </Typography>

            {/* <a
              href={getData?.data?.s_whatsapp?.link}
              target="_blank"
              rel="noopener noreferrer"
              className="link-click-here"
            >
              <div className="wa-logo">
                <img
                  src="https://s3buket.blr1.cdn.digitaloceanspaces.com/images/wa-img.png"
                  alt="walogo"
                />
              </div>
              <div className="click-here-btn">click here</div>
            </a> */}

            {
              isSelfData?.data?.selfAllowed && <p className="what_icon">
                <a
                  target="_blank"
                  href={getData?.data?.s_whatsapp?.link}
                  rel="noopener noreferrer"
                >
                  <div>
                    <img
                      src="https://s3buket.blr1.cdn.digitaloceanspaces.com/images/wa-img.png"
                      width={35}
                      height={35}
                      alt="walogo"
                    />
                  </div>
                  <div>click here</div>
                </a>
              </p>
            }


            <Box className="or_text">
              <div></div>
              OR
              <div></div>
            </Box>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box
              className="userid-phonenumber"
              sx={{
                display: "flex",
                flexWrap: { md: "nowrap", xs: "wrap" },
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="relative userid-wrapper w-full">
                <label
                  htmlFor="user-Id"
                  className="mr-auto w-full block text-left text-[13px] text-white"
                >
                  User ID
                </label>
                <Box
                  className="input-wrapper"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <InputAdornment
                    position="start"
                    sx={{
                      // position: "absolute",
                      left: 10,
                      bottom: 35,
                      // transform: "translateY(-50%)",
                    }}
                  >
                    <Person sx={{ color: "white" }} />
                  </InputAdornment>

                  <input
                    type="text"
                    id="user-id"
                    {...register("username", {
                      required: "userId is required",
                    })}
                    autoComplete="off"
                    placeholder="Enter User id*"
                  />
                  {errors.username && (
                    <Typography
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        position: "absolute",
                        bottom: "-20px",
                      }}
                    >
                      {errors.username.message}
                    </Typography>
                  )}
                </Box>
              </div>

              <div className="relative phone-number-wrapper w-full mt-4">
                <label
                  htmlFor="mobile-number"
                  className="mr-auto w-full block text-left text-[13px] text-white"
                >
                  Phone Number
                </label>
                <Box
                  className="input-wrapper"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    my: { md: 0, xs: 2 },
                  }}
                >
                  <InputAdornment
                    position="start"
                    sx={{
                      // position: "absolute",
                      left: 10,
                      bottom: 35,
                      // transform: "translateY(-50%)",
                    }}
                  >
                    <PhoneIcon sx={{ color: "white" }} />
                  </InputAdornment>
                  <Select
                    sx={{ mx: 1 }}
                    disabled
                    value={+91}
                    variant="outlined"
                    size="small"
                  >
                    <MenuItem value={+91}>+91</MenuItem>
                  </Select>
                  <input
                    type="text"
                    id="mobile-number"
                    {...register("mobile", {
                      required: "mobile no is required",
                    })}
                    autoComplete="off"
                    placeholder="Enter Mobile No*"
                  />
                  {errors.mobile && (
                    <Typography
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        position: "absolute",
                        bottom: "-20px",
                      }}
                    >
                      {errors.mobile.message}
                    </Typography>
                  )}
                </Box>
              </div>
            </Box>

            <Box
              className="password-confirmpassword"
              sx={{
                display: "flex",
                flexWrap: { md: "nowrap", xs: "wrap" },
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="passparent relative w-full">
                <label
                  htmlFor="password"
                  className="mr-auto w-full block text-left text-[13px] lg:mt-8 text-white"
                >
                  Password <sup className="text-white text-lg top-0">*</sup>
                </label>
                <Box
                  className="input-wrapper"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <InputAdornment
                    position="start"
                    sx={{
                      // position: "absolute",
                      left: 10,
                      bottom: 35,
                      // transform: "translateY(-50%)",
                    }}
                  >
                    <LockIcon sx={{ color: "white" }} />
                  </InputAdornment>
                  <input
                    className="bg-white"
                    type={showPass ? "text" : "password"}
                    id="password"
                    {...register("password", {
                      required: "Password is required",
                    })}
                    autoComplete="new-password"
                    autoSave="off"
                    placeholder="Password"
                  />
                  <InputAdornment
                    onClick={handleShowPass}
                    position="end"
                    sx={{
                      cursor: "pointer",
                      position: "absolute",
                      fontSize: "20px",
                      right: 10,
                      bottom: 35,
                    }}
                  >
                    {!showPass ? (
                      <FaEye color="#fff" />
                    ) : (
                      <FaEyeSlash color="#fff" />
                    )}
                  </InputAdornment>
                  {errors.password && (
                    <Typography
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        position: "absolute",
                        bottom: "-20px",
                      }}
                    >
                      {errors.password.message}
                    </Typography>
                  )}
                </Box>
              </div>

              <div className="relative confirm-password-wrapper w-full">
                <label
                  htmlFor="confirm-password"
                  className="mr-auto w-full block text-left text-[13px] text-white lg:mt-8 mt-4"
                >
                  Confirm Password
                </label>
                <Box
                  className="input-wrapper"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: "white" }} />
                  </InputAdornment>
                  <input
                    type={showConfirmPass ? "text" : "password"}
                    id="confirm-password"
                    {...register("confirmPassword", {
                      required: "Please Enter your confirm password",
                      validate: value =>
                        value === password || "Passwords do not match",
                    })}
                    autoComplete="off"
                    placeholder="Confirm Password*"
                  />
                  <InputAdornment
                    onClick={handleShowConfirmPass}
                    position="end"
                    sx={{
                      cursor: "pointer",
                      position: "absolute",
                      fontSize: "20px",
                      right: 10,
                      bottom: 35,
                    }}
                  >
                    {!showConfirmPass ? (
                      <FaEye color="#fff" />
                    ) : (
                      <FaEyeSlash color="#fff" />
                    )}
                  </InputAdornment>
                  {errors.confirmPassword && (
                    <Typography
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        position: "absolute",
                        bottom: "-20px",
                      }}
                    >
                      {errors.confirmPassword.message}
                    </Typography>
                  )}
                </Box>
              </div>
            </Box>

            {(isSelfData?.data?.casinoComm !== 0 &&
              isSelfData?.data?.oddsComm !== 0) && (
                <Box
                  className="commisson-holder"
                  sx={{
                    display: "flex",
                    flexWrap: { md: "nowrap", xs: "wrap" },
                    alignItems: "center",
                    gap: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  <Box
                    className="oddscomm-input-holder"
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label
                      htmlFor="odds-comm"
                      className="mr-auto capitalize w-full block text-left text-[13px] text-white lg:mt-8 mt-4"
                    >
                      odds comm.
                    </label>
                    <input
                      readOnly
                      className="odd-comm w-full"
                      type="text"
                      id="odds-comm"
                      value={isSelfData?.data?.oddsComm}
                      autoComplete="off"
                      placeholder="Confirm Password*"
                      disabled
                    />
                  </Box>

                  <Box
                    className="fancycomm-input-holder"
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label
                      htmlFor="fancy-comm"
                      className="mr-auto capitalize w-full block text-left text-[13px] text-white lg:mt-8 mt-4"
                    >
                      fancy comm.
                    </label>

                    <input
                      readOnly
                      type="text"
                      className="fancy-comm"
                      id="fancy-comm"
                      value={isSelfData?.data?.fancyComm}
                      autoComplete="off"
                      placeholder="Confirm Password*"
                      disabled
                    />
                  </Box>
                </Box>
              )}

            <div className="flex mt-5 items-center">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    onChange={event => setKeepLoggedIn(event.target.checked)}
                    checked={checked}
                    sx={{
                      color: "#f36c21",
                      "&.Mui-checked": {
                        color: "#f36c21",
                      },
                    }}
                  />
                }
                label="I am at least 18 years of age and I have read, accept and agree to the Terms and Conditions , Responsible Gaming, GamCare, Gambling Therapy"
                sx={{
                  color: "#fff",
                  fontSize: "14px",
                  "& .MuiFormControlLabel-label": {
                    fontSize: "12px",
                    fontWeight: 600,
                    textAlign: "left",
                  },
                }}
              />
            </div>
            <button type="submit" className="w-full loginbtn my-2">
              Submit
              {/* <Login fontSize="small" /> */}
            </button>
            <button
              type="button"
              className="w-full demologinbtn"
              onClick={handleDemoLogin}
            >
              DEMO LOGIN
            </button>
          </form>
        </div>
      </Box>
    </Box>
  )
}

export default RegisterPage
