import * as React from "react"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"

interface MatchedDeletedRadioProps {
  allbacklay: string
  handlebacklay: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const AllBackLayRadioCasino: React.FC<MatchedDeletedRadioProps> = ({
  allbacklay,
  handlebacklay,
}) => {
  const betTypeSports = [
    {
      val: 1,
      name: "All",
    },
    {
      val: 2,
      name: "Back",
    },
    {
      val: 3,
      name: "Lay",
    },
  ]

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={allbacklay}
        onChange={handlebacklay}
      >
        {betTypeSports?.map((item, idx) => (
          <FormControlLabel
            sx={{ textTransform: "capitalize", color: "#fff" }}
            key={idx}
            value={item?.val}
            control={<Radio size="small" />}
            label={item?.name}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default AllBackLayRadioCasino
