import { Box, Grid, Typography, Container } from "@mui/material"
import HeadingBar from "../../../../common/HeadingBar"
import teenpattiimg from "/images/teen-patti_one-day.webp"
import { useNavigate } from "react-router"
import { livecarddata } from "./livecarddata"
const LiveCardPage = () => {
  const nav = useNavigate()

  return (
    <div>
      <HeadingBar title="live card" />

      <Box
        className="textholder"
        sx={{ paddingLeft: "20px", paddingTop: "10px" }}
      >
        <Typography
          sx={{
            fontSize: "1.8em",
            mb: "15px",
            color: "#fff",
            textAlign: "left",
            fontWeight: "600",
          }}
          component="h1"
        >
          Rummy makes people richer. Play online and win real cash.
        </Typography>

        <Typography
          sx={{
            color: "#fff",
            fontSize: "0.85rem",
            fontWeigh: "400",
            lineHeight: "1.375rem",
            letterSpacing: ".0071428571em",
            margin: "0 0 10px",
            paddingRight: "20px",
            textAlign: "left",
          }}
        >
          {window.location.hostname.replace("www.", "").split(".")[0]} invites you to discover thrilling online poker
          tournaments and games. Here, you can play games like 3 patti online,
          Indian card games, poker, etc. online using secured deposits. Play and
          benefit from instant withdrawals and advanced software. Things like
          ‘play rummy win cash’ is an everyday situation for this platform where
          you can awaken the champion inside you. Also, those who enjoy online 3
          patti real money can rejoice as we also offer a beginners guide on
          poker rules, 3 patti rules, etc. and hand rankings to make it simple
          to play poker online. Our endeavour in offering players a platform to
          win prizes is what drives our efforts. Learn all moves low to high
          using our comprehensive guide. To play and win, sign up to
          {window.location.hostname.replace("www.", "").split(".")[0]} now!
        </Typography>
      </Box>

      <Box
        className="cardholder"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          my: 4,
        }}
      >
        <Container maxWidth="lg">
          <Grid container gap={1} sx={{ m: "auto" }}>
            {livecarddata.map((imgdata, index) => (
              <Grid
                onClick={() => nav(imgdata?.link)}
                key={imgdata?.img + index}
                maxHeight="250px"
                item
                xs={4}
                md={1.8}
                sx={{
                  maxWidth: { md: "200px", sm: "130px", xs: "90px" },
                  m: { md: "0 0 5px 0", xs: "auto" },
                  width: "100%",
                  borderRadius: "10px",
                  overflow: "hidden",
                  border: "1px solid #f36c21",
                }}
              >
                <img src={imgdata?.img} alt="" />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default LiveCardPage
