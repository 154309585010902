import { Box, Typography } from "@mui/material"
// import notfound404img from "/404-not-found.webp"
import ButtonComp from "../../../common/ButtonComp"
import { FaArrowLeftLong } from "react-icons/fa6"
import { useNavigate } from "react-router"

const Oops404Page = () => {
  const nav = useNavigate()

  return (
    <Box className="imagewrapper">
      <Box className="pagenotfound">
        <img
          src="https://s3buket.blr1.cdn.digitaloceanspaces.com/images/404-not-found.webp"
          alt="404image"
          className="p-[45px] max-w-[100%] h-auto m-auto"
        />
      </Box>

      <Typography
        component="h2"
        sx={{
          fontSize: "6rem",
          fontWeight: "400",
          textAlign: "center",
          color: "#f36c21",
        }}
      >
        Ooops!
      </Typography>

      <Box sx={{ m: "10px 0", textAlign: "center" }}>
        <Typography component="p" sx={{ fontSize: "1rem", color: "#fff" }}>
          What are the odds of that?
        </Typography>
        <Typography component="p" sx={{ fontSize: "1rem", color: "#fff" }}>
          We can't seem to find the page you're looking for.
        </Typography>
      </Box>

      <Box sx={{ m: "50px 0 30px" }}>
        <ButtonComp
          onClick={() => nav(-1)}
          className="cursor-default flex items-center m-[auto] rounded-[28px] bg-[#f36c21] min-w-[78px] px-[36px] py-[11px] uppercase "
        >
          <FaArrowLeftLong color="#fff" fontSize="16px" />
          <Typography
            component="p"
            sx={{ ml: 1, fontSize: "14px", color: "#fff" }}
          >
            back to home
          </Typography>
        </ButtonComp>
      </Box>
    </Box>
  )
}

export default Oops404Page
