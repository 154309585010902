import * as React from "react"
import { Box,  Grid,  Tab, Tabs } from "@mui/material"
import MatchedBetsAccordionSports from "./MatchedBetsAccordionSports"
import MatchedBetsAccordionCasino from "./MatchedBetsAccordionCasino"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  
}

interface Props{
  exp: boolean
}

const SearchBar:React.FC<Props> = ({exp}) => {
  const [tabValue, setTabValue] = React.useState(0)
  const tabsName = ["sports", "casino"]
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    )
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  return (
    <div className="openbetinputs py-4 lg:py-0">
      <Grid
        container
        rowSpacing={{ xs: 1 }}
        columnSpacing={1}
        sx={{
          padding: { md: "25px 25px 0 25px", xs: "0 0 0px 0" },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          container
          className="datashown"
          sx={{
            backgroundColor: "#1e1e1e",
            margin: { md: "0 0 0px 0", xs: "10px 0" },
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box className="openbetstabsholder">
              <Tabs
                centered
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#f36c21",
                    bottom: "9px",
                  },
                }}
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                {tabsName?.map((name, idx) => (
                  <Tab
                    key={idx}
                    label={name}
                    sx={{
                      color: "#fff",
                      fontSize: "12px",
                      padding: "0",
                      minHeight: "40px",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#51311f",
                        opacity: 0.8,
                      },
                    }}
                    {...a11yProps(idx)}
                  />
                ))}
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <MatchedBetsAccordionSports exp={exp}/>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <MatchedBetsAccordionCasino exp={exp}/>
            </CustomTabPanel>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default SearchBar
