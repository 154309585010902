import { Grid } from "@mui/material"
import LeftMenu from "../../common/LeftMenu"
import HomeRightSection from "./HomeRightSection"
import React from "react"
import { useMediaQuery } from "@mui/material"

const HomeSportsPage = () => {
  const isMobile = useMediaQuery("(max-width:991px)")
  const mdvalue = isMobile ? 12 : 10
  return (
    <div className="py-2">
      <Grid container spacing={2}>
        <Grid
          item
          sx={{ display: { xs: "none", sm: "none", md: "block" } }}
          md={1.82}
        >
          {!isMobile && <LeftMenu />}
        </Grid>

        <Grid
          item
          xs={12}
          md={mdvalue}
          sx={{
            width: {
              md: `${!isMobile && "100%"}`,
              maxWidth: `${!isMobile && "100%"}`,
            },
          }}
        >
          <HomeRightSection />
        </Grid>
      </Grid>
    </div>
  )
}

export default HomeSportsPage
