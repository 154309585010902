import { Typography } from "@mui/material"

export const posNegPnl = (value: number | null | undefined) => {
  if (null) {
    return
  } else {
    if (value && value > 0) {
      return (
        <Typography
          component="span"
          sx={{ color: "#1e8067", fontSize: { md: "12px", xs: "10px" } }}
        >
          {value}
        </Typography>
      )
    } else {
      return (
        <Typography
          component="span"
          sx={{ color: "#f00", fontSize: { md: "12px", xs: "10px" } }}
        >
          {value}
        </Typography>
      )
    }
  }
}
