import {
  Box,
  Grid,
  List,
  ListItem,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useState } from "react"
// import leagueimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tab2ico1.webp"
// import resultimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tab2ico2.webp"
import SportsMatchesData from "./SportsMatchesData"
import { sportsstaticdata } from "../../../common/sportsmenustaticdata"
// import SportsMenu from "./SportsMenu"
import nodataimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/nodata/nodataimg.webp"
import NoData from "../../../common/NoData"
import InPlayBetLiveParent from "./InPlayBetLiveParent"
import SportsMenu from "../../../common/SportsMenu"
import { useParams } from "react-router"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function tabProps(index: number) {
  return {
    id: `second-tabs-${index}`,
    "aria-controls": `second-tab-panel-${index}`,
  }
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const SportPageSecondTab = () => {
  const isMobile = useMediaQuery("(max-width:600px)")
  const mdValue = isMobile ? 12 : 10
  const [value, setValue] = useState(0)
  const { id } = useParams<{ id: any }>()

  const handleChange = (e: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          margin: "10px 0 0",
          backgroundImage: "linear-gradient(0deg,hsla(0,0%,4%,.5) 50%,#323232)",
          borderTop: "1px solid #4c4c4c",
          backgroundColor: "#1e1e1e",
        }}
      >
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            sx: {
              borderBottom: "2px solid #4caf50",
              backgroundColor: "#2e4a2f",
              minWidth: "unset",
              height: "100lvh",
              zIndex: -1,
            },
          }}
          sx={{
            zIndex: 2,
            isolation: "isolate",
            minHeight: "36px",
            "& .MuiTab-root": {
              minWidth: "unset",
              minHeight: "36px",
            },
          }}
        >
          <Tab
            sx={{ p: 0 }}
            disableTouchRipple
            label={
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "8px 16px",
                }}
              >
                <Typography
                  component="p"
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "600",
                    fontSize: { md: "12px", xs: "10px" },
                    color: value === 0 ? "#f36c21" : "hsla(0,0%,100%,.6)",
                  }}
                >
                  live & upcoming
                </Typography>
              </Box>
            }
            {...tabProps(0)}
          />
          <Tab
            sx={{ p: 0 }}
            disableTouchRipple
            label={
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 16px",
                  gap: 0.5,
                }}
              >
                <img
                  src={
                    "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tab2ico1.webp"
                  }
                  alt=""
                  className="w-[17px] max-w-full"
                />
                <Typography
                  component="p"
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "600",
                    fontSize: { md: "12px", xs: "10px" },
                    color: value === 1 ? "#f36c21" : "hsla(0,0%,100%,.6)",
                  }}
                >
                  leagues
                </Typography>
              </Box>
            }
            {...tabProps(1)}
          />
          <Tab
            sx={{ p: 0 }}
            disableTouchRipple
            label={
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 16px",
                  gap: 0.5,
                }}
              >
                <img
                  src={
                    "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tab2ico2.webp"
                  }
                  alt=""
                  className="w-[20px] max-w-full"
                />
                <Typography
                  component="p"
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "600",
                    fontSize: { md: "12px", xs: "10px" },
                    color: value === 2 ? "#f36c21" : "hsla(0,0%,100%,.6)",
                  }}
                >
                  results
                </Typography>
              </Box>
            }
            {...tabProps(2)}
          />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <Box sx={{ my: 1 }} className="sportspagesecondtabparent liveupcoming">
          <Grid container spacing={0.8}>
            <Grid
              item
              xs={2}
              sx={{ display: { md: "block", xs: "none" }, sm: "none" }}
            >
              <SportsMenu id={id} />
            </Grid>
            <Grid
              item
              xs={12}
              md={mdValue}
              sx={{
                width: {
                  md: `${isMobile && "100%"}`,
                  maxWidth: `${!isMobile && "100%"}`,
                },
              }}
            >
              <InPlayBetLiveParent>
                <SportsMatchesData />
              </InPlayBetLiveParent>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <Box sx={{ my: 1 }} className="sportspagesecondtabparent leagues">
          <Grid container spacing={0.8}>
            <Grid
              item
              xs={2}
              sx={{ display: { md: "block", xs: "none" }, sm: "none" }}
            >
              {!isMobile && <SportsMenu id={id} />}
            </Grid>
            <Grid
              item
              xs={12}
              md={mdValue}
              sx={{
                width: {
                  md: `${isMobile && "100%"}`,
                  maxWidth: `${!isMobile && "100%"}`,
                },
              }}
            >
              <InPlayBetLiveParent>
                <Grid container>
                  <Grid item xs={12}>
                    <List sx={{ p: 0 }}>
                      {sportsstaticdata?.map((staticdata, idx) => (
                        <ListItem
                          key={idx}
                          sx={{
                            fontSize: "13px",
                            color: "#fff",
                            borderBottom: "1px solid #423f3f",
                            ":hover": {
                              backgroundColor: "#353535",
                            },
                          }}
                        >
                          {staticdata?.name}
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </InPlayBetLiveParent>
              {/* sports match card */}

              {/* sports match card */}
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <Box sx={{ my: 1 }} className="sportspagesecondtabparent results">
          <Grid container spacing={0.8}>
            <Grid
              item
              xs={2}
              sx={{ display: { md: "block", xs: "none" }, sm: "none" }}
            >
              {!isMobile && <SportsMenu id={id} />}
            </Grid>
            <Grid
              item
              xs={12}
              md={mdValue}
              sx={{
                width: {
                  md: `${isMobile && "100%"}`,
                  maxWidth: `${!isMobile && "100%"}`,
                },
              }}
            >
              <InPlayBetLiveParent>
                <NoData />
              </InPlayBetLiveParent>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>
    </Box>
  )
}

export default SportPageSecondTab
