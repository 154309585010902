import type { PayloadAction} from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

const initialAuthState: AuthState = {
    user: JSON.parse(localStorage.getItem('user') || 'null'),
    isLoggedIn: !!JSON.parse(localStorage.getItem('user') ?? "null"),
    token: localStorage.getItem('token'),
}


const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        login: (state, action: PayloadAction<LoginRes>) => {

            const user: Partial<User> = {
                username: action.payload.username,
                
            };

            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.removeItem("dialogClosed");
            state.user = user;
            state.isLoggedIn = true;
            state.token = action.payload.token;

            return state;
        },

        logout: (state) => {
            
            localStorage.clear()

            state.user = null;
            state.token = null;
            state.isLoggedIn = false;

            return state;
        },

        demoLogin: (state, action) => {

            const user: Partial<User> = {
                username: action.payload.username,
                
            };

            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.removeItem("dialogClosed");
            state.user = user;
            state.isLoggedIn = true;
            state.token = action.payload.token;

            return state;
        },


    }
});

export default authSlice.reducer;
export const { login, logout, demoLogin } = authSlice.actions;

const authSelector = (state: RootState) => state.auth;
export const selectUser = createSelector(authSelector, auth => auth.user);