import { Box, Typography } from "@mui/material"
import nodataimg from "/images/nodata/nodataimg.webp"

const NoData = () => {
  return (
    <Box className="nodatabox" sx={{ padding: "30px 0", width: "100%" }}>
      <img
        src={
          "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/nodata/nodataimg.webp"
        }
        alt=""
        className="my-[20px] mx-auto"
      />

      <Typography
        component="p"
        sx={{
          fontSize: { md: "18px", xs: "12px" },
          color: { md: "#1e8067", xs: "#fff" },
          paddingRight: "6px",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        No Data Found!
      </Typography>
      <Typography
        component="p"
        sx={{
          color: "#746969",
          fontSize: { md: "14px", xs: "12px" },
          fontWeight: "600",
          margin: "0 0",
          lineHeight: "20px",
          textAlign: "center",
        }}
      >
        We can't find any item matching your search.
      </Typography>
    </Box>
  )
}

export default NoData
