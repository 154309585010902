import { useState, useEffect } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Fade } from "@mui/material";

const WelcomeDialog = () => {
  const [show, setShow] = useState(false);
  const isDialogClosed = localStorage.getItem("dialogClosed");
  
  
  useEffect(() => {
    if (!isDialogClosed) {
      setShow(true); 
    }
  }, [isDialogClosed]);

  const handleClose = () => {
    setShow(false); 
    localStorage.setItem("dialogClosed", "true"); 
  };



  return (
    <Dialog
      open={show}
      TransitionComponent={Fade}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
      sx={{
        ".MuiDialog-paper": {
          position: "absolute",
          backgroundColor: "#fff",
          borderRadius: "8px",
          minWidth: "350px",
          p: 0,
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: "#f36c21", p: 0, m: 0 }}>
        <Button
          onClick={handleClose}
          sx={{
            fontSize: "24px",
            float: "right",
            color: "#fff",
          }}
        >
          x
        </Button>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <img src="/img/new.jpeg" alt="Diwali Celebration" style={{ width: "100%", height: "auto", minHeight: "350px" }} />
      </DialogContent>
    </Dialog>
  );
};

export default WelcomeDialog;
