import { FC } from "react"

interface ButtonCompProps {
  className: string
  children?: React.ReactNode
  onClick: () => any
}

const ButtonComp: FC<ButtonCompProps> = ({ className, children, onClick }) => {
  return (
    <button onClick={onClick} className={className}>
      {children}
    </button>
  )
}

export default ButtonComp
