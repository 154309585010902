import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Typography,
} from "@mui/material"
import { useState } from "react"

import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import SecondHeadingBar from "../../../../common/SecondHeadingBar"

const style = {
  backgroundColor: "#f36c21",
}

const dataToMap = [
  { title: "general 1", content: "general content 1" },
  { title: "general 2", content: "general content 2" },
  { title: "general 3", content: "general content 3" },
  { title: "general 4", content: "general content 4" },
  { title: "general 5", content: "general content 5" },
  { title: "general 6", content: "general content 6" },
]

const FaqPage = () => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <Box className="faqpageparent">
      <Box sx={style}>
        <SecondHeadingBar title="FAQS" />
      </Box>

      <Box className="accordion" sx={{ py: 2 }}>
        <Container maxWidth="xl">
          <Grid container columnSpacing={{ md: 2, xs: 0 }}>
            <Grid item xs={12} md={6}>
              {dataToMap.slice(0, 3).map((accdata, idx) => (
                <Accordion
                  sx={{
                    "&::before": {
                      display: "none",
                    },
                    backgroundColor: "#1e1e1e",
                    borderRadius: "5px",
                    border: "1px solid #2f2f2f !important",
                    my: 2,
                    outline: "none",
                    color: "#fff",
                    fontWeight: "500",
                  }}
                  key={accdata?.title + idx}
                  expanded={expanded === `panel${idx}`}
                  onChange={handleChange(`panel${idx}`)}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded !== `panel${idx}` ? <AddIcon /> : <RemoveIcon />
                    }
                    aria-controls={`panel${idx}bh-content`}
                    id={`panel${idx}bh-header`}
                  >
                    <Typography sx={{ color: "text.secondary" }}>
                      {accdata.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{accdata.content}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
            <Grid item xs={12} md={6}>
              {dataToMap.slice(3).map((accdata, idx) => (
                <Accordion
                  sx={{
                    backgroundColor: "#1e1e1e",
                    borderRadius: "5px",
                    border: "1px solid #2f2f2f !important",
                    my: 2,
                    color: "#fff",
                    fontWeight: "500",
                    "&::before": {
                      display: "none",
                    },
                  }}
                  key={accdata?.title + idx}
                  expanded={expanded === `panel${idx + 3}`}
                  onChange={handleChange(`panel${idx + 3}`)}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded !== `panel${idx + 3}` ? (
                        <AddIcon />
                      ) : (
                        <RemoveIcon />
                      )
                    }
                    aria-controls={`panel${idx + 3}bh-content`}
                    id={`panel${idx + 3}bh-header`}
                  >
                    <Typography sx={{ color: "text.secondary" }}>
                      {accdata.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{accdata.content}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default FaqPage
