import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout } from '../../features/auth/authslice';

const baseQuery = fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE_URL,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token');
        if (token) headers.set('Authorization', `Bearer ${token}`);
        return headers;
    }
});

const wrapperQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>
    = async (args, api, extraOptions) => {
        let result = await baseQuery(args, api, extraOptions)

        if (result?.error?.status === 401) api.dispatch(logout());
        if (result?.error?.status === 403) api.dispatch(logout());
        if ((result.data as ErrorRes)?.responseCode === 401) api.dispatch(logout());
        if ((result.data as ErrorRes)?.responseCode === 403) api.dispatch(logout());
        return result
    };

const mainApi = createApi({
    reducerPath: 'mainApi',
    baseQuery: wrapperQuery,
    tagTypes: ["getstake", "afterBetPlaced"],
    endpoints: (build) => {
        return {
            login: build.mutation<LoginRes, any>({
                query: (args) => {
                    return {
                        url: '/login/client-login',
                        method: 'POST',
                        body: args
                    }
                }
            }),

            demologin: build.mutation<demologinRes, demologinPayload>({
                query: (args) => {
                    return {
                        url: '/login/demo-user-creation-login',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            bannerImg: build.mutation<any, any>({
                query: (args) => {
                    return {
                        url: '/enduser/user-banner-list',
                        method: 'POST',
                        body: args
                    }
                }
            }),

            leftMenuList: build.mutation<LeftMenuListRes, LeftMenuListPayload>({
                query: (args) => {
                    return {
                        url: '/enduser/left-menu-data-open',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            isSelfByAppUrl: build.mutation<isSelfRes, isSelfPayload>({
                query: (args) => {
                    return {
                        url: '/login/is-self-by-app-url',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            getUserBalance: build.query<getUserBalanceRes, any>({
                query: (args) => {
                    return {
                        url: '/enduser/get-user-balance',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            accountStatement: build.mutation<accountStatementResponse, accountStatementPayload>({
                query: (args) => {
                    return {
                        url: '/enduser/account-statement',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            validateJWT: build.query<jwtRes, any>({
                query: (args) => {
                    return {
                        url: '/util/validate-jwt-token',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            logout: build.query<LogoutRes, any>({
                query: (args) => {
                    return {
                        url: '/login/logout',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            changePassword: build.mutation<changepassRes, changepassPayload>({
                query: (args) => {
                    return {
                        url: '/enduser/change-password',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            accountstatement: build.mutation<accountStatementResponse, accountStatementPayload>({
                query: (args) => {
                    return {
                        url: '/enduser/account-statement',
                        method: 'POST',
                        body: args
                    }
                }
            }),

            getstakebutton: build.query<getstakeRes, getstakePayload>({
                query: () => {
                    return {
                        url: '/enduser/get-stake-button',
                        method: 'POST',

                    }

                },
                providesTags: ['getstake']
            }),

            setstakebutton: build.mutation<setstakeRes, setstakePayload>({
                query: (args) => {
                    return {
                        url: '/enduser/set-stake-button',
                        method: 'POST',
                        body: args
                    }
                },
                invalidatesTags: ['getstake']
            }),
            betlistbymatchid: build.mutation<betlistbymatchidRes, betlistbymatchidPayload>({
                query: (args) => {
                    return {
                        url: '/enduser/bet-list-by-matchid',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            getBetPlaced: build.mutation<BetPlaceResponse, BetPlacePayload>({
                query: (args) => {
                    return {
                        url: '/enduser/place-bets',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            useroddspnl: build.query<Record<string, Record<string, number>>, oddspnlPayload>({
                providesTags: ['afterBetPlaced'],

                query: (args) => {
                    return {
                        url: '/enduser/user-odds-pnl',
                        method: 'POST',
                        body: args
                    }
                },
                transformResponse: (raw: oddspnlRes) => {
                    const obj: Record<string, Record<string, number>> = {}

                    if (!raw || !raw.data) {
                        return obj;
                    }

                    raw.data.forEach((item) => {
                        const oddsPnlObj = {
                            [item?.selection1]: item.pnl1,
                            [item?.selection2]: item.pnl2,
                            [item?.selection3]: item.pnl3,
                        }
                        obj[item.marketId] = oddsPnlObj
                    })

                    return obj
                }
            }),
            userfancypnl: build.query<Record<number, number>, fancypnlPayload>({
                providesTags: ['afterBetPlaced'],
                query: (args) => {
                    return {
                        url: '/enduser/user-fancy-pnl',
                        method: 'POST',
                        body: args
                    }
                },
                transformResponse: (raw: fancyPnlRes) => {
                    const obj: Record<number, number> = {}

                    if (Array.isArray(raw?.data)) {
                        raw?.data?.forEach((item: any) => {
                            if (item?.marketId !== undefined && item?.pnl !== undefined) {
                                obj[item?.marketId] = item.pnl
                            }
                        })
                    }

                    return obj
                }

            }),
            winnerPnl: build.query<winnerPnlResponse, winnerPnlPayload>({
                query: (args) => {
                    return {
                        url: '/enduser/user-winner-pnl',
                        method: 'POST',
                        body: args,
                    }
                },

            }),
            profitlossmatchwise: build.mutation<profitlossRes, profitlossPayload>({
                query: (args) => {
                    return {
                        url: '/report/profit-loss-match-wise',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            allcasinotype: build.query({
                query: () => {
                    return {
                        url: '/casino/all-casino-types',
                        method: 'POST'
                    }
                }
            }),
            casinotablebytypes: build.mutation({
                query: (args) => {
                    return {
                        url: '/casino/casino-tables-by-types',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            activematchsportwise: build.mutation({
                query: (args) => {
                    return {
                        url: '/enduser/active-match-sport-wise-open',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            activesportlist: build.query<activesportlistRes, void>({
                query: () => {
                    return {
                        url: '/enduser/active-sport-list',
                        method: 'POST',
                    }
                }
            }),
            unsettledbet: build.mutation<unsettledRes, unsettledPayload>({
                query: (args) => {
                    return {
                        url: '/enduser/unsettled-bet',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            betlisthistory: build.mutation<betlisthistoryResponse, betlisthistoryPayload>({
                query: (args) => {
                    return {
                        url: '/enduser/bet-list-history',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            getoneuserbetresult: build.query<getoneuserRes, getoneuserPayload>({
                query: (args) => {
                    return {
                        url: '/api/getOneUserBetResult',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            getdata: build.query<getDataRes, getDataPayload>({
                query: (args) => {
                    return {
                        url: '/api/admin/getData',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            selfregister: build.mutation<selfRegisterRes, selfRegisterPayload>({
                query: (args) => {
                    return {
                        url: '/user/self-register',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            getDepositList: build.mutation<DepositListRes, void>({
                query: (args) => {
                    return {
                        url: '/enduser/depsosit-request-client',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            getSelfDeposit: build.mutation<BetPlaceResponse, any>({
                query: (args) => {
                    return {
                        url: '/enduser/self-deposit-app',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            getPaymentMethod: build.mutation<PaymentMethodRes, void>({
                query: (args) => {
                    return {
                        url: '/deposit-type/get_sub',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            getRequestStack: build.mutation<requestStackRes, void>({
                query: (args) => {
                    return {
                        url: '/request-stack',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            getWithdrawClientList: build.mutation<WiyhdrawClientRes, void>({
                query: (args) => {
                    return {
                        url: '/enduser/withdraw-request-client',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            getSelfWithdraw: build.mutation<any, any>({
                query: (args) => {
                    return {
                        url: '/self-withdraw-app',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            getSaveClient: build.mutation<any, SelfWithdrawPayload>({
                query: (args) => {
                    return {
                        url: '/save/client-bank',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            getWithtype: build.mutation<any, void>({
                query: (args) => {
                    return {
                        url: '/withtype-subadmin/get',
                        method: 'POST',
                        body: args
                    }
                }
            }),
            getClientBank: build.mutation<any, void>({
                query: (args) => {
                    return {
                        url: '/get/client-bank',
                        method: 'POST',
                        body: args
                    }
                }
            }),

        }

    }
})

export const {
    useLoginMutation,
    useDemologinMutation,
    useBannerImgMutation,
    useLeftMenuListMutation,
    useIsSelfByAppUrlMutation,
    useGetUserBalanceQuery,
    useValidateJWTQuery,
    useLazyLogoutQuery,
    useChangePasswordMutation,
    useAccountStatementMutation,
    useGetstakebuttonQuery,
    useSetstakebuttonMutation,
    useBetlistbymatchidMutation,
    useGetBetPlacedMutation,
    useUseroddspnlQuery,
    useUserfancypnlQuery,
    useWinnerPnlQuery,
    useProfitlossmatchwiseMutation,
    useAllcasinotypeQuery,
    useCasinotablebytypesMutation,
    useActivematchsportwiseMutation,
    useActivesportlistQuery,
    useUnsettledbetMutation,
    useBetlisthistoryMutation,
    useGetoneuserbetresultQuery,
    useGetdataQuery,
    useSelfregisterMutation,
    useGetDepositListMutation,
    useGetSelfDepositMutation,
    useGetPaymentMethodMutation,
    useGetRequestStackMutation,
    useGetWithdrawClientListMutation,
    useGetSelfWithdrawMutation,
    useGetSaveClientMutation,
    useGetWithtypeMutation,
    useGetClientBankMutation
} = mainApi;

export default mainApi;