// import cricketoddsimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/crickabtmarket/cricket-odds.webp";
// import fancyimg from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/crickabtmarket/fancyimg.webp'
// import cricketlambiimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/crickabtmarket/cricket-lambi.webp";
// import oversessionimg from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/crickabtmarket/oversession.webp'
// import batsmanrunimg from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/crickabtmarket/batsmanrun.webp'


type cricketDataType = {
    heading?: string
    data: subdata
}[]

type subdata = {
    subheading?: string
    titleone: string,
    titletwo?: string,
    img: string
}

export const cricketdata: cricketDataType = [
    {
        heading: 'Match Odds:',
        data: {
            titleone: 'Back - Placing a back bet simply means you are staking money on the team to win.',
            titletwo: 'Lay - Placing a lay bet simply means you are staking money on the team to NOT win.',
            img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/crickabtmarket/cricket-odds.webp'
        }
    },
    {
        heading: 'Fancy:',
        data: {
            subheading: 'Fall of Wicket:',
            titleone: 'Bet on when the wicket shall fall in line with the score. Chose whether the wicket will fall within that score or not',
            titletwo: '**in case the wicket doesnt fall, the bets are voided**',
            img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/crickabtmarket/fancyimg.webp'
        }
    },
    {
        data: {
            subheading: 'Batsman Runs:',
            titleone: 'Bet on the runs a batsman shall score in an innings. The market opens once the batsman comes on crease.',
            img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/crickabtmarket/batsmanrun.webp'
        }
    },
    {
        data: {
            subheading: 'Lambi Runs:',
            titleone: 'Bet on the final innings score of the batting team.',
            img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/crickabtmarket/cricket-lambi.webp'
        }
    },
    {
        data: {
            subheading: 'Over sessions:',
            titleone: 'Bet on the score at the end of the said number of overs.',
            img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/crickabtmarket/oversession.webp'
        }
    }
]

