import * as React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import type { SelectChangeEvent } from "@mui/material"
import { Box } from "@mui/material"
import NoData from "../NoData"
import MatchedDeletedRadioSports from "./MatchedDeletedRadioSports"
import AllBackLayRadioSports from "./AllBackLayRadioSports"
import ShowEntriesSelectSports from "./ShowEntriesSelectSports"
import { useUnsettledbetMutation } from "../../api/mainApi/mainApiSlice"
import { posNegPnl } from "../../utils/posNegPnl"
import TableForSports from "./TableForSports"

interface Props {
  exp: boolean
}

const MatchedBetsAccordionSports: React.FC<Props> = ({ exp }) => {
  const [showEntries, setShowEntries] = React.useState("5")
  const [label, setLabel] = React.useState("false")
  const [allbacklay, setAllBackLay] = React.useState("1")
  const handleMatchedDeleted = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value)
  }
  const [isExpanded, setIsExpanded] = React.useState(true)
  const handleSelectChange = (event: SelectChangeEvent) => {
    setShowEntries(event.target.value)
  }

  const handlebacklay = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAllBackLay(e.target.value)
  }

  const [sportTrigger, { data: unsettledsportsData }] =
    useUnsettledbetMutation()

  React.useEffect(() => {
    sportTrigger({
      index: 0,
      betType: Number(allbacklay),
      isDeleted: label === "true" ? true : false,
      noOfRecords: Number(showEntries),
      sportType: 1,
    })
  }, [label, showEntries, allbacklay])

  const handleAccordionChange = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <Box className="sportsaccordion">
      <Box
        sx={{
          px: 1,
        }}
      >
        {!exp && (
          <MatchedDeletedRadioSports
            label={label}
            handleMatchedDeleted={handleMatchedDeleted}
          />
        )}
      </Box>
      <Box
        className="sportselectandRadioholder"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          px: 1,
          pb: 2,
        }}
      >
        <AllBackLayRadioSports
          allbacklay={allbacklay}
          handlebacklay={handlebacklay}
        />

        <ShowEntriesSelectSports
          showEntries={showEntries}
          handleSelectChange={handleSelectChange}
        />

        <Box
          className="totalbetsandtotalamount"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "#fff",
            gap: 1,
          }}
        >
          <Box
            className="totalbets"
            sx={{
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: "0.8rem" }}>total bets</Typography>
            <Typography sx={{ fontSize: "0.8rem" }}>
              {posNegPnl(unsettledsportsData?.data?.totalBets)}
            </Typography>
          </Box>
          <Box
            className="totalamount"
            sx={{
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: "0.8rem" }}>total amount</Typography>
            <Typography sx={{ fontSize: "0.8rem" }}>
              {posNegPnl(unsettledsportsData?.data?.totalStake)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Accordion
        defaultExpanded
        expanded={isExpanded}
        onChange={handleAccordionChange}
        sx={{
          backgroundColor: "transparent",
          "&.Mui-expanded": {
            margin: 0,
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                borderRadius: "50%",
                color: "white",
                backgroundColor: "#f36c21",
                padding: "4px",
              }}
            />
          }
          aria-controls="matched-bets-content"
          id="matched-bets-header"
          sx={{
            "&.MuiPaper-root": {
              ":before": {
                backgroundColor: "rgba(255, 255, 255, 0)",
              },
            },
            border: "1px solid #f36c21",
            borderRadius: isExpanded ? "15px 15px 0 0" : "15px",
            minHeight: "46px !important",
            "&.Mui-expanded": {
              minHeight: "46px !important",
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              minHeight: "46px !important",
              margin: "0px",
            },
            "& .MuiAccordionSummary-content": {
              alignItems: "center",
              margin: "0px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(180deg)",
            },
          }}
        >
          <Typography variant="h6" sx={{ fontSize: "13px", color: "#fff" }}>
            Matched Bets
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: 0,
            border: "1px solid #f36c21",
            borderRadius: "0 0 15px 15px",
            overflow: "hidden",
            "& .MuiPaper-root": {
              backgroundColor: "transparent",
            },
          }}
        >
          <TableForSports unsettledsportsData={unsettledsportsData} />
          {!unsettledsportsData?.data?.dataList.length && <NoData />}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default MatchedBetsAccordionSports
