import { Box, Grid, Typography } from "@mui/material"
import { FC } from "react"
import { Link } from "react-router-dom"
// import rglbimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/rglb.png"

interface LiveCardProps {
  title1: string
  title2: string
}

const LiveCard: FC<LiveCardProps> = ({ title1, title2 }) => {
  return (
    <Box
      className="live-card"
      sx={{
        my: 1,
        boxShadow:
          "0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)",
        position: "relative",
        padding: "0.5rem",
        backgroundColor: "rgba(23,13,31,.5)!important",
        borderRadius: "16px ",
        backdropFilter: "blur(15px)",
        border: "1px solid #474747",
      }}
    >
      <Box
        className="wrapper"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography
          component="h2"
          sx={{
            width: "100%",
            fontSize: "21px",
            fontWeight: "800",
            lineHeight: "23px",
            color: "#fff",
            fontStyle: "normal",
            textTransform: "capitalize",
          }}
        >
          {title1}
          <Typography
            component="strong"
            sx={{
              marginLeft: 1,
              color: "#6fad47",
              fontSize: "21px",
              fontWeight: "800",
              lineHeight: "23px",
              fontStyle: "normal",
              textTransform: "capitalize",
            }}
          >
            {title2}
          </Typography>
        </Typography>
        <Link to={"#"} className="text-[#6fad47] text-[16px] capitalize">
          more...
        </Link>
      </Box>

      <Grid
        container
        sx={{ justifyContent: "space-between" }}
        columnSpacing={{ xs: 2 }}
        rowSpacing={1}
      >
        {[1, 2, 3, 4, 5, 6].map((data, index) => (
          <Grid
            item
            xs={6}
            md={3.8}
            key={data + index}
            sx={{ my: 0.5, cursor: "pointer" }}
          >
            <Box
              sx={{
                backgroundImage:
                  "linear-gradient(#130c2d,#130c2d) padding-box,linear-gradient(270deg,#02559c,#02559c) border-box",
                boxShadow: "0 -1px 5px 2px #02559c",
                border: "2px solid transparent",
                padding: "10px",
                borderRadius: "50em",
                height: "50px",
                width: "100%",
                color: "#fff",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                ":hover": {
                  boxShadow: "0 -1px 5px 2px #4b16d1",
                  backgroundImage:
                    "linear-gradient(#130c2d,#423572) padding-box,linear-gradient(270deg,#2310d9,#fab2ff) border-box",
                },
              }}
            >
              <Typography
                component="p"
                sx={{
                  fontSize: { xs: "9px", md: "13px" },
                  color: "#fff",
                }}
              >
                Royal Gaming Live Lobby
              </Typography>
              <Typography component="span">
                <img
                  src={
                    "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/rglb.png"
                  }
                  alt=""
                  className=" lg:w-[30px] w-[20px]"
                />
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default LiveCard
