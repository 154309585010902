import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { url } from "inspector";


const betFairApiSlice = createApi({
    reducerPath: "betfairapislice",
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BET_FAIR_URL,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('token')
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),

    endpoints: (build) => {
        return {
            activeMatch: build.query<activeMatchRes, void>({
                query: () => {
                    return {
                        url: '/active_match',
                        method: 'GET'
                    }
                }
            }),
            activeMatchById: build.query<activeMatchIdResponse, string | number>({
                query: (id) => {
                    return {
                        url: `/active_match/${id}`,
                        method: 'GET'
                    }
                }
            }),
            myIp: build.query<IpRes, void>({
                query: () => {
                    return {
                        url: '/my-ip',
                        method: "GET"
                    }
                }
            }),
            sportDetailFancyApi: build.query<BetFairSportEventDetailRes, number>({
                query: (args) => {
                    return {
                        url: `/fancy/${args}`,
                        method: 'GET'
                    }
                }
            }),
            getMyIp: build.query<MyIP, void>({
                query: (args) => {
                    return {
                        url: `/my-ip`,
                        method: 'GET'
                    }
                }
            })
        }
    }
})

export const { useActiveMatchQuery, useMyIpQuery, useSportDetailFancyApiQuery, useActiveMatchByIdQuery, useGetMyIpQuery } = betFairApiSlice
export default betFairApiSlice