import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
) {
  return { name, calories, fat, carbs, protein }
}

const styleth = {
  textAlign: "left",
  color: "#fff",
  textTransform: "capitalize",
  textWrap: "nowrap",
}

const styletd = {
  textAlign: "left",
  color: "#fff",
  textTransform: "capitalize",
}

interface TableForCasinoProps {
  unsettledsportsData: unsettledRes | undefined
}

const TableForCasino: React.FC<TableForCasinoProps> = ({
  unsettledsportsData,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={styleth}>event name</TableCell>
            <TableCell sx={styleth}>nation</TableCell>
            <TableCell sx={styleth}>user rate</TableCell>
            <TableCell sx={styleth}>Amount</TableCell>
            <TableCell sx={styleth}>Place Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {unsettledsportsData?.data?.dataList?.map((row, idx) => (
            <TableRow
              key={idx}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                " &.MuiTableRow-root": {
                  borderLeft: row?.isback
                    ? "5px solid #A5D9FE"
                    : "2px solid #F8D0Ce",
                },
              }}
            >
              <TableCell sx={styletd}>{row?.eventName}</TableCell>
              <TableCell sx={styletd}>{row?.nation}</TableCell>
              <TableCell sx={styletd}>{row?.rate}</TableCell>
              <TableCell sx={styletd}>{row?.amount}</TableCell>
              <TableCell sx={styletd}>{row?.time}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableForCasino
