import * as React from "react"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { Typography } from "@mui/material"

type entriesValType = number[]

const entriesVal: entriesValType = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100]

interface ShowEntriesSelectProps {
  showEntries: string
  handleSelectChange: (event: SelectChangeEvent) => void
}

const ShowEntriesSelectCasino: React.FC<ShowEntriesSelectProps> = ({
  showEntries,
  handleSelectChange,
}) => {
  return (
    <FormControl
      sx={{
        // m: 1,
        minWidth: 120,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
      size="small"
    >
      <Typography
        component="p"
        sx={{ textTransform: "capitalize", color: "#fff" }}
      >
        show
      </Typography>
      <Select
        size="small"
        sx={{ mx: 2, p: 0 }}
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={showEntries}
        onChange={handleSelectChange}
      >
        {entriesVal?.map((num, idx) => (
          <MenuItem key={idx} value={num} sx={{ color: "#fff" }}>
            {num}
          </MenuItem>
        ))}
      </Select>
      <Typography
        component="p"
        sx={{ textTransform: "capitalize", color: "#fff" }}
      >
        entries
      </Typography>
    </FormControl>
  )
}

export default ShowEntriesSelectCasino
