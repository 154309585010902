import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import SliderComp from "./SliderComp"
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { MdOutlineSensors } from "react-icons/md"
// import cricket from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/cricket.webp"
import { FaPlay } from "react-icons/fa"
import { Link, useNavigate, useParams } from "react-router-dom"
import LiveCard from "../../common/LiveCard"
// import premiumgif from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/premium-new.gif"
import { useActiveMatchQuery } from "../../api/betFairApi/betFairApiSlice"
import sportIcon from "../../utils/sporticon"
import "./homerightsectionstyle.css"

const teamnamestyle = {
  fontSize: { md: "0.75rem", xs: "10px" },
  fontFamily: "Roboto,sans-serif ",
  fontWeight: "700",
  color: "#212121",
  opacity: 0.9,
}

const HomeRightSection = () => {
  const isMobile = useMediaQuery("(max-width:600px)")
  const nav = useNavigate()
  const [inplay, setInplay] = useState<boolean>()

  const { data: activematchData } = useActiveMatchQuery()
  const { id } = useParams()

  const handleNavigate = (matchdata: MatchList, sportid: any) => {
    nav(`/sports-event-detail/${matchdata?.matchId}/${sportid}`, {
      state: {
        marketId: matchdata?.marketId,
      },
    })
  }

  useEffect(() => {
    activematchData?.data.forEach(sport => {
      sport.matchList.map(data => {
        setInplay(data.inPlay)
      })
    })
  }, [activematchData])

  console.log(activematchData?.data, "activeeeee")

  return (
    <div
      // style={{
      //   backgroundImage:
      //     'url("https://s3buket.blr1.cdn.digitaloceanspaces.com/images/inplay_bg.webp")',
      // }}
      className="homerightsection border border-[#3a3737]
      bg-cover
        bg-no-repeat
        rounded-[10px]
        bg-fixed
        bg-[#121212]
    "
    >
      {/* <SliderComp /> */}

      {activematchData?.data?.map((activematch, idx) => (
        <Box className="sportscomp" key={idx} sx={{ my: 2 }}>
          <Typography
            component="p"
            sx={{
              padding: "0 10px",
              fontSize: { md: "14px", xs: "10px" },
              color: "#fff",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              textTransform: "uppercase",
            }}
          >
            <Typography
              component="span"
              sx={{
                borderRadius: "50%",
                backgroundColor: "#fc6321",
                height: { md: "23px", xs: "20px" },
                width: { md: "23px", xs: "20px" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mr: 1,
              }}
            >
              <MdOutlineSensors />
            </Typography>
            {inplay ? "INPLAY" : "Upcoming"}
          </Typography>

          {activematch?.matchList.length > 0 && (
            <Box
              sx={{
                overflow: "hidden",
                borderRadius: "15px",
                my: 2,
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={5}
                  md={4}
                  sx={{ backgroundColor: "#f36c21", display: "flex" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      px: "10px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      src={sportIcon(activematch?.name)}
                      alt=""
                      className="h-[20px] lg:h-[23px] w-[20px] lg:w-[23px]"
                    />
                    <Typography
                      component="p"
                      sx={{
                        color: "#f8f9fa",
                        fontSize: { md: "14px", xs: "12px" },
                        ml: 1,
                        fontWeight: "600",
                        fontFamily: "roboto, sans-serif",
                        textTransform: "uppercase",
                      }}
                    >
                      {activematch?.name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} md={8}>
                  <Grid
                    container
                    className="rightheadstrip"
                    sx={{
                      backgroundColor: "#272727",
                    }}
                  >
                    <Grid item xs={2} lg={6} md={4}></Grid>
                    <Grid item xs={10} lg={6} md={8}>
                      <Grid container sx={{ padding: "9px" }}>
                        <Grid item xs={4}>
                          <Typography
                            sx={{
                              padding: { md: "0 0 0 0", xs: "0 0 0 0px" },
                              fontSize: "15px",
                              textAlign: "center",
                            }}
                          >
                            1
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            sx={{
                              padding: { md: "0 0 0 0", xs: "0 0 0 0px" },
                              fontSize: "15px",
                              textAlign: "center",
                            }}
                          >
                            x
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            sx={{
                              padding: { md: "0 0 0 0", xs: "0 0 0 0px" },
                              fontSize: "15px",
                              textAlign: "center",
                            }}
                          >
                            2
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {activematch?.matchList?.map((matchlistdata, index) => (
                <Grid
                  key={index}
                  onClick={() =>
                    handleNavigate(matchlistdata, activematch?.sportid)
                  }
                  container
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    borderBottom: "1px solid #e0e0e0",
                    padding: "3px 0 3px 10px",
                  }}
                >
                  <Grid
                    item
                    xs={3}
                    lg={3}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ mt: "5px" }}>
                      <Typography
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                        component="span"
                      >
                        <Typography component="span" sx={teamnamestyle}>
                          {matchlistdata?.matchName}
                        </Typography>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3} lg={3}>
                    <Box
                      sx={{
                        mt: "5px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        height: "40px",
                        padding: { xs: "0 20px", md: "0" },
                      }}
                      className="playliveicon"
                    >
                      <Box
                        className="inplaylogoleft"
                        sx={{ width: "50%", display: "flex" }}
                      >
                        <button className="px-[6px] py-[12px]">
                          <FaPlay
                            className="text-[11px] text-[#4caf50]"
                            title="IN-PLAY"
                          />
                        </button>
                      </Box>

                      <Box className="inplaylogoright">
                        <button className="px-[6px] py-[12px]">
                          <MdOutlineSensors
                            className=" text-[#4caf50]"
                            title="LIVE"
                          />
                        </button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Box
                      sx={{
                        mt: "5px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                        height: "40px",
                      }}
                      className="inplaygamelivesec"
                    >
                      <Box className="livebettingbox">
                        <List sx={{ display: "flex", p: 0, pr: "5px" }}>
                          <ListItem
                            sx={{
                              margin: "1px",
                              p: 0,
                              overflow: "hidden",
                              borderRadius: { md: "0", xs: "10px" },
                              backgroundColor: "#a5d9fe",
                            }}
                          >
                            <button
                              className={` h-[35px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] w-[50px] lg:w-[59px] text-[#000] inline-block leading-[13px] border-0 overflow-hidden `}
                            >
                              <Typography
                                component="span"
                                sx={{
                                  fontSize: "11px",
                                  color: "#000",
                                  fontFamily: "Roboto,sans-serif",
                                }}
                              >
                                <Typography
                                  component="strong"
                                  sx={{
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    color: "#000",
                                    fontFamily: "Roboto,sans-serif",
                                  }}
                                >
                                  {matchlistdata?.team1Back}
                                </Typography>
                              </Typography>
                            </button>
                          </ListItem>

                          {!isMobile && (
                            <ListItem
                              sx={{
                                margin: "1px",
                                p: 0,
                                overflow: "hidden",
                                borderRadius: { md: "0", xs: "10px" },
                                backgroundColor: "#F8D0CE",
                              }}
                            >
                              <button
                                className={` h-[35px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] w-[50px] lg:w-[59px] text-[#000] inline-block leading-[13px] border-0 overflow-hidden `}
                              >
                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: "11px",
                                    color: "#000",
                                    fontFamily: "Roboto,sans-serif",
                                  }}
                                >
                                  <Typography
                                    component="strong"
                                    sx={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                      color: "#000",
                                      fontFamily: "Roboto,sans-serif",
                                    }}
                                  >
                                    {matchlistdata?.team1Lay}
                                  </Typography>
                                </Typography>
                              </button>
                            </ListItem>
                          )}

                          <ListItem
                            sx={{
                              margin: "1px",
                              p: 0,
                              overflow: "hidden",
                              borderRadius: { md: "0", xs: "10px" },
                              backgroundColor: "#a5d9fe",
                            }}
                          >
                            <button
                              className={` h-[35px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] w-[50px] lg:w-[59px] text-[#000] inline-block leading-[13px] border-0 overflow-hidden `}
                            >
                              <Typography
                                component="span"
                                sx={{
                                  fontSize: "11px",
                                  color: "#000",
                                  fontFamily: "Roboto,sans-serif",
                                }}
                              >
                                <Typography
                                  component="strong"
                                  sx={{
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    color: "#000",
                                    fontFamily: "Roboto,sans-serif",
                                  }}
                                >
                                  {matchlistdata?.drawBack}
                                </Typography>
                              </Typography>
                            </button>
                          </ListItem>

                          {!isMobile && (
                            <ListItem
                              sx={{
                                margin: "1px",
                                p: 0,
                                overflow: "hidden",
                                borderRadius: { md: "0", xs: "10px" },
                                backgroundColor: "#F8D0CE",
                              }}
                            >
                              <button
                                className={` h-[35px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] w-[50px] lg:w-[59px] text-[#000] inline-block leading-[13px] border-0 overflow-hidden `}
                              >
                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: "11px",
                                    color: "#000",
                                    fontFamily: "Roboto,sans-serif",
                                  }}
                                >
                                  <Typography
                                    component="strong"
                                    sx={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                      color: "#000",
                                      fontFamily: "Roboto,sans-serif",
                                    }}
                                  >
                                    {matchlistdata?.drawLay}
                                  </Typography>
                                </Typography>
                              </button>
                            </ListItem>
                          )}

                          <ListItem
                            sx={{
                              margin: "1px",
                              p: 0,
                              overflow: "hidden",
                              borderRadius: { md: "0", xs: "10px" },
                              backgroundColor: "#a5d9fe",
                            }}
                          >
                            <button
                              className={` h-[35px] lg:h-[40px] py-[1px] px-[6px] text-center text-[11px] w-[50px] lg:w-[59px] text-[#000] inline-block leading-[13px] border-0 overflow-hidden `}
                            >
                              <Typography
                                component="span"
                                sx={{
                                  fontSize: "11px",
                                  color: "#000",
                                  fontFamily: "Roboto,sans-serif",
                                }}
                              >
                                <Typography
                                  component="strong"
                                  sx={{
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    color: "#000",
                                    fontFamily: "Roboto,sans-serif",
                                  }}
                                >
                                  {matchlistdata?.team2Back}
                                </Typography>
                              </Typography>
                            </button>
                          </ListItem>

                          {!isMobile && (
                            <ListItem
                              sx={{
                                margin: "1px",
                                p: 0,
                                overflow: "hidden",
                                backgroundColor: "#F8D0CE",
                              }}
                              className={"last:rounded-br-[10px] bg-[#F8D0CE]"}
                            >
                              <button
                                className={` h-[35px] lg:h-[40px] py-[1px] lg:rounded-[10px] px-[6px] text-center text-[11px] w-[50px] lg:w-[59px] text-[#000] inline-block leading-[13px] border-0 overflow-hidden `}
                              >
                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: "11px",
                                    color: "#000",
                                    fontFamily: "Roboto,sans-serif",
                                  }}
                                >
                                  <Typography
                                    component="strong"
                                    sx={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                      color: "#000",
                                      fontFamily: "Roboto,sans-serif",
                                    }}
                                  >
                                    {matchlistdata?.team2Lay}
                                  </Typography>
                                </Typography>
                              </button>
                            </ListItem>
                          )}
                          {/* end */}
                        </List>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </Box>
          )}
        </Box>
      ))}

      {/* livecard */}

      <Grid
        className="livecardholder"
        container
        rowSpacing={0}
        columnSpacing={{ xs: 2 }}
        sx={{ justifyContent: "space-between" }}
      >
        <Grid item xs={12} md={12} lg={6}>
          <LiveCard title1="live" title2="Cards" />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <LiveCard title1="live" title2="Casino" />
        </Grid>
      </Grid>

      {/* livecard */}

      <Box className="gifimg" sx={{ my: 2 }}>
        <img
          loading="lazy"
          src={
            "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/premium-new.gif"
          }
          alt=""
          className="w-full"
        />
      </Box>
    </div>
  )
}
export default HomeRightSection
