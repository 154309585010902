import {
  Box,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { Link, useNavigate, useParams } from "react-router-dom"
import { CiStar } from "react-icons/ci"
import { AiFillStar } from "react-icons/ai"
import { FaPlay } from "react-icons/fa"
import { useEffect, useState } from "react"
import { useActiveMatchByIdQuery } from "../../../api/betFairApi/betFairApiSlice"
import NoData from "../../../common/NoData"
import { useAppDispatch } from "../../../hooks/useAppDispatch"
import { setSportDataLength } from "../../../api/sportdatalength/sportdataslice"

const teamnamestyle = {
  whiteSpace: "nowrap",
  fontSize: { md: "0.75rem", xs: "10px" },
  fontFamily: "Roboto,sans-serif ",
  fontWeight: "700",
  color: "#212121",
  opacity: 0.9,
  padding: "10px",
  textOverflow: "ellipsis",
  overflow: "hidden",
}

const SportsMatchesData = () => {
  const { id } = useParams<{ id: any }>()
  const nav = useNavigate()
  const isMobile = useMediaQuery("(max-width:600px)")
  const [fav, setFav] = useState(false)
  const dispatch = useAppDispatch()
  const handleFavourite = () => {
    setFav(!fav)
  }

  const { data: activematchData } = useActiveMatchByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  })

  const handleNavigate = (matchdata: activematchdata) => {
    nav(`/sports-event-detail/${matchdata?.matchId}/${id}`)
  }

  // useEffect(() => {
  //   dispatch(setSportDataLength(activematchData?.data?.length))
  //   console.log(activematchData?.data?.length, "ksksksksk")
  // }, [activematchData])

  return (
    <>
      <Box className="sportsmatchdata" sx={{ my: 1 }}>
        {activematchData?.data?.map(activematches => {
          // const matchName = activematches?.matchName.split(" v ")
          return (
            <Grid container key={activematches?.matchId}>
              <Grid
                item
                xs={12}
                sx={{
                  width: {
                    md: `${isMobile && "100%"}`,
                    maxWidth: `${!isMobile && "100%"}`,
                  },
                }}
              >
                {/* sports match card */}

                <Box className="iconwrapper" sx={{ position: "relative" }}>
                  <Typography
                    component="span"
                    sx={{
                      position: "absolute",
                      backgroundColor: activematches?.inPlay
                        ? "#4caf50"
                        : "#2196F3",
                      padding: "2px 6px",
                      textTransform: "uppercase",
                      fontWeight: 600,
                      top: -10,
                      left: 10,
                      fontSize: "10px",
                      color: "#fff",
                      borderRadius: 1,
                      fontStyle: "italic",
                    }}
                  >
                    {activematches?.inPlay ? "live" : "upcoming"}
                  </Typography>

                  <Box
                    sx={{
                      overflow: "hidden",
                      borderRadius: "15px",
                      my: 2,
                    }}
                  >
                    <Grid
                      container
                      sx={{ cursor: "pointer" }}
                      className="topheadingdiv"
                      onClick={() => handleNavigate(activematches)}
                    >
                      <Grid
                        item
                        xs={8}
                        md={4}
                        sx={{ backgroundColor: "#f36c21", display: "flex" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            px: "10px",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            mt: 1,
                          }}
                        >
                          <Typography
                            component="p"
                            sx={{
                              color: "#f8f9fa",
                              fontSize: { md: "12px", xs: "12px" },
                              ml: { md: 1, xs: 0 },
                              fontFamily: "roboto, sans-serif",
                            }}
                          >
                            {activematches?.matchName}
                          </Typography>
                          <Typography
                            component="p"
                            sx={{
                              color: "#f8f9fa",
                              fontWeight: 600,
                              fontSize: { md: "12px", xs: "12px" },
                              ml: { md: 1, xs: 0 },
                              fontFamily: "roboto, sans-serif",
                            }}
                          >
                            {activematches?.openDate}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4} md={8}>
                        <Grid
                          container
                          className="rightheadstrip"
                          sx={{ backgroundColor: "#272727", height: "100%" }}
                        >
                          <Grid
                            item
                            xs={4}
                            md={6}
                            sx={{
                              display: { md: "block", xs: "none", sm: "none" },
                            }}
                          >
                            {/* empty */}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Grid container sx={{ padding: "9px" }}>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{ padding: "0 50%", fontSize: "15px" }}
                                >
                                  <FaPlay
                                    className="text-[14px] text-white"
                                    title="In-Play"
                                  />
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{ display: { md: "block", xs: "none" } }}
                              >
                                {/* empty */}
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  component="p"
                                  sx={{
                                    padding: { md: "0 50%", xs: "0 65%" },
                                    fontSize: "15px",
                                  }}
                                >
                                  <Typography
                                    component="span"
                                    onClick={handleFavourite}
                                  >
                                    {fav ? (
                                      <AiFillStar
                                        className="text-[20px] text-[#ffc107] cursor-pointer"
                                        title="Add to Favourite"
                                      />
                                    ) : (
                                      <CiStar
                                        className="text-[20px] text-[#ffc107] cursor-pointer"
                                        title="Add to Favourite"
                                      />
                                    )}
                                  </Typography>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Link to={"#"}>
                      <Grid
                        container
                        sx={{
                          backgroundColor: "#fff",
                          borderBottom: "1px solid #e0e0e0",
                          padding: "3px 0 3px 10px",
                        }}
                      >
                        <Grid item xs={6} lg={6}>
                          <Box sx={{ mt: "5px" }}>
                            <Typography
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                textWrap: "wrap",
                                whiteSpace: "wrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                              component="span"
                            >
                              <Typography component="span" sx={teamnamestyle}>
                                {activematches?.matchName}
                              </Typography>
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={6} lg={6}>
                          <Box
                            sx={{
                              mt: "5px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                              height: "40px",
                            }}
                            className="inplaygamelivesec"
                          >
                            <Box className="livebettingbox">
                              <List sx={{ display: "flex", p: 0, pr: "5px" }}>
                                <ListItem sx={{ p: 0, overflow: "hidden" }}>
                                  <button
                                    className={` h-[35px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] w-[50px] lg:w-[59px] text-[#000] bg-[#a5d9fe] inline-block leading-[13px] border-0 overflow-hidden `}
                                  >
                                    <Typography
                                      component="span"
                                      sx={{
                                        fontSize: "11px",
                                        color: "#000",
                                        fontFamily: "Roboto,sans-serif",
                                      }}
                                    >
                                      <Typography
                                        component="strong"
                                        sx={{
                                          fontSize: "11px",
                                          fontWeight: "bold",
                                          color: "#000",
                                          fontFamily: "Roboto,sans-serif",
                                        }}
                                      >
                                        {activematches?.team1Back}
                                      </Typography>
                                    </Typography>
                                    <br />
                                  </button>
                                </ListItem>
                                {!isMobile && (
                                  <ListItem sx={{ p: 0, overflow: "hidden" }}>
                                    <button
                                      className={` h-[35px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] w-[50px] lg:w-[59px] text-[#000] bg-[#a5d9fe] inline-block leading-[13px] border-0 overflow-hidden `}
                                    >
                                      <Typography
                                        component="span"
                                        sx={{
                                          fontSize: "11px",
                                          color: "#000",
                                          fontFamily: "Roboto,sans-serif",
                                        }}
                                      >
                                        <Typography
                                          component="strong"
                                          sx={{
                                            fontSize: "11px",
                                            fontWeight: "bold",
                                            color: "#000",
                                            fontFamily: "Roboto,sans-serif",
                                          }}
                                        >
                                          {activematches?.drawBack}
                                        </Typography>
                                      </Typography>
                                    </button>
                                  </ListItem>
                                )}
                                {!isMobile && (
                                  <ListItem sx={{ p: 0, overflow: "hidden" }}>
                                    <button
                                      className={` h-[35px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] w-[50px] lg:w-[59px] text-[#000]  bg-[#a5d9fe] inline-block leading-[13px] border-0 overflow-hidden `}
                                    >
                                      <Typography
                                        component="span"
                                        sx={{
                                          fontSize: "11px",
                                          color: "#000",
                                          fontFamily: "Roboto,sans-serif",
                                        }}
                                      >
                                        <Typography
                                          component="strong"
                                          sx={{
                                            fontSize: "11px",
                                            fontWeight: "bold",
                                            color: "#000",
                                            fontFamily: "Roboto,sans-serif",
                                          }}
                                        >
                                          {activematches?.team2Back}
                                        </Typography>
                                      </Typography>
                                    </button>
                                  </ListItem>
                                )}
                                <ListItem sx={{ p: 0, overflow: "hidden" }}>
                                  <button
                                    className={` h-[35px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] w-[50px] lg:w-[59px] text-[#000] bg-[#F8D0CE] inline-block leading-[13px] border-0 overflow-hidden `}
                                  >
                                    <Typography
                                      component="span"
                                      sx={{
                                        fontSize: "11px",
                                        color: "#000",
                                        fontFamily: "Roboto,sans-serif",
                                      }}
                                    >
                                      <Typography
                                        component="strong"
                                        sx={{
                                          fontSize: "11px",
                                          fontWeight: "bold",
                                          color: "#000",
                                          fontFamily: "Roboto,sans-serif",
                                        }}
                                      >
                                        {activematches?.team1Lay}
                                      </Typography>
                                    </Typography>
                                  </button>
                                </ListItem>
                                {!isMobile && (
                                  <ListItem sx={{ p: 0, overflow: "hidden" }}>
                                    <button
                                      className={` h-[35px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] w-[50px] lg:w-[59px] text-[#000] bg-[#F8D0CE] inline-block leading-[13px] border-0 overflow-hidden `}
                                    >
                                      <Typography
                                        component="span"
                                        sx={{
                                          fontSize: "11px",
                                          color: "#000",
                                          fontFamily: "Roboto,sans-serif",
                                        }}
                                      >
                                        <Typography
                                          component="strong"
                                          sx={{
                                            fontSize: "11px",
                                            fontWeight: "bold",
                                            color: "#000",
                                            fontFamily: "Roboto,sans-serif",
                                          }}
                                        >
                                          {activematches?.drawLay}
                                        </Typography>
                                      </Typography>
                                    </button>
                                  </ListItem>
                                )}

                                {!isMobile && (
                                  <ListItem sx={{ p: 0, overflow: "hidden" }}>
                                    <button
                                      className={` h-[35px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] w-[50px] lg:w-[59px] text-[#000] bg-[#F8D0CE] inline-block leading-[13px] border-0 overflow-hidden `}
                                    >
                                      <Typography
                                        component="span"
                                        sx={{
                                          fontSize: "11px",
                                          color: "#000",
                                          fontFamily: "Roboto,sans-serif",
                                        }}
                                      >
                                        <Typography
                                          component="strong"
                                          sx={{
                                            fontSize: "11px",
                                            fontWeight: "bold",
                                            color: "#000",
                                            fontFamily: "Roboto,sans-serif",
                                          }}
                                        >
                                          {activematches?.team2Lay}
                                        </Typography>
                                      </Typography>
                                      <br />
                                      {/* <Typography
                                    component="span"
                                    sx={{ fontSize: "11px", color: "#000" }}
                                  >
                                    120
                                  </Typography> */}
                                    </button>
                                  </ListItem>
                                )}
                              </List>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Link>
                  </Box>
                </Box>

                {/* sports match card */}
              </Grid>
            </Grid>
          )
        })}
      </Box>

      {activematchData?.data && !(activematchData?.data?.length > 0) && (
        <NoData />
      )}
    </>
  )
}

export default SportsMatchesData
