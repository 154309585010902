import type { FC} from "react";
import React, { useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router"
import Header from "../../common/Header"
import SportsBar from "../../common/SportsBar"
import Footer from "../pages/footer/Footer"
import DownloadTheApp from "../../common/DownloadTheApp"
import PaymentMethodComp from "../../common/PaymentMethodComp"
import SportsBarMobileFooter from "../../common/SportsBarMobileFooter"
import { useMediaQuery } from "@mui/material"
import { useValidateJWTQuery } from "../../api/mainApi/mainApiSlice"
import WelcomeDialog from "../../common/WelcomeDialog";

interface layoutProps {
  currpathname: string
  isSelfData: isSelfRes | undefined
}

const Layout: FC<layoutProps> = ({ currpathname, isSelfData }) => {
  const token = localStorage.getItem("token")
  useValidateJWTQuery({}, { pollingInterval: 1000, skip: !token })
  const isMobile = useMediaQuery("(max-width:767px)")
  const navigate = useNavigate()
  const isLoginPage = currpathname === "/login"
  const isRegisterPage = currpathname === "/register"
  const host = window.location.hostname;

  useEffect(() => {
    if (!token) {
      navigate("/")
    }
  }, [token])
  return (
    <div>
      <Header isSelfData={isSelfData} />
      <Outlet />

      {host?.includes("indiabet777") ? <WelcomeDialog /> : null}

      {currpathname === "/" && <PaymentMethodComp />}
      {!isLoginPage && !isRegisterPage && <Footer isSelfData={isSelfData}/>}

      {isMobile && !isLoginPage && !isRegisterPage && <SportsBarMobileFooter />}
    </div>
  )
}

export default Layout
