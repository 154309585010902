import * as React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import { Container, Typography } from "@mui/material"
// import blackjackimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/blackjack.webp"
// import baccaratimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/baccarat.webp"
// import pokertabimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/pokertabimg.webp"
// import rouletteimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/roulette.webp"
// import gameshowimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/gameshow.webp"
import { Link } from "react-router-dom"
import {
  baccaratvideodata,
  blackjack,
  gameshow,
  poker,
  videodata,
} from "./gameTabsvideodata"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ m: 1 }}>{children}</Box>}
    </div>
  )
}

function SecondCustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`second-tabpanel-${index}`}
      aria-labelledby={`second-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ m: 1 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

function secontabprops(index: number) {
  return {
    id: `second-tab-${index}`,
    "aria-controls": `second-tabpanel-${index}`,
  }
}

const tabStyle = (isActive: boolean) => ({
  backgroundColor: isActive ? "#5b5b5b" : "#272727",
  height: "36px",
  minWidth: "64px",
  borderRadius: "30px",
  padding: "0 16px",
  minHeight: "auto",
  margin: "0 5px", // Add margin for space between tabs
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ":hover": {
    border: "1px solid #000",
  },
})

const tabData = [
  {
    label: "Roulette",
    index: 0,
    imageUrl:
      "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/roulette.webp",
  },
  {
    label: "Baccarat",
    index: 1,
    imageUrl:
      "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/baccarat.webp",
  },
  {
    label: "Poker",
    index: 2,
    imageUrl:
      "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/pokertabimg.webp",
  },
  {
    label: "Blackjack",
    index: 3,
    imageUrl:
      "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/blackjack.webp",
  },
  {
    label: "Gameshow",
    index: 4,
    imageUrl:
      "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/gameshow.webp",
  },
]

const secondTabStyle = {
  fontSize: "12px",
  textTransform: "uppercase",
  lineHeight: "1.25rem",
  cursor: "pointer",
  padding: "0 16px",
  minHeight: "auto",
  height: "35px",
  borderRadius: "3px",
  minWidth: "90px",
  ":hover": {
    backgroundColor: "#161616",
  },
}

const GameTabs = () => {
  const [value, setValue] = React.useState(0)
  const [secondVal, setSecondVal] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleChildTabchange = (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setSecondVal(newValue)
  }

  return (
    <Box sx={{ backgroundColor: "#000" }}>
      <Container maxWidth="lg" disableGutters sx={{ padding: "17px 0" }}>
        <Box sx={{ width: "100%" }}>
          <Box>
            <Tabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                  rowGap: "5px",
                },
              }}
              // className="flex gap-y-1 flex-wrap"
              value={value}
              onChange={handleChange}
              aria-label="game tabs"
              TabIndicatorProps={{
                style: { display: "none" },
              }}
            >
              {tabData.map(tab => (
                <Tab
                  key={tab.index}
                  sx={tabStyle(value === tab.index)}
                  {...a11yProps(tab.index)}
                  label={
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                    >
                      <img
                        src={tab.imageUrl}
                        alt={tab.label}
                        width={24}
                        height={24}
                      />
                      {tab.label}
                    </Box>
                  }
                />
              ))}
            </Tabs>
          </Box>

          <SecondCustomTabPanel value={value} index={0}>
            <Box sx={{ width: "100%" }}>
              <Box className="secondtabparent">
                <Tabs
                  sx={{ minHeight: "auto" }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#f36c21",
                    },
                  }}
                  value={secondVal}
                  onChange={handleChildTabchange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    sx={secondTabStyle}
                    label={
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "12px",
                          color: "#9e9e9e",
                          textTransform: "uppercase",
                          fontWeigh: "700",
                        }}
                      >
                        about
                      </Typography>
                    }
                    {...secontabprops(0)}
                  />
                  <Tab
                    sx={secondTabStyle}
                    label={
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "12px",
                          color: "#9e9e9e",
                          textTransform: "uppercase",
                          fontWeigh: "700",
                        }}
                      >
                        tutorials
                      </Typography>
                    }
                    {...secontabprops(1)}
                  />
                </Tabs>
              </Box>
              <SecondCustomTabPanel value={secondVal} index={0}>
                <Box className="aboutdata" sx={{ p: 0 }}>
                  <Typography
                    component="h1"
                    sx={{
                      fontSize: "1.8rem",
                      fontWeight: "600",
                      textAlign: "left",
                      color: "#fff",
                    }}
                  >
                    Hurray, Roulette fanatics. Play online now and win real
                    money.
                  </Typography>
                  <Box className="roulette">
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: "20.8px",
                        color: "#fff",
                        fontWeight: "600",
                        mb: "10px",
                        textAlign: "left",
                      }}
                    >
                      Roulette:
                    </Typography>
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: ".85rem",
                        color: "#fff",
                        mb: "10px",
                        textAlign: "left",
                        paddingRight: "20px",
                      }}
                    >
                      {window.location.hostname.replace("www.", "").split(".")[0]} offers online casino games and it pretty
                      much announces what it intends to provide as soon as you
                      hear its name. A fair gameplay is something that we’re
                      here for, and this online platform certainly offers just
                      that. Here, games like online roulette for real money are
                      played and won. The platform also has people wanting to{" "}
                      <Link to={"#"} className="text-[#f36c21] underline">
                        play casino online
                      </Link>{" "}
                      all the time. Still wondering if you should or should not
                      try your luck? Take a look at our online live roulette or
                      casino roulette game and you’ll find no reason to refuse.
                      Sign up on {window.location.hostname.replace("www.", "").split(".")[0]} today and play now!
                    </Typography>
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: ".85rem",
                        color: "#fff",
                        mb: "10px",
                        textAlign: "left",
                        paddingRight: "20px",
                      }}
                    >
                      The objective in roulette is to predict the number on
                      which the ball will land on the wheel. The wheel consists
                      of numbers 0-36. We offer exciting variaitons of the game
                      such as Double ball roulette, American Roulette, Immersive
                      roulette etc. Best experienced on{" "}
                      <Link to={"#"} className="text-[#f36c21] underline">
                        {window.location.hostname.replace("www.", "")}
                      </Link>{" "}
                      , powered by Evolution casino.
                    </Typography>
                  </Box>
                </Box>
              </SecondCustomTabPanel>
              <CustomTabPanel value={secondVal} index={1}>
                <Box className="videocontainer">
                  <Container
                    maxWidth="xl"
                    sx={{ padding: { md: "", xs: "0" } }}
                  >
                    {videodata?.map((video, idx) => (
                      <Box
                        key={video.label + idx}
                        className="casinovideolist"
                        sx={{
                          width: { md: "50%", xs: "100%" },
                          margin: { md: "0 auto", xs: "0 auto" },
                        }}
                      >
                        <Typography
                          component="h4"
                          sx={{
                            margin: "10px 0",
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                            wordBreak: "break-all",
                            fontSize: "1.25rem",
                            letterSpacing: ".0125em",
                            lineHeight: "2rem",
                            flexWrap: "wrap",
                            fontWeigh: "400",
                          }}
                        >
                          {video.label}
                        </Typography>
                        <Box
                          className="tutorials-video"
                          sx={{
                            position: "relative",
                            paddingBottom: "56.25%",
                            height: 0,
                            marginBottom: "12px",
                          }}
                        >
                          <iframe
                            src={video.iframsrc}
                            className="border-0 h-[100%] w-[100%] top-0 left-0 absolute"
                            title="video"
                            allowFullScreen
                          ></iframe>
                        </Box>
                      </Box>
                    ))}
                  </Container>
                </Box>
              </CustomTabPanel>
            </Box>
          </SecondCustomTabPanel>

          <SecondCustomTabPanel value={value} index={1}>
            <Box sx={{ width: "100%" }}>
              <Box className="secondtabparent">
                <Tabs
                  sx={{ minHeight: "auto" }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#f36c21",
                    },
                  }}
                  value={secondVal}
                  onChange={handleChildTabchange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    sx={secondTabStyle}
                    label={
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "12px",
                          color: "#9e9e9e",
                          textTransform: "uppercase",
                          fontWeigh: "700",
                        }}
                      >
                        about
                      </Typography>
                    }
                    {...secontabprops(0)}
                  />
                  <Tab
                    sx={secondTabStyle}
                    label={
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "12px",
                          color: "#9e9e9e",
                          textTransform: "uppercase",
                          fontWeigh: "700",
                        }}
                      >
                        tutorials
                      </Typography>
                    }
                    {...secontabprops(1)}
                  />
                </Tabs>
              </Box>
              <SecondCustomTabPanel value={secondVal} index={0}>
                <Box className="aboutdata" sx={{ p: 0 }}>
                  <Typography
                    component="h1"
                    sx={{
                      fontSize: "1.8rem",
                      fontWeight: "600",
                      textAlign: "left",
                      color: "#fff",
                    }}
                  >
                    Baccarat:
                  </Typography>
                  <Box className="roulette">
                    <Typography
                      component="p"
                      sx={{
                        fontSize: ".85rem",
                        color: "#fff",
                        mb: "10px",
                        textAlign: "left",
                        paddingRight: "20px",
                      }}
                    >
                      Looking to play casino online? Come, sign up on
                      {window.location.hostname.replace("www.", "").split(".")[0]}. We’ve got all the games you’ve been
                      looking for. Baccarat card game,{" "}
                      <Link to={"#"} className="text-[#f36c21] underline">
                        BlackjackPokerRoulette
                      </Link>{" "}
                      , and much more. Play the Baccarat game online and stand a
                      chance to win big. Your money is safe with us and we offer
                      daily exchanges too. Sign up on {window.location.hostname.replace("www.", "").split(".")[0]} today,
                      the online Baccarat Game awaits you!
                    </Typography>
                    <Typography
                      component="p"
                      sx={{
                        fontSize: ".85rem",
                        color: "#fff",
                        mb: "10px",
                        textAlign: "left",
                        paddingRight: "20px",
                      }}
                    >
                      The objective of Baccarat is to predict which side-
                      "Player" or "Banker" will win by having the value closest
                      to 9. We offer exciting variations of the game such as
                      Control Squeeze, Lightening Baccarat etc. Best experienced
                      on{" "}
                      <Link to={"#"} className="text-[#f36c21] underline">
                        {window.location.hostname.replace("www.", "").split(".")[0]}.co.in
                      </Link>{" "}
                      , powered by Evolution casino.
                    </Typography>
                  </Box>
                </Box>
              </SecondCustomTabPanel>
              <CustomTabPanel value={secondVal} index={1}>
                <Box className="videocontainer">
                  <Container
                    maxWidth="xl"
                    sx={{ padding: { md: "", xs: "0" } }}
                  >
                    {baccaratvideodata?.map((video, idx) => (
                      <Box
                        key={video.label + idx}
                        className="casinovideolist"
                        sx={{
                          width: { md: "50%", xs: "100%" },
                          margin: { md: "0 auto", xs: "0 auto" },
                        }}
                      >
                        <Typography
                          component="h4"
                          sx={{
                            margin: "10px 0",
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                            wordBreak: "break-all",
                            fontSize: "1.25rem",
                            letterSpacing: ".0125em",
                            lineHeight: "2rem",
                            flexWrap: "wrap",
                            fontWeigh: "400",
                          }}
                        >
                          {video.label}
                        </Typography>
                        <Box
                          className="tutorials-video"
                          sx={{
                            position: "relative",
                            paddingBottom: "56.25%",
                            height: 0,
                            marginBottom: "12px",
                          }}
                        >
                          <iframe
                            src={video.iframsrc}
                            className="border-0 h-[100%] w-[100%] top-0 left-0 absolute"
                            title="video"
                            allowFullScreen
                          ></iframe>
                        </Box>
                      </Box>
                    ))}
                  </Container>
                </Box>
              </CustomTabPanel>
            </Box>
          </SecondCustomTabPanel>

          <SecondCustomTabPanel value={value} index={2}>
            <Box sx={{ width: "100%" }}>
              <Box className="secondtabparent">
                <Tabs
                  sx={{ minHeight: "auto" }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#f36c21",
                    },
                  }}
                  value={secondVal}
                  onChange={handleChildTabchange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    sx={secondTabStyle}
                    label={
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "12px",
                          color: "#9e9e9e",
                          textTransform: "uppercase",
                          fontWeigh: "700",
                        }}
                      >
                        about
                      </Typography>
                    }
                    {...secontabprops(0)}
                  />
                  <Tab
                    sx={secondTabStyle}
                    label={
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "12px",
                          color: "#9e9e9e",
                          textTransform: "uppercase",
                          fontWeigh: "700",
                        }}
                      >
                        tutorials
                      </Typography>
                    }
                    {...secontabprops(1)}
                  />
                </Tabs>
              </Box>
              <SecondCustomTabPanel value={secondVal} index={0}>
                <Box className="aboutdata" sx={{ p: 0 }}>
                  <Typography
                    component="h1"
                    sx={{
                      fontSize: "1.8rem",
                      fontWeight: "600",
                      textAlign: "left",
                      color: "#fff",
                    }}
                  >
                    Poker:
                  </Typography>
                  <Box className="roulette">
                    <Typography
                      component="p"
                      sx={{
                        fontSize: ".85rem",
                        color: "#fff",
                        mb: "10px",
                        textAlign: "left",
                        paddingRight: "20px",
                      }}
                    >
                      The objective in Casino poker is to beat the dealers hand
                      by getting the best possible five card hand, made out of
                      two cards dealt to the player and the community cards. We
                      offer exciting variaitons of the game such as Casino
                      Hold'em, Carribean Stud poker, Triple card poker, Extreme
                      Texas Hold'em Poker. Best experienced on{" "}
                      <Link to={"#"} className="text-[#f36c21] underline">
                        {window.location.hostname.replace("www.", "")}
                      </Link>{" "}
                    </Typography>
                  </Box>
                </Box>
              </SecondCustomTabPanel>
              <CustomTabPanel value={secondVal} index={1}>
                <Box className="videocontainer">
                  <Container
                    maxWidth="xl"
                    sx={{ padding: { md: "", xs: "0" } }}
                  >
                    {poker?.map((video, idx) => (
                      <Box
                        key={video.label + idx}
                        className="casinovideolist"
                        sx={{
                          width: { md: "50%", xs: "100%" },
                          margin: { md: "0 auto", xs: "0 auto" },
                        }}
                      >
                        <Typography
                          component="h4"
                          sx={{
                            margin: "10px 0",
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                            wordBreak: "break-all",
                            fontSize: "1.25rem",
                            letterSpacing: ".0125em",
                            lineHeight: "2rem",
                            flexWrap: "wrap",
                            fontWeigh: "400",
                          }}
                        >
                          {video.label}
                        </Typography>
                        <Box
                          className="tutorials-video"
                          sx={{
                            position: "relative",
                            paddingBottom: "56.25%",
                            height: 0,
                            marginBottom: "12px",
                          }}
                        >
                          <iframe
                            src={video.iframsrc}
                            className="border-0 h-[100%] w-[100%] top-0 left-0 absolute"
                            title="video"
                            allowFullScreen
                          ></iframe>
                        </Box>
                      </Box>
                    ))}
                  </Container>
                </Box>
              </CustomTabPanel>
            </Box>
          </SecondCustomTabPanel>
          <SecondCustomTabPanel value={value} index={3}>
            <Box sx={{ width: "100%" }}>
              <Box className="secondtabparent">
                <Tabs
                  sx={{ minHeight: "auto" }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#f36c21",
                    },
                  }}
                  value={secondVal}
                  onChange={handleChildTabchange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    sx={secondTabStyle}
                    label={
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "12px",
                          color: "#9e9e9e",
                          textTransform: "uppercase",
                          fontWeigh: "700",
                        }}
                      >
                        about
                      </Typography>
                    }
                    {...secontabprops(0)}
                  />
                  <Tab
                    sx={secondTabStyle}
                    label={
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "12px",
                          color: "#9e9e9e",
                          textTransform: "uppercase",
                          fontWeigh: "700",
                        }}
                      >
                        tutorials
                      </Typography>
                    }
                    {...secontabprops(1)}
                  />
                </Tabs>
              </Box>
              <SecondCustomTabPanel value={secondVal} index={0}>
                <Box className="aboutdata" sx={{ p: 0 }}>
                  <Typography
                    component="h1"
                    sx={{
                      fontSize: "1.8rem",
                      fontWeight: "600",
                      textAlign: "left",
                      color: "#fff",
                    }}
                  >
                    Blackjack:
                  </Typography>
                  <Box className="roulette">
                    <Typography
                      component="p"
                      sx={{
                        fontSize: ".85rem",
                        color: "#fff",
                        mb: "10px",
                        textAlign: "left",
                        paddingRight: "20px",
                      }}
                    >
                      Do you love playing Blackjack? Sign up on {window.location.hostname.replace("www.", "").split(".")[0]}
                      and start playing from a wide variety of games. Free Bet
                      Blackjack, Infinite Blackjack, Power Blackjack, and Speed
                      Blackjack are all available on our website. You can also
                      try your luck at sports betting and{" "}
                      <Link to={"#"} className="text-[#f36c21] underline">
                        Indian card games
                      </Link>{" "}
                      . Sign up on {window.location.hostname.replace("www.", "").split(".")[0]} today.
                    </Typography>
                    <Typography
                      component="p"
                      sx={{
                        fontSize: ".85rem",
                        color: "#fff",
                        mb: "10px",
                        textAlign: "left",
                        paddingRight: "20px",
                      }}
                    >
                      The objective of Blackjack is to achieve a higher card
                      count than the dealer, but without going over 21. We offer
                      exciting variations of the game such as Free Bet
                      Blackjack, Infinite Blackjack, Power Blackjack, Speed
                      Blackjack and regular tables. Best experienced on
                      <Link to={"#"} className="text-[#f36c21] underline">
                        {window.location.hostname.replace("www.", "")}
                      </Link>{" "}
                      , powered by Evolution casino.
                    </Typography>
                  </Box>
                </Box>
              </SecondCustomTabPanel>
              <CustomTabPanel value={secondVal} index={1}>
                <Box className="videocontainer">
                  <Container
                    maxWidth="xl"
                    sx={{ padding: { md: "", xs: "0" } }}
                  >
                    {blackjack?.map((video, idx) => (
                      <Box
                        key={video.label + idx}
                        className="casinovideolist"
                        sx={{
                          width: { md: "50%", xs: "100%" },
                          margin: { md: "0 auto", xs: "0 auto" },
                        }}
                      >
                        <Typography
                          component="h4"
                          sx={{
                            margin: "10px 0",
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                            wordBreak: "break-all",
                            fontSize: "1.25rem",
                            letterSpacing: ".0125em",
                            lineHeight: "2rem",
                            flexWrap: "wrap",
                            fontWeigh: "400",
                          }}
                        >
                          {video.label}
                        </Typography>
                        <Box
                          className="tutorials-video"
                          sx={{
                            position: "relative",
                            paddingBottom: "56.25%",
                            height: 0,
                            marginBottom: "12px",
                          }}
                        >
                          <iframe
                            src={video.iframsrc}
                            className="border-0 h-[100%] w-[100%] top-0 left-0 absolute"
                            title="video"
                            allowFullScreen
                          ></iframe>
                        </Box>
                      </Box>
                    ))}
                  </Container>
                </Box>
              </CustomTabPanel>
            </Box>
          </SecondCustomTabPanel>
          <SecondCustomTabPanel value={value} index={4}>
            <Box sx={{ width: "100%" }}>
              <Box className="secondtabparent">
                <Tabs
                  sx={{ minHeight: "auto" }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#f36c21",
                    },
                  }}
                  value={secondVal}
                  onChange={handleChildTabchange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    sx={secondTabStyle}
                    label={
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "12px",
                          color: "#9e9e9e",
                          textTransform: "uppercase",
                          fontWeigh: "700",
                        }}
                      >
                        about
                      </Typography>
                    }
                    {...secontabprops(0)}
                  />
                  <Tab
                    sx={secondTabStyle}
                    label={
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "12px",
                          color: "#9e9e9e",
                          textTransform: "uppercase",
                          fontWeigh: "700",
                        }}
                      >
                        tutorials
                      </Typography>
                    }
                    {...secontabprops(1)}
                  />
                </Tabs>
              </Box>
              <SecondCustomTabPanel value={secondVal} index={0}>
                <Box className="aboutdata" sx={{ p: 0 }}>
                  <Typography
                    component="h1"
                    sx={{
                      fontSize: "1.8rem",
                      fontWeight: "600",
                      textAlign: "left",
                      color: "#fff",
                    }}
                  >
                    Game Shows:
                  </Typography>
                  <Box className="roulette">
                    <Typography
                      component="p"
                      sx={{
                        fontSize: ".85rem",
                        color: "#fff",
                        mb: "10px",
                        textAlign: "left",
                        paddingRight: "20px",
                      }}
                    >
                      Enjoy playing online gambling games? You’re in luck!
                      {window.location.hostname.replace("www.", "").split(".")[0]} offers a wide range of online games.
                      <Link to={"#"} className="text-[#f36c21] underline">
                        Casino
                      </Link>
                      , card games, and sport bets, we have everything you need.
                      <Link to={"#"} className="text-[#f36c21] underline">
                        BaccaratRoulette
                      </Link>{" "}
                      , Lightening Dice, Dreamcatcher,{" "}
                      <Link to={"#"}>Cricket</Link> , Football, Teen Patti, Deal
                      or No Deal, and many more exciting games. To play and win
                      cash, sign up on {window.location.hostname.replace("www.", "").split(".")[0]} today.
                      <Link to={"#"} className="text-[#f36c21] underline">
                        Indian card games
                      </Link>
                      . Sign up on {window.location.hostname.replace("www.", "").split(".")[0]} today.
                    </Typography>
                    <Typography
                      component="p"
                      sx={{
                        fontSize: ".85rem",
                        color: "#fff",
                        mb: "10px",
                        textAlign: "left",
                        paddingRight: "20px",
                      }}
                    >
                      Game shows are light shows with active dealers. We offer
                      exciting variations of the game such as Lightening Dice,
                      Dreamcatcher, Deal or No Deal. Best experienced on
                      <Link to={"#"} className="text-[#f36c21] underline">
                        {window.location.hostname.replace("www.", "")}
                      </Link>{" "}
                      , powered by Evolution casino.
                    </Typography>
                  </Box>
                </Box>
              </SecondCustomTabPanel>
              <CustomTabPanel value={secondVal} index={1}>
                <Box className="videocontainer">
                  <Container
                    maxWidth="xl"
                    sx={{ padding: { md: "", xs: "0" } }}
                  >
                    {gameshow?.map((video, idx) => (
                      <Box
                        key={video.label + idx}
                        className="casinovideolist"
                        sx={{
                          width: { md: "50%", xs: "100%" },
                          margin: { md: "0 auto", xs: "0 auto" },
                        }}
                      >
                        <Typography
                          component="h4"
                          sx={{
                            margin: "10px 0",
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                            wordBreak: "break-all",
                            fontSize: "1.25rem",
                            letterSpacing: ".0125em",
                            lineHeight: "2rem",
                            flexWrap: "wrap",
                            fontWeigh: "400",
                          }}
                        >
                          {video.label}
                        </Typography>
                        <Box
                          className="tutorials-video"
                          sx={{
                            position: "relative",
                            paddingBottom: "56.25%",
                            height: 0,
                            marginBottom: "12px",
                          }}
                        >
                          <iframe
                            src={video.iframsrc}
                            className="border-0 h-[100%] w-[100%] top-0 left-0 absolute"
                            title="video"
                            allowFullScreen
                          ></iframe>
                        </Box>
                      </Box>
                    ))}
                  </Container>
                </Box>
              </CustomTabPanel>
            </Box>
          </SecondCustomTabPanel>
        </Box>
      </Container>
    </Box>
  )
}

export default GameTabs
