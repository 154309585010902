import { Box } from "@mui/material"
import SecondHeadingBar from "../../../common/SecondHeadingBar"
import SearchBar from "../../../common/openbetsmain/OpenBetsMainComp"

const style = {
    backgroundColor: "#f36c21",
}

const Exposure = () => {
    return (
        <Box className="betspageparent">
            <Box sx={style}>
                <SecondHeadingBar title="Exposure" />
            </Box>
            <Box className="gametypesearch">
                <SearchBar exp={true}/>
            </Box>
        </Box>
    )
}

export default Exposure
