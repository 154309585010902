import { Dialog, DialogContent, DialogProps } from "@mui/material"
import React, { Dispatch, FC, SetStateAction } from "react"

interface MoreModalProps {
  children: React.ReactNode
  open: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
  scroll: "body" | "paper" | undefined
}

const MoreModal: FC<MoreModalProps> = ({
  children,
  open,
  setModalOpen,
  scroll,
}) => {
  const handleModalClose = () => {
    setModalOpen(!open)
  }
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleModalClose}
      scroll={scroll}
      sx={{
        height: "100lvh",
        position: "fixed",
        width: "calc(100% - 10px)",
        margin: "0 auto",
        bottom: 0,
        top: "20% !important",
        background: "#000",
        "& .MuiPaper-root": {
          width: "100%",
          height: "100%",
          p: 1,
          m: 0,
        },
      }}
    >
      {children}
    </Dialog>
  )
}

export default MoreModal
