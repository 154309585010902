import { FC } from "react"
import "./headingbar.css"
import { Box, Button, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router"
import { ChevronLeft } from "@mui/icons-material"

interface HeadingBarProps {
  title: string
}

const HeadingBar: FC<HeadingBarProps> = ({ title }) => {
  const nav = useNavigate()

  return (
    <Box className="headingbarparent">
      <Typography
        component="p"
        sx={{
          display: "flex",
          fontSize: "16px",
          textAlign: "left",
          color: "#fff",
          textTransform: "capitalize",
          fontWeight: "bold",
        }}
      >
        <Button
          onClick={() => nav(-1)}
          disableRipple
          disableTouchRipple
          sx={{
            minWidth: 0,
            borderRadius: "50%",
            backgroundColor: "transparent",
            cursor: "default",
            ":hover": {
              backgroundColor: "#0000008b",
            },
          }}
        >
          <ChevronLeft sx={{ color: "#f36c21" }} />
        </Button>
        {title}
      </Typography>
    </Box>
  )
}

export default HeadingBar
