type sportsstaticdataType = {
    name: string
}[]


export const sportsstaticdata: sportsstaticdataType = [
    {
        name: 'Andhra Premier League'
    },
    {
        name: 'ICC Men\'s T20 WC Europe Qualifier'
    },
    {
        name: 'Tamil Nadu Premier League'
    },
    {
        name: 'World Championship of Legends T20'
    },
    {
        name: 'Major League Cricket'
    },
    {
        name: 'Lanka Premier League'
    },
    {
        name: 'Womens International Twenty20 Matches'
    },
    {
        name: 'Test Matches'
    },
    {
        name: 'International Twenty20 Matches'
    },
    {
        name: 'T20 Blast'
    }
]