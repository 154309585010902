// import tennisoddsimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tennisabtmarket/tennismatchodds.webp";



type tennisDataType = {
    heading?: string
    data: subdata
}[]

type subdata = {
    subheading?: string
    titleone: string,
    titletwo?: string,
    img: string
}

export const tennisdata: tennisDataType = [
    {
        heading: 'Match Odds:',
        data: {
            titleone: 'Back - Placing a back bet simply means you are staking money on the team to win.',
            titletwo: 'Lay - Placing a lay bet simply means you are staking money on the team to NOT win.',
            img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tennisabtmarket/tennismatchodds.webp'
        }
    },


]

