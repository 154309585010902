import { Box, Container, Grid, List, ListItem, Typography } from "@mui/material"
import worlimatka from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/worli-matka.webp"

const listyle = {
  padding: 0,
  fontSize: "14px",
  color: "#fff",
}

const WorliMatka = () => {
  return (
    <Box className="worlimatka">
      <Container maxWidth="lg" sx={{ py: "10px", m: "17px 59px" }}>
        <Grid container columnSpacing={{ xs: 2 }}>
          <Grid item xs={4}>
            <Typography
              component="h1"
              sx={{
                fontSize: "32px",
                color: "#f36c21",
                fontFamily: "Lato,sans-serif",
                fontWeight: "700",
                paddingBottom: "8px",
                textAlign: "left",
              }}
            >
              Worli Matka
            </Typography>

            <div className="teenpattiimg max-w-[420px] mb-[27px]">
              <img
                src={
                  "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/worli-matka.webp"
                }
                alt=""
                className="max-w-[100%] h-auto"
              />
            </div>
          </Grid>
          <Grid item xs={8}>
            <Box className="pana">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Pana Total:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  The Pana Total is expressed as A+B+C=X.
                </ListItem>
                <ListItem sx={listyle}>
                  If X is less than 9, X is as derived.
                </ListItem>
                <ListItem sx={listyle}>
                  If X is greater than 9, for example XY, Y is considered Pana
                  Total.
                </ListItem>
                <ListItem sx={listyle}>Example:</ListItem>
                <ListItem sx={listyle}>
                  170 = 1+7+0 = 8 (8 Is the Pana Total)
                </ListItem>
                <ListItem sx={listyle}>
                  179 = 1+7+9 = 17(Here, since, 17 is greater than 9 hence 7 is
                  considered Pana Total)
                </ListItem>
              </List>
            </Box>
            <Box className="oddeven">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Odd - Even:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  Odd Pana is considered when, Pana Total is Odd.
                </ListItem>
                <ListItem sx={listyle}>
                  Even Pana is considered when, Pana Total is Even.
                </ListItem>
              </List>
            </Box>
            <Box className="hilow">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Hi - Low:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  Hi Pana is considered when, Pana Total is 6, 7, 8, 9 or 0.
                </ListItem>
                <ListItem sx={listyle}>
                  Low Pana is considered when, Pana Total is 1, 2, 3, 4 or 5.
                </ListItem>
              </List>
            </Box>
            <Box className="singlepana">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Single Pana:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  Each Single Pana has 12 combinations, and winner Pana is Pana
                  Total.
                </ListItem>
                <ListItem sx={listyle}>
                  Single Pana is always in X &lt; Y &gt; Z format and all three
                  numbers of Pana are unique.
                </ListItem>
                <ListItem sx={listyle}>
                  Rate Paid for Single Pana : 1:140
                </ListItem>
              </List>
            </Box>
            <Box className="dobulepana">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Double Pana:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  Each Double Pana has 9 combinations.
                </ListItem>
                <ListItem sx={listyle}>
                  Double Pana is always in X = Y &lt; Z or X &lt; Y = Z format
                  and pana has a repeat digit.
                </ListItem>
                <ListItem sx={listyle}>
                  Rate Paid for Double Pana : 1:240
                </ListItem>
              </List>
            </Box>
            <Box className="alltriopana">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                All Trio Pana:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  All Trio Pana is considered when, all three numbers of Pana
                  are same.
                </ListItem>
                <ListItem sx={listyle}>
                  Rate Paid for All Trio Pana : 1:700
                </ListItem>
              </List>
            </Box>
            <Box className="spall">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                SPALL:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  SPALL is considered winner, when any Single Pana is winner.
                </ListItem>
                <ListItem sx={listyle}>Rate Paid for SPALL : 1:140</ListItem>
              </List>
            </Box>
            <Box className="dpall">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                DPALL:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  DPALL is considered winner, when any Double Pana is winner.
                </ListItem>
                <ListItem sx={listyle}>Rate Paid for DPALL : 1:240</ListItem>
              </List>
            </Box>
            <Box className="commonsp">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Common SP:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  Common SP is considered winner, when Common SP Pana exists in
                  Single Pana numbers.
                </ListItem>
                <ListItem sx={listyle}>
                  Rate Paid for Common SP : 1:140
                </ListItem>
              </List>
            </Box>
            <Box className="commondp">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Common DP:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  Common DP is considered winner, when Common DP Pana exists in
                  Double Pana numbers.
                </ListItem>
                <ListItem sx={listyle}>
                  Rate Paid for Common DP : 1:240
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default WorliMatka
