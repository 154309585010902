import { Link, Outlet, useNavigate } from "react-router-dom"
import "./sportsbar.css"
import { sportsbardata } from "./sportsbardata"
import { useEffect, useState } from "react"
import { Box, Button, useMediaQuery } from "@mui/material"
import SportsBarMobile from "./SportsBarMobile"
import { useTranslation } from "react-i18next"
import { useLeftMenuListMutation } from "../api/mainApi/mainApiSlice"
import sportIcon from "../utils/sporticon"
// import inplay from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/inplay.webp"
import { useQtechAuthenticationQuery } from "../api/qtech/qtechApiSlice"
import { useDispatch } from "react-redux"
import { setState } from "../features/casino/casinoslice"

const SportsBar = () => {
  const token = localStorage.getItem("token")
  const isMobile = useMediaQuery("(max-width:991px)")
  const [active, setActive] = useState(0)
  const [staticActive, setStaticActive] = useState(0)
  // const [activeCasino, setActiveCasino] = useState("all")
  const nav = useNavigate()
  const [leftMenuTrigger, { data: leftMenuData }] = useLeftMenuListMutation()
  const { t } = useTranslation()

  useEffect(() => {
    leftMenuTrigger({})
  }, [leftMenuTrigger])

  const { data: authData } = useQtechAuthenticationQuery(
    {},
    { pollingInterval: 3600000, skip: !token },
  )

  useEffect(() => {
    if (authData) {
      localStorage.setItem("authToken", authData?.data?.access_token.toString())
    }
  }, [authData])

  const handleActive = (sport: any, index: number) => {
    setStaticActive(index)
    nav(`${sport?.link}`, {
      state: {
        sport: sport.name,

        casinoName:
          sport.name === "crash games"
            ? "all".toUpperCase()
            : sport.name === "slot games"
              ? "slot".toUpperCase()
              : sport.name === "live card"
                ? "lottery".toUpperCase()
                : sport.name.replace(/\s+/g, "").toUpperCase(),
      },
    })
  }

  const handleLeftMenuNav = (
    sportName: string,
    id: number | string,
    index: number,
  ) => {
    setActive(index)

    let sName = sportName.split(" ").join("")
    if (id === "77" || id === 77) {
      nav(`/racing-category/HORSE_RACE/${id}`)
    } else {
      nav(`/sports/${sName}/${id}`)
    }
  }

  return (
    <>
      {!isMobile && (
        <Box
          className="sportbar"
          sx={{ overflowX: "auto", overflowY: "hidden", width: "100%" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "inherit",
              gap: 1,
            }}
          >
            <Button
              centerRipple
              disableRipple
              disableTouchRipple
              variant="text"
              onClick={() => nav("/")}
              sx={{
                borderRadius: "100px",
                transformStyle: "preserve-3d",
                transition: ".6s",
                display: "inline-block",
                padding: "3px 10px",
                textAlign: "center",
                minWidth: "unset",
                width: "100%",
                ":hover": {
                  background: "none",
                },
              }}
              className="sportsbarbtn"
            >
              <img
                src={
                  "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/inplay.webp"
                }
                alt=""
                className="h-5 w-5 m-auto"
              />
              <span className="text-white text-[11px] font-[Roboto, sans-serif] mt-2 uppercase group-hover:transition ease delay-0 group-hover:text-orange-400 whitespace-nowrap">
                {t("inplay")}
              </span>
            </Button>
            {leftMenuData?.data?.map((sports, index) => (
              <Button
                centerRipple
                tabIndex={index}
                disableRipple
                disableTouchRipple
                variant="text"
                key={sports?.sportName + sports?.sportId}
                onClick={() =>
                  handleLeftMenuNav(
                    sports?.sportName,
                    sports?.sportId as any,
                    index,
                  )
                }
                sx={{
                  borderRadius: "100px",
                  boxShadow: `${active === index ? "inset 0 8px 26px -23px hsla(0,0%,100%,.75)" : ""}`,
                  border: `${active === index ? "1px solid #f36c21" : "1px solid transparent"}`,
                  transformStyle: "preserve-3d",
                  transition: ".6s",
                  display: "inline-block",
                  padding: "3px 20px",
                  textAlign: "center",
                  minWidth: "unset",
                  width: "100%",
                  ":hover": {
                    background: "none",
                  },
                }}
                className="sportsbarbtn"
              >
                <img
                  src={sportIcon(sports?.sportName)}
                  alt=""
                  className={`h-5 w-5 m-auto ${sports?.sportName === "Kabaddi" || sports?.sportName === "Election" ? "filter invert" : ""}`}
                />
                <span className="text-white text-[11px] font-[Roboto, sans-serif] mt-2 uppercase group-hover:transition ease delay-0 group-hover:text-orange-400 whitespace-nowrap">
                  {t(sports.sportName)}
                </span>
              </Button>
            ))}
            {sportsbardata?.map((staticdata, index) => (
              <Button
                centerRipple
                tabIndex={index}
                disableRipple
                disableTouchRipple
                variant="text"
                key={staticdata?.name + index}
                onClick={() => handleActive(staticdata, index)}
                sx={{
                  borderRadius: "100px",
                  boxShadow: `${staticActive === index ? "inset 0 8px 26px -23px hsla(0,0%,100%,.75)" : ""}`,
                  border: `${staticActive === index ? "1px solid #f36c21" : "1px solid transparent"}`,
                  transformStyle: "preserve-3d",
                  transition: ".6s",
                  display: "inline-block",
                  padding: "3px 20px",
                  textAlign: "center",
                  minWidth: "unset",
                  width: "100%",
                  ":hover": {
                    background: "none",
                  },
                }}
                className="sportsbarbtn"
              >
                <img
                  src={sportIcon(staticdata?.name)}
                  alt=""
                  className="h-5 w-5 m-auto"
                />
                <span className="text-white text-[11px] font-[Roboto, sans-serif] mt-2 uppercase group-hover:transition ease delay-0 group-hover:text-orange-400 whitespace-nowrap">
                  {t(staticdata.name)}
                </span>
              </Button>
            ))}
          </Box>
        </Box>
      )}
      {isMobile && <SportsBarMobile />}
      <Outlet />
    </>
  )
}

export default SportsBar
