// import fb from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/socialmedia/fb.webp'
// import insta from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/socialmedia/instagram.webp'
// import linkedin from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/socialmedia/linkedin.webp'
// import telegram from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/socialmedia/telegram.webp'
// import yt from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/socialmedia/youtube.webp'
// import twitter from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/socialmedia/twitter.webp'
// import wa from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/socialmedia/wa.webp'


type socialMediaDataType = {
    img: string,
    url: string
}[]

export const socialmediadata: socialMediaDataType = [
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/socialmedia/fb.webp',
        url: ''
    },
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/socialmedia/twitter.webp',
        url: ''
    },
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/socialmedia/telegram.webp',
        url: ''
    },
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/socialmedia/instagram.webp',
        url: ''
    },
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/socialmedia/youtube.webp',
        url: ''
    },
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/socialmedia/linkedin.webp',
        url: ''
    },
    {
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/socialmedia/wa.webp',
        url: ''
    },
]