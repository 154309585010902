// import horse from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/horse.webp"
// import cricket from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/cricket.webp"
// import soccer from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/soccer.webp"
// import tennis from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tennisball.webp"
// import greyhound from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/greyhoundimg.webp"
// import kabaddi from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/kabaddiimg.png"
// import fantasy from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/fantasypro.webp"
// import election from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/election.png"
// import sportbook from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/sportsbookmobile.webp'
// import livecasinoimg from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecasinomf.webp'
// import crashimg from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/crashmf.webp'
// import livecardimg from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardsmf.webp'
// import slotgameimg from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/slotgamesmf.webp'
// import matkaimg from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/matka.webp'


const sportIcon = (sportName: string) => {
    if (sportName === "Cricket") {
        return 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/cricket.webp'
    } else if (sportName === "Football") {
        return 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/soccer.webp'
    } else if (sportName === "Tennis") {
        return 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tennisball.webp'
    } else if (sportName === "Horse racing") {
        return 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/horse.webp'
    } else if (sportName === "Kabaddi") {
        return 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/kabaddiimg.png'
    } else if (sportName === "fantasy pro") {
        return 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/fantasypro.webp'
    } else if (sportName === 'Election') {
        return 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/election.png'
    } else if (sportName === 'sportbook') {
        return 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/sportsbookmobile.webp'
    } else if (sportName === 'live casino') {
        return 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecasinomf.webp'
    } else if (sportName === 'crash games') {
        return 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/crashmf.webp'
    } else if (sportName === 'live card') {
        return 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecasinomf.webp'
    } else if (sportName === 'slot games') {
        return 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/slotgamesmf.webp'
    } else if (sportName === 'matka') {
        return 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/matka.webp'
    }

    else if (
        sportName === "greyhound racing" ||
        "GreyHound racing" ||
        "greyHound racing" ||
        "greyhoundracing"
    ) {
        return 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/greyhoundimg.webp'
    }
}

export default sportIcon 