import { Box } from "@mui/material"
import React, { FC } from "react"

interface InPlayBetLiveParentProps {
  children: React.ReactNode
}

const InPlayBetLiveParent: FC<InPlayBetLiveParentProps> = ({ children }) => {
  return (
    <Box
      className="inplay-bet-detail-sec"
      sx={{
        padding: { md: "25px 15px", xs: "0" },
        background: "linear-gradient(180deg,#1f1f1f 35%,#000)",
        backgroundColor: "#1f1f1f",
        borderRadius: "1rem",
        ":hover": {
          boxShadow:
            "0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12)",
        },
      }}
    >
      {/* sports match card */}
      {children}
      {/* sports match card */}
    </Box>
  )
}

export default InPlayBetLiveParent
