import { Box, Typography } from "@mui/material"
import { IoWarning } from "react-icons/io5"

const NotUnmatchedBets = () => {
  return (
    <Box className="notunmatchedbets">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IoWarning fontSize={100} color="#f36c21" />
      </Box>

      <Typography
        sx={{ fontSize: "12px", color: "#f36c21", textAlign: "center" }}
      >
        No Unmatched Bets!
      </Typography>
    </Box>
  )
}

export default NotUnmatchedBets
