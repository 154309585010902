import { Box, Typography } from "@mui/material"
// import btc from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/btc.webp"
// import eth from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/eth.webp"
// import upi from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/upi.webp"

type dummydata = {
  url: string
}[]

const dummydata: dummydata = [
  {
    url: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/btc.webp",
  },
  {
    url: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/eth.webp",
  },
  {
    url: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/upi.webp",
  },
  {
    url: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/btc.webp",
  },
  {
    url: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/eth.webp",
  },
  {
    url: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/upi.webp",
  },
  {
    url: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/btc.webp",
  },
  {
    url: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/eth.webp",
  },
  {
    url: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/upi.webp",
  },
  {
    url: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/upi.webp",
  },
]

const PaymentMethodComp = () => {
  return (
    <Box sx={{ padding: "20px 0px" }}>
      <Typography
        component="h3"
        sx={{
          margin: "10px",
          color: "#fff",
          fontWeight: "700",
          textTransform: "uppercase",
          fontSize: "36px",
          lineHeight: "60px",
          textAlign: "center",
        }}
      >
        PAYMENT METHODS
      </Typography>
      <Box
        className="paymentmethodcompparent"
        sx={{
          display: "flex",
          gap: "5px",
          justifyContent: "center",
          flexWrap: { md: "nowrap", xs: "wrap" },
        }}
      >
        {dummydata?.map((imgurl, index) => (
          <Box
            key={imgurl.url + index}
            sx={{
              p: "5px",
              minHeight: { xs: "30px", md: "unset" },
              width: { md: "150px", xs: "50px" },
              height: { md: "110px", xs: "32px" },
              borderRadius: "5px",
              boxShadow:
                "0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)",
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={imgurl.url}
              className="w-[30px] lg:w-[90px] aspect-[1/2] lg:aspect-[3/2] object-contain"
              alt=""
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default PaymentMethodComp
