// import socceroddsimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/footabtmarket/soccer-odds.webp";
// import footballfancyimg from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/footabtmarket/footballfancy.webp'



type footballDataType = {
    heading?: string
    data: subdata
}[]

type subdata = {
    subheading?: string
    titleone: string,
    titletwo?: string,
    img: string
}

export const footballdata: footballDataType = [
    {
        heading: 'Match Odds:',
        data: {
            titleone: 'Back - Placing a back bet simply means you are staking money on the team to win.',
            titletwo: 'Lay - Placing a lay bet simply means you are staking money on the team to NOT win.',
            img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/footabtmarket/soccer-odds.webp'
        }
    },
    {
        heading: 'Fancy:',
        data: {
            subheading: 'Under/ Over xyz goals :',
            titleone: 'Back or lay over/ under 0.5, 1.5 upto 6.5 goals.',
            img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/footabtmarket/footballfancy.webp'
        }
    },

]

