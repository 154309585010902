/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material"

import {
  useGamelinkMutation,
  useQtechgamelistQuery,
  useQtechproviderQuery,
} from "../../api/qtech/qtechApiSlice"
import { useLocation } from "react-router"
import { useEffect, useState } from "react"
import NoData from "../../common/NoData"
import { isBrowser, isMobile } from "react-device-detect"
import CloseIcon from "@mui/icons-material/Close"
import { fantsyGameList } from "./FantsyList"
import { useGetoneuserbetresultQuery } from "../../api/mainApi/mainApiSlice"

const CrashCasinoGame = () => {
  const token = window.localStorage.getItem("token")
  const auth_Token = window.localStorage.getItem("authToken")
  const [iframeModalOpen, setIframeModalOpen] = useState(false)
  const [pleaseNoteModal, setPleaseNoteModal] = useState(false)
  const [gamelistPayload, setGameListPayload] = useState({
    gameCategory: "INSTANTWIN",
    provider: "SPB",
    token: auth_Token || "",
  })
  const location = useLocation()
  const { data: getOneUserData } = useGetoneuserbetresultQuery({
    appUrl: window.location.hostname.replace("www.", ""),
  })
  const { data: providerData } = useQtechproviderQuery(
    {
      gameType: location?.state?.casinoName,
    },
    { skip: !token },
  )

  const instantWinArr: any[] = []
  const extracting = (data: any, data2: any) => {
    data?.forEach((element: any) => {
      if (element?.id === 146) {
        instantWinArr.push(element)
      }
    }) || []

    data2?.forEach((element: any) => {
      if (element?.id === 138 || element?.id === 106 || element?.id === 139) {
        instantWinArr.push(element)
      }
    }) || []

    return instantWinArr
  }

  extracting(providerData?.data?.instantWin, providerData?.data?.slot)

  const {
    data: gamelistdata,
    isLoading,
    isFetching,
  } = useQtechgamelistQuery(gamelistPayload, { skip: !token })

  const [gamelinkTrigger, { data: gamelinkData, isLoading: loading }] =
    useGamelinkMutation()

  const handleGameListPayload = (payloaddata: any) => {
    setPleaseNoteModal(true);
    setGameListPayload({
      gameCategory: payloaddata?.gameType,
      provider: payloaddata?.providerId,
      token: auth_Token || "",
    })
  }

  const handleIframeModal = (gameId: string) => {
    gamelinkTrigger({
      birthDate: "1986-01-01",
      country: "IN",
      currency: "INR",
      device: `${(isMobile && "mobile") || (isBrowser && "desktop")}`,
      gameName: gameId,
      gender: "M",
      lang: "en_IN",
      mode: "real",
      playerId: "121212",
      returnUrl: window.location.origin,
      token: auth_Token || "",
      walletSessionId: token || "",
    })
  }

  useEffect(() => {
    if (gamelinkData?.status) {
      setPleaseNoteModal(true);
    }
  }, [gamelinkData])
  

  const TabButton = ({ casinodata }: any) => {
    return (
      <>
        <Button
          onClick={() => handleGameListPayload(casinodata)}
          variant="outlined"
          size="small"
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            minWidth: { md: "200px", xs: "100px" },
            textWrap: "nowrap",
            width: { md: "300px", xs: "49%" },
            padding: { md: ".6rem 0", xs: ".2rem 0" },
            backgroundColor: "#ffffff25",
            gap: 1,
          }}
        >
          <img src={casinodata?.image} alt="" className="h-[18px] w-auto" />
          <Typography component="p" sx={{ fontSize: { md: "15px", xs: "12px" } }}>
            {casinodata?.providerName}
          </Typography>
        </Button>

        <Dialog
          open={pleaseNoteModal}
          onClose={() => setIframeModalOpen(false)}
          PaperProps={{
            sx: {
              width: "277px",
              m: "auto",
              py: 4,
              height: "470px",
              backgroundColor: "#fff",
              padding: "0 0 20px",
              position: "relative",
              fontSize: "16px",
              fontWeight: 500,
              color: "#000",
              boxShadow:
                "0 4px 6px rgba(0,0,0,.25),inset -3px 4px 6px rgba(0,0,0,.25)",
              borderRadius: "30px",
              overflowY: "visible",
            },
          }}
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          }}
        >
          <DialogContent sx={{ borderRadius: "30px", p: 0 }}>
            <Box className="imgholder">
              <img
                src={
                  "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/slotsCasinoPopUp.webp"
                }
                alt=""
                className="h-[150px] left-[25%] top-[-70px] absolute"
              />
            </Box>

            <Box
              className="closebutton"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                margin: "20px 20px 0 0",
              }}
            >
              <CloseIcon
                onClick={() => setPleaseNoteModal(false)}
                sx={{
                  cursor: "pointer",
                  p: 0.2,
                  height: "14px",
                  width: "14px",
                  color: "#fff",
                  borderRadius: "100%",
                  backgroundColor: "#000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </Box>

            <Box
              className="content-box"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                p: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#252b37",
                  fontSize: "18px",
                  lineHeight: "22px",
                  marginTop: "30px",
                }}
                component="p"
              >
                PLEASE NOTE
              </Typography>

              <Typography
                className="point-text"
                sx={{
                  color: "#007b59",
                  mt: "11px",
                  fontSize: "22px",
                  fontWeight: "26px",
                }}
              >
                (1 Point = ₹{getOneUserData?.data?.qtech})
              </Typography>
              <Typography sx={{ color: "#000", textAlign: "center", mt: "5px" }}>
                Welcome to your new casino lobby
              </Typography>

              <Typography
                sx={{
                  width: "150px",
                  textAlign: "center",
                  mt: "35px",
                  fontWeight: 400,
                  fontSize: "15px",
                  color: "#252b37",
                  lineHeight: "18px",
                }}
                className="summaraytext"
              >
                Checkout the exciting Live Casino tables, Slots and much more,
                upto 200+ game and counting...
              </Typography>

              <Box className="agree-button" sx={{ width: "100%" }}>
                <Button
                  onClick={handlePleaseNote}
                  sx={{
                    backgroundColor: "#f36c21",
                    boxShadow: "0 4px 4px rgba(0,0,0,.25)",
                    width: "230px",
                    height: "44px",
                    borderRadius: "15px",
                    fontWeight: "600",
                    fontSize: "15px",
                    color: "#fff",
                    textTransform: "uppercase",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: "52px",

                    ":hover": {
                      color: "#f36c21",
                      border: "1px solid #f36c21",
                      background:
                        "linear-gradient(0deg,hsla(0,0%,4%,.5) 50%,#323232)",
                      backgroundColor: "#1e1e1e",
                    },
                  }}
                >
                  ok, i agree !
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </>

    )
  }

  const ModalCasino = () => {
    return (
      <Dialog
        sx={{
          height: "100vh",
          width: "100vw",
          maxWidth: "100vw",
          maxHeight: "100vh",
          m: 0,
          p: 0,
        }}
        open={iframeModalOpen}
        onClose={() => setIframeModalOpen(false)}
        fullScreen
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box
            className="closebutton"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: "#f36c21",
              p: 2,
              borderBottom: "1px solid #fff",
              width: "100%",
            }}
          >
            <CloseIcon
              onClick={() => setIframeModalOpen(false)}
              sx={{
                p: 0.2,
                height: "30px",
                width: "30px",
                color: "#f36c21",
                borderRadius: "10%",
                backgroundColor: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </Box>

          <iframe
            allowFullScreen
            src={gamelinkData?.data?.url}
            style={{ height: "100%", width: "100%", border: "none" }}
            title="desktop"
          />
        </Box>
      </Dialog>
    )
  }

  const PleaseNoteModal = () => {
    return (
      <Dialog
        open={pleaseNoteModal}
        onClose={() => setIframeModalOpen(false)}
        PaperProps={{
          sx: {
            width: "277px",
            m: "auto",
            py: 4,
            height: "470px",
            backgroundColor: "#fff",
            padding: "0 0 20px",
            position: "relative",
            fontSize: "16px",
            fontWeight: 500,
            color: "#000",
            boxShadow:
              "0 4px 6px rgba(0,0,0,.25),inset -3px 4px 6px rgba(0,0,0,.25)",
            borderRadius: "30px",
            overflowY: "visible",
          },
        }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        }}
      >
        <DialogContent sx={{ borderRadius: "30px", p: 0 }}>
          <Box className="imgholder">
            <img
              src={
                "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/slotsCasinoPopUp.webp"
              }
              alt=""
              className="h-[150px] left-[25%] top-[-70px] absolute"
            />
          </Box>

          <Box
            className="closebutton"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              margin: "20px 20px 0 0",
            }}
          >
            <CloseIcon
              onClick={() => setPleaseNoteModal(false)}
              sx={{
                cursor: "pointer",
                p: 0.2,
                height: "14px",
                width: "14px",
                color: "#fff",
                borderRadius: "100%",
                backgroundColor: "#000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </Box>

          <Box
            className="content-box"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              p: "20px",
            }}
          >
            <Typography
              sx={{
                color: "#252b37",
                fontSize: "18px",
                lineHeight: "22px",
                marginTop: "30px",
              }}
              component="p"
            >
              PLEASE NOTE
            </Typography>

            <Typography
              className="point-text"
              sx={{
                color: "#007b59",
                mt: "11px",
                fontSize: "22px",
                fontWeight: "26px",
              }}
            >
              (1 Point = ₹{getOneUserData?.data?.qtech})
            </Typography>
            <Typography sx={{ color: "#000", textAlign: "center", mt: "5px" }}>
              Welcome to your new casino lobby
            </Typography>

            <Typography
              sx={{
                width: "150px",
                textAlign: "center",
                mt: "35px",
                fontWeight: 400,
                fontSize: "15px",
                color: "#252b37",
                lineHeight: "18px",
              }}
              className="summaraytext"
            >
              Checkout the exciting Live Casino tables, Slots and much more,
              upto 200+ game and counting...
            </Typography>

            <Box className="agree-button" sx={{ width: "100%" }}>
              <Button
                onClick={handlePleaseNote}
                sx={{
                  backgroundColor: "#f36c21",
                  boxShadow: "0 4px 4px rgba(0,0,0,.25)",
                  width: "230px",
                  height: "44px",
                  borderRadius: "15px",
                  fontWeight: "600",
                  fontSize: "15px",
                  color: "#fff",
                  textTransform: "uppercase",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: "52px",

                  ":hover": {
                    color: "#f36c21",
                    border: "1px solid #f36c21",
                    background:
                      "linear-gradient(0deg,hsla(0,0%,4%,.5) 50%,#323232)",
                    backgroundColor: "#1e1e1e",
                  },
                }}
              >
                ok, i agree !
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  const handlePleaseNote = () => {
    setIframeModalOpen(true)
    setPleaseNoteModal(false)
  }
  return (
    <Box className="livecasino">
      <ModalCasino />
      <Box className="cardholder">
        <Box className="tabcontainer" sx={{ mx: { md: 13, xs: 1 } }}>
          <Box
            className="tabs-here"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              flexWrap: { md: "nowrap", xs: "nowrap" },
              gap: "0.2rem",
              flexDirection: "row",
              my: 2,
              overflowY: "hidden",
              overflowX: "scroll",
            }}
          >
            {instantWinArr?.map((data: any, idx: number) => (
              <TabButton casinodata={data} key={idx} />
            ))}
            {providerData && !(providerData?.data?.instantWin?.length > 0) && (
              <NoData />
            )}
          </Box>
        </Box>

        <Container maxWidth="lg" sx={{ textAlign: "center" }}>
          {isLoading && isFetching ? (
            <CircularProgress size={"100px"} color="primary" />
          ) : (
            <Grid container spacing={1} sx={{ py: 2 }}>
              {fantsyGameList?.map((item, idx) => {
                if (gamelistPayload?.provider !== item?.providerId) return null;
                return (
                  <Grid item md={2} xs={4} key={idx + 1}>
                    <Card
                      onClick={() => handleIframeModal(item?.id)}
                      sx={{
                        overflow: "hidden",
                        borderRadius: "10px",
                        cursor: "pointer",
                        border: "2px solid #f36c21 !important",
                      }}
                    >
                      <img
                        key={idx}
                        src={item?.image}
                        alt={item?.name}
                        className="w-full h-full max-w-full rounded-[10px]"
                      />
                    </Card>
                  </Grid>
                )
              })}
              {!gamelistdata && <NoData />}
            </Grid>
          )}
        </Container>
        <PleaseNoteModal />
      </Box>
    </Box>
  )
}

export default CrashCasinoGame
