import { Drawer } from "@mui/material"

import type { FC} from "react";
import { useState } from "react"
import RightDrawerList from "./RightDrawerList"

interface RightDrawerProps {
  open: boolean
  rightDrawerToggle: (newOpen: boolean) => () => void
  userBalanceData: getUserBalanceRes | undefined;
  setRightDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
  // demouserName: any
}

const RightDrawer: FC<RightDrawerProps> = ({
  open,
  rightDrawerToggle,
  userBalanceData,
  setRightDrawerOpen
  // demouserName,
}) => {
  return (
    <Drawer
      open={open}
      onClose={rightDrawerToggle(false)}
      anchor="right"
      sx={{
        "& .MuiPaper-root.MuiDrawer-paper": {
          overflowY: "visible !important",
        },
      }}
    >
      <RightDrawerList
        // demouserName={demouserName}
        rightDrawerToggle={rightDrawerToggle}
        userBalanceData={userBalanceData}
        setRightDrawerOpen={setRightDrawerOpen}
      />
    </Drawer>
  )
}

export default RightDrawer
