import * as React from "react"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"

interface MatchedDeletedRadioProps {
  label: string
  handleMatchedDeleted: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const MatchedDeletedRadioCasino: React.FC<MatchedDeletedRadioProps> = ({
  label,
  handleMatchedDeleted,
}) => {
  const isDeletedSports = [
    {
      isDeleted: false,
      name: "Matched",
    },
    {
      isDeleted: true,
      name: "Deleted",
    },
  ]

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={label}
        onChange={handleMatchedDeleted}
      >
        {isDeletedSports?.map((item, idx) => (
          <FormControlLabel
            sx={{ textTransform: "capitalize", color: "#fff" }}
            key={idx}
            value={item?.isDeleted}
            control={<Radio size="small" />}
            label={item?.name}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default MatchedDeletedRadioCasino
