import { Button, Typography } from "@mui/material"
import HeadingBar from "../../../common/HeadingBar"
import { ChevronLeft } from "@mui/icons-material"
import { useLocation, useNavigate } from "react-router"

const News = () => {
  return (
    <div>
      <HeadingBar title="news" />
    </div>
  )
}

export default News
