import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material"
import type { FC, SetStateAction } from "react"
import { useEffect, useState } from "react"
import { rightdata } from "./rightdrawerdata"
import { useNavigate } from "react-router"
import CloseIcon from "@mui/icons-material/Close"
import { useAppDispatch } from "../../hooks/useAppDispatch"
import { selectUser } from "../../features/auth/authslice"
import { useAppSelector } from "../../hooks/useAppSelector"
import LogoutModal from "../LogoutModal"
import FeedbackModal from "../feedbackmodal/FeedbackModal"
interface DrawerListProps {
  rightDrawerToggle: (newOpen: boolean) => () => void
  userBalanceData: getUserBalanceRes | undefined;
  setRightDrawerOpen: any
  // demouserName: any
}

interface handleNavigationProps {
  name: string
  ico: string
  link?: string
}

const RightDrawerList: FC<DrawerListProps> = ({
  rightDrawerToggle,
  userBalanceData,
  setRightDrawerOpen
  // demouserName,
}) => {
  const [checked, setChecked] = useState<boolean>(false)
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectUser)
  const nav = useNavigate()
  const isMobile = useMediaQuery("(max-width:900px)")
  const [logoutmodal, setLogoutModal] = useState<boolean>(false)
  let userLocalData: string | null = null
  const loginData = localStorage.getItem("loginData")
  if (loginData) {
    userLocalData = JSON.parse(loginData).userId // Assign value inside the block
  }

  const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked)
  }

  const handleFeedbackModal = () => {
    setFeedbackOpen(true)
  }

  const handleLanguageModal = () => {
    console.log("languagemodal func")
  }

  const handleNavigate = (navdata: handleNavigationProps) => {
    if (navdata?.name === "Feedback") {
      handleFeedbackModal()
    } else if (navdata?.name === "Language") {
      handleLanguageModal()
    } else {
      if (navdata?.link) {
        nav(`${navdata?.link}`)
      }
    }
  }

  const handleLogoutModal = () => {
    setLogoutModal(!logoutmodal)
  }

  const handleDeposit=()=>{
    nav('/deposit');
    setRightDrawerOpen(false)
    
  }
  const handleWithdraw=()=>{
    nav('/withdraw');
    setRightDrawerOpen(false)
  }

  return (
    <>
      <FeedbackModal open={feedbackOpen} setFeedbackOpen={setFeedbackOpen} />
      <LogoutModal
        setLogoutModal={setLogoutModal}
        logoutmodal={logoutmodal}
        rightDrawerToggle={rightDrawerToggle}
      />
      <IconButton
        onClick={rightDrawerToggle(false)}
        className="group"
        sx={{
          backgroundColor: "#fff",
          right: "100%",
          top: 10,
          position: "absolute",
          borderRadius: "5px 0 0 5px",
          transition: "all 0.3s linear 0s",
          color: "#000",
          ":hover": {
            backgroundColor: "#f36c21",
            color: "#fff",
          },
        }}
      >
        <CloseIcon
          className="group:hovertext-white"
          sx={{
            fontSize: { md: "26px", xs: "16px" },
            color: "#000",
            fontWeight: 600,
            transition: "all 0.3s linear 0s",
          }}
        />
      </IconButton>
      <Box
        sx={{
          width: { md: 300, xs: 260 },
          position: "relative",
          height: "100lvh",
          borderLeft: "0.5px groove #f36c21",
          boxShadow:
            "0 50px 100px -20px rgba(50,50,93,.25),0 30px 60px -30px rgba(0,0,0,.3)",
        }}
        role="presentation"
      >
        <Box
          className="top-heading"
          sx={{ backgroundColor: "#1e1e1e", padding: "14px" }}
        >
          <Typography
            sx={{
              color: "#fff",
              fontSize: "16px",
              fontWeight: "900",
              letterSpacing: "0.7px",
            }}
          >
            Hi {userLocalData}
            {/* {demouserName} */}
          </Typography>
        </Box>

        <List
          sx={{ overflowY: "scroll", height: "calc(100lvh - 50px)", py: 1 }}
        >
          <Box
            sx={{ backgroundColor: "transparent", px: 1 }}
            className="walletamountnetexpos"
          >
            <Box
              className="onclickbetholder"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: ".875rem", xs: "14px" },
                  textTransform: "capitalize",
                  color: "#fff",
                }}
              >
                on click bet
              </Typography>
              <Switch
                checked={checked}
                onChange={handleSwitch}
                disableTouchRipple
                disableRipple
                sx={{
                  "& .MuiSwitch-thumb": {
                    height: "11px",
                    width: "11px",
                    margin: "5px 0 0 5px",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  },
                  "& .MuiSwitch-switchBase": {
                    backgroundColor: "rgb(255 255 255 / 0%)",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "#fff",

                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    boxShadow: "0 0 0 5px rgba(231,75,8,.25)",
                    backgroundColor: "#f36c21",
                    opacity: 1,
                    border: "1px solid rgba(0, 0, 0, 0.076)",
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "#ffffff",
                    border: "1px solid rgba(0,0,0,.25)",
                    height: "15px",
                    width: "50px",
                  },
                }}
                name="odds"
              />
            </Box>

            {/*  */}
            <Box
              className="wallet-amount"
              sx={{
                my: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box className="wallet-info-lft">
                <Typography
                  sx={{
                    fontSize: { md: ".875rem", xs: "14px" },
                    textTransform: "capitalize",
                    color: "#fff",
                  }}
                >
                  Wallet Amount
                </Typography>
                <Typography sx={{ fontSize: ".5rem", color: "#fff" }}>
                  Inclusive bonus
                </Typography>
              </Box>
              <Box className="wallet-info-rht">
                <Typography sx={{ color: "#f36c21", fontSize: "14px" }}>
                  {userBalanceData?.data?.balance || "0.00"}
                </Typography>
              </Box>
            </Box>
            {/*  */}

            <Box
              className="net-exposed"
              sx={{
                my: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box className="wallet-info-lft">
                <Typography
                  sx={{
                    fontSize: ".875rem",
                    textTransform: "capitalize",
                    color: "#fff",
                  }}
                >
                  Net Exposure
                </Typography>
              </Box>
              <Box className="wallet-info-rht">
                <Typography sx={{ color: "#f36c21", fontSize: "14px" }}>
                  {userBalanceData?.data?.winnings || "0.00"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider />
          {/* {
            isMobile && <> 
             <ListItem  onClick={handleDeposit} disablePadding sx={{ borderBottom: "1px solid grey" }}>
            <ListItemButton
              onClick={()=>rightDrawerToggle(false)}
              className="group"
              sx={{
                ":hover": {
                  backgroundColor: "#f36c21",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: { xs: "35px" },
                }}
              >
                <img
                  src={'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/searchbar/deposit.webp'}
                  alt=""
                  className="text-white w-[20px] h-[20px] group-hover:brightness-0 group-hover:invert"
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: { md: "16px", xs: "14px" } }}>
                    Deposit
                  </Typography>
                }
                sx={{ color: "#fff" }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem onClick={handleWithdraw} disablePadding sx={{ borderBottom: "1px solid grey" }}>
            <ListItemButton
              onClick={()=>rightDrawerToggle(false)}
              className="group"
              sx={{
                ":hover": {
                  backgroundColor: "#f36c21",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: { xs: "35px" },
                }}
              >
                <img
                src={'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/searchbar/deposit.webp'}
                alt=""
                className="text-white w-[20px] h-[20px] group-hover:brightness-0 group-hover:invert"
              />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: { md: "16px", xs: "14px" } }}>
                    Withdraw
                  </Typography>
                }
                sx={{ color: "#fff" }}
              />
            </ListItemButton>
          </ListItem>
            </>
          } */}
         
          {rightdata?.map((listdata, index) => {
            if((listdata?.name === "Deposit"  || listdata?.name === "Withdraw") && !isMobile) return null;
            return(
            <ListItem
              onClick={() => handleNavigate(listdata)}
              key={listdata.name + index}
              disablePadding
              sx={{ borderBottom: "1px solid grey" }}
            >
              <ListItemButton
                onClick={
                  listdata?.name === "Feedback" || listdata?.name === "Language"
                    ? () => {}
                    : rightDrawerToggle(false)
                }
                className="group"
                sx={{
                  ":hover": {
                    backgroundColor: "#f36c21",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: { xs: "35px" },
                  }}
                >
                  <img
                    src={listdata.ico}
                    alt=""
                    className="text-white w-[20px] h-[20px] group-hover:brightness-0 group-hover:invert"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: { md: "16px", xs: "14px" } }}>
                      {listdata.name}
                    </Typography>
                  }
                  sx={{ color: "#fff" }}
                />
              </ListItemButton>
            </ListItem>
          )})}

          <ListItem sx={{ m: "20px auto" }}>
            <Button
              onClick={handleLogoutModal}
              sx={{
                m: "auto",
                fontWeight: "600",
                padding: "6px",
                textTransform: "capitalize",
                width: "55%",
                backgroundColor: "#4caf50",
                borderRadius: "3px",
                textAlign: "center",
                color: "#fff",
                fontSize: { md: "16px", xs: "14px" },
                outline: "none",
                ":hover": {
                  backgroundColor: "#4caf50",
                },
              }}
            >
              Logout
            </Button>
          </ListItem>
        </List>
      </Box>
    </>
  )
}

export default RightDrawerList
