import { Box, Button, Grid, Typography } from "@mui/material"
// import downloadapp from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/download-app.webp"

const AboutDownloadTheApp = () => {
  const host = window.location.hostname
  return (
    <Box
      sx={{
        paddingBottom: "20px",
        marginTop: "25px",
        backgroundColor: "#f36c21",
      }}
    >
      <Grid container sx={{ alignItems: "center", justifyContent: "center" }}>
        <Grid item xs={6} md={6} lg={3}>
          <Box sx={{ textAlign: "end" }}>
            {host?.includes("indiabet777") ? (
              <img
                src={"/img/1.jpeg"}
                alt=""
                className="lg:w-[475px] ml-auto "
                style={{
                  paddingRight:"12px",
                  paddingTop:"12px"
                }}
              />
            ) : (
              <img
                loading="lazy"
                src={
                  "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/download-app.webp"
                }
                alt="downloadappimg"
                className="lg:w-[475px] ml-auto"
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={6} md={6} lg={5}>
          <Box className="download-fair-ply flex flex-col items-start">
            <Box className="club-title-name">
              <Typography
                component="h2"
                sx={{
                  marginBottom: "16px",
                  fontSize: { md: "35px", xs: "24px" },
                  fontFamily: "Lato,sans-serif",
                  color: "#fff",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  textAlign: { xs: "center" },
                }}
              >
                DOWNLOAD THE APP
              </Typography>
            </Box>
            <Box
              className="download-text"
              sx={{ margin: { md: "auto", xs: "0 10px", textAlign: "center" } }}
            >
              <Typography
                component="p"
                sx={{
                  fontSize: { md: "17px", xs: "16px" },
                  fontWeight: { md: "600" },
                  fontFamily: "Lato,sans-serif",
                  color: "#fff",
                  textAlign: { md: "left", xs: "center" },
                }}
              >
                Now download the CO Official app and get easier, quicker access
                to your winnings!
              </Typography>
              <Box
                className="downloadappbtn"
                sx={{
                  display: "flex",
                  justifyContent: { md: "flex-start", xs: "center" },
                  my: 2,
                }}
              >
                <Button
                  sx={{
                    ":hover": {
                      backgroundColor: "#c2c2c2",
                      boxShadow:
                        "0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)",
                    },
                    cursor: "pointer",
                    height: { md: "52px", xs: "27px" },
                    minWidth: { md: "92px", xs: "23px" },
                    padding: { md: "0 28px", xs: "0 13px" },
                    backgroundColor: "#fff",
                    borderRadius: "100px",
                    border: "0",
                    color: "#000",
                    textTransform: "capitalize",
                    boxShadow:
                      "0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      color: "#000",
                      fontWeight: "700",
                      fontSize: { md: "16px", xs: "11px" },
                      textTransform: " uppercase",
                      fontFamily: "Lato, sans-serif",
                      letterSpacing: " 1px",
                    }}
                  >
                    download app
                  </Typography>
                </Button>
              </Box>

              <Typography
                component="p"
                sx={{
                  fontSize: { md: "14px", xs: "13px" },
                  textAlign: { md: "left", xs: "center" },
                  color: "#f8f9fa",
                  fontWeight: "600",
                  padding: { md: "5px 0 0", xs: "5px 0 0" },
                }}
              >
                Download now outside Google Playstore
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AboutDownloadTheApp
