import { Box, Button, Typography } from "@mui/material"
// import livecardsimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardsmf.webp"
// import livecasinoimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecasinomf.webp"
// import slotgamesimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/slotgamesmf.webp"
// import crashimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/crashmf.webp"

import { useNavigate } from "react-router"

type sportsbarmobiledataType = {
  name: string
  link?: string
  ico: string
}[]

const sportsbarmobiledata: sportsbarmobiledataType = [
  {
    name: "live cards",
    link: "/live-card",
    ico: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecardsmf.webp",
  },
  {
    name: "live casino",
    link: `/live-casino`,
    ico: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/livecasinomf.webp",
  },
  {
    name: "slot games",
    link: "/slot-games",
    ico: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/slotgamesmf.webp",
  },
  {
    name: "crash games",
    link: "/crash-games",
    ico: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/crashmf.webp",
  },
]

const SportsBarMobileFooter = () => {
  const nav = useNavigate()

  const handleNavigate = (sport: any) => {
    if (sport) {
      nav(`${sport?.link}`, {
        state: {
          sport: sport?.name,
          casinoName:
            sport.name === "crash games"
              ? "all".toUpperCase()
              : sport.name === "slot games"
                ? "slot".toUpperCase()
                : sport.name === "live cards"
                  ? "lottery".toUpperCase()
                  : sport.name.replace(/\s+/g, "").toUpperCase(),
        },
      })
    }
  }

  return (
    <Box
      className="mobilesportsbar"
      sx={{
        position: "fixed",
        bottom: "0",
        zIndex: 99,
        borderBottom: "1px solid #333",
        borderTop: "1px solid #4c4c4c",
        width: "100%",
        // margin: { xs: "10px 5px" },
        display: "flex",
        alignItems: "center",
        background: "linear-gradient(0deg,#111 50%,#323232)",
        padding: "5px 0",
      }}
    >
      {sportsbarmobiledata?.map((sportsbardata, idx) => (
        <Button
          onClick={() => handleNavigate(sportsbardata)}
          key={sportsbardata.name + idx}
          sx={{
            borderRadius: 0,
            width: { xs: "25%" },
            textAlign: "center",
            borderRight: "1px solid #6e6e6e",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            ":last-child": {
              borderRight: "0",
            },
          }}
        >
          <img
            src={sportsbardata?.ico}
            alt=""
            className="max-w-full h-[20px] mb-[5px]"
          />
          <Typography
            component="p"
            sx={{
              fontSize: "12px",
              textTransform: "uppercase",
              color: "#FFFFFF99",
            }}
          >
            {sportsbardata?.name}
          </Typography>
        </Button>
      ))}
    </Box>
  )
}

export default SportsBarMobileFooter
