// import gift from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/affiliate/gift.webp';
// import tri from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/affiliate/tri.webp';
// import offering from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/affiliate/deposit.webp';
// import mobi from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/affiliate/mobile.webp';
// import check from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/affiliate/check.webp';
// import support from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/affiliate/support.webp';


type affiliatedataType = {
    title: string,
    img: string,
    content: string
}[]


export const affiliatedata: affiliatedataType = [
    {
        title: 'Hefty Commissions',
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/affiliate/gift.webp',
        content: 'We offer a commission rate upto 40% subject to differ based on number of clients and the estimated turnover.'
    },
    {
        title: 'Multi Tier System',
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/affiliate/tri.webp',
        content: 'Know someone that knows everyone? Bring in an affiliate and earn commission on the players that come with them!'
    },
    {
        title: 'Offerings',
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/affiliate/deposit.webp',
        content: `${window.location.hostname.replace("www.", "").split(".")[0]} is the world’s biggest sports betting exchange with a range of live casino and live Indian card games and the best odds for sports betting!`
    },
    {
        title: 'Easy, Accessible, Efficient',
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/affiliate/mobile.webp',
        content: `${window.location.hostname.replace("www.", "").split(".")[0]} brings advanced online gambling to your fingertips in the most easy and user friendly way there is!`
    },
    {
        title: 'Stay Up To Date',
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/affiliate/check.webp',
        content: 'We believe with money matters, transparency is key! Access detailed data of the players’ activities and your downline’s performance in our affiliate backend.'
    },
    {
        title: '24*7 Customer Support',
        img: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/affiliate/support.webp',
        content: 'For everything else, there’s the dedicated Client Services team available round the clock!'
    },

]