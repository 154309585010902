import { Box, Container, Grid, Typography } from "@mui/material"
import SecondHeadingBar from "../../../../common/SecondHeadingBar"
import { affiliatedata } from "./affiliatedata"
import AffiliateTable from "./AffiliateTable"

const style = {
  backgroundColor: "#f36c21",
}

const Affiliate = () => {
  return (
    <Box className="affiliateparent">
      <Box className="affiliateholder" sx={style}>
        <SecondHeadingBar title="Affiliate" />
      </Box>

      <Container maxWidth="xl" sx={{ px: 1 }}>
        <Typography
          sx={{
            color: "#fff",
            fontSize: "2.125rem",
            lineHeight: "2.5rem",
            letterSpacing: ".0073529412em",
            fontWeight: "500",
            textAlign: "center",
            my: 2,
          }}
          component="h2"
        >
          Come win with us, partner!
        </Typography>

        <Grid container spacing={2}>
          {affiliatedata?.map((content, idx) => (
            <Grid
              item
              xs={12}
              md={6}
              key={content?.title + idx}
              columnSpacing={4}
            >
              <Box
                sx={{
                  padding: "25px 20px 25px",
                  backgroundColor: "#1e1e1e",
                  border: "1px solid #f36c21",
                  marginBottom: "20px",
                  height: "145px",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  component="p"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    component="span"
                    sx={{
                      height: "45px",
                      width: "45px",
                      borderRadius: "100px",
                      backgroundColor: "#f36c21",
                      display: "inline-block",
                      padding: "10px",
                      marginRight: "5px",
                    }}
                  >
                    <img src={content?.img} alt="" />
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ fontSize: "20px", color: "#fff" }}
                  >
                    {content?.title}
                  </Typography>
                </Typography>

                <Typography
                  className="subtitle"
                  component="p"
                  sx={{ fontSize: "13px", color: "#fff", my: "15px" }}
                >
                  {content?.content}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        <AffiliateTable />

        <Typography
          sx={{
            color: "#f36c21",
            fontSize: "1rem",
            p: "5px 0 10px",
            fontWeight: "600",
          }}
        >
          Note* :- Settlement 1st week of next month
        </Typography>
      </Container>
    </Box>
  )
}

export default Affiliate
