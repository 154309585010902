import { Box, Tab, Tabs, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { tabdata } from "./sprotspagedata"
import { Link, useNavigate } from "react-router-dom"
import Badge from "../../../common/Badge"
import SportPageSecondTab from "./SportPageSecondTab"
import SportsMatchesData from "./SportsMatchesData"
import { useLeftMenuListMutation } from "../../../api/mainApi/mainApiSlice"
import sportIcon from "../../../utils/sporticon"
import favimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/favorite.webp"
import { useSelector } from "react-redux"
import { sportdataSelector } from "../../../api/sportdatalength/sportdataslice"
import { useActiveMatchByIdQuery } from "../../../api/betFairApi/betFairApiSlice"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const SportsPage = () => {
  const nav = useNavigate()
  const [value, setValue] = useState(0)
  const [leftMenuTrigger, { data: leftMenuData }] = useLeftMenuListMutation()
  // const sportdatalength = useSelector(sportdataSelector)

  // console.log(sportdatalength, "sportdatalengthhh")

  const { data: cricketactivematchData } = useActiveMatchByIdQuery(4, {
    refetchOnMountOrArgChange: true,
  })
  const { data: tennisactivematchData } = useActiveMatchByIdQuery(1, {
    refetchOnMountOrArgChange: true,
  })
  const { data: footballactivematchData } = useActiveMatchByIdQuery(2, {
    refetchOnMountOrArgChange: true,
  })

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleClick = (args: any) => {
    nav(`/sports/${args?.sportName.trim()}/${args?.sportId}`)
  }

  useEffect(() => {
    leftMenuTrigger({})
  }, [])

  const sportdatalengthobj = {
    data: [],
  }

  return (
    <Box className="sportspageparent">
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 0, margin: "10px 0 0" }}>
          <Tabs
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              sx: {
                flex: 1,
                width: "100%",
                backgroundColor: "#f36c21",
                // minWidth: "unset",
              },
            }}
            sx={{
              "& .MuiTab-root": {
                minWidth: "fitcontent",
                flex: 1,
              },

              ".MuiButtonBase-root": {
                width: "auto",
                px: 0,
              },
            }}
          >
            {leftMenuData?.data?.map((menudata, idx) => (
              <Tab
                key={idx}
                sx={{ p: 0 }}
                disableTouchRipple
                label={
                  <Box
                    onClick={() => handleClick(menudata)}
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      className="imgbadgeholder"
                      sx={{ display: "flex", position: "relative" }}
                    >
                      <img
                        src={sportIcon(menudata?.sportName)}
                        alt=""
                        className={`w-[20px] h-[20px] ${menudata?.sportName === "Kabaddi" || menudata?.sportName === "Election" ? "filter invert" : ""}`}
                      />

                      <Badge
                        className="absolute left-6 lg:left-9 font-semibold flex items-center justify-center text-[10px] lg:text-[12px] h-[20px] w-[20px] rounded-full bg-[#f36c21] text-white"
                        text={
                          (menudata?.sportId == 4
                            ? cricketactivematchData
                            : menudata?.sportId == 1
                              ? tennisactivematchData
                              : menudata?.sportId == 2
                                ? footballactivematchData
                                : sportdatalengthobj
                          )?.data?.length ?? 0
                        }
                      />
                    </Box>

                    <Typography
                      component="p"
                      sx={{
                        textTransform: "uppercase",
                        fontSize: { md: "13px", xs: "10px" },
                        color: value === idx ? "#f36c21" : "#ccc",
                        mt: 1,
                      }}
                    >
                      {menudata?.sportName}
                    </Typography>
                  </Box>
                }
                {...a11yProps(idx)}
              />
            ))}

            <Tab
              disableTouchRipple
              disableRipple
              {...a11yProps(6)}
              label={
                <Box
                  onClick={() => nav("/favourites")}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/favorite.webp"
                    }
                    alt=""
                    className={`w-[20px] h-[20px]`}
                  />
                  <Link
                    to={"#"}
                    className={`uppercase text-[10px] lg:text-[13px] text-${value === 6 ? "#f36c21" : "#ccc"} block w-full `}
                  >
                    fav
                  </Link>
                </Box>
              }
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={0} index={0}>
          <SportPageSecondTab />
        </CustomTabPanel>
      </Box>
    </Box>
  )
}

export default SportsPage
