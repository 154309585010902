import { FaShop } from "react-icons/fa6";
import { BsNewspaper } from "react-icons/bs";
import { IoIosDesktop } from "react-icons/io";
import { IoPerson } from "react-icons/io5";
import { GoShield } from "react-icons/go";
import { MdQuestionAnswer } from "react-icons/md";
import CampaignIcon from '@mui/icons-material/Campaign';
// import affiliateImg from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/affiliate.webp';
// import term from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/term.webp';

export const LeftSideBarData = [
    [{
        name: 'market',
        icon: FaShop,
        link: '/markets',
        loading: 'lazy'

    },
    {
        name: 'news',
        icon: BsNewspaper,
        link: '/news',
        loading: 'lazy'

    },
    {
        name: 'about us',
        icon: IoPerson,
        link: '/aboutus',
        loading: 'lazy'

    },
    {
        name: 'tutorials',
        icon: IoIosDesktop,
        link: '/tutorials',
        loading: 'lazy'

    },
    {
        name: 'privacy policy',
        icon: GoShield,
        link: '/privacy_policy',
        loading: 'lazy'

    },
    {
        name: 'faq',
        icon: MdQuestionAnswer,
        link: '/faqs',
        loading: 'lazy'
    },
    {
        name: 'promotion',
        icon: CampaignIcon,
        link: '/promotion',
        loading: 'lazy'
    },

    ],
    [{
        name: 'affiliate',
        icon: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/affiliate.webp',
        link: '/affiliate',
        loading: 'lazy'
    },
    {
        name: 'T&C',
        icon: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/term.webp',
        link: '/term_condition',
        loading: 'lazy'
    }
    ]

]